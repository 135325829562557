import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, FormGroup} from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog'
import { LoginErroDialog } from '../login/login_error_dialog'
import { TCAndPPDialogForUserAccepetance } from '../tc_and_pp_dialog_for_user_acceptance/tc_and_pp_dialog_for_user_acceptance'
import { environment } from 'src/environments/environment';
import { RouteStateService } from '../route.state.service'
import { AbstractControl } from '@angular/forms';
import {SexChoices, ClientForHowLong, CommentStatusUI} from '../interface'
import { ApiService } from '../api.service';
import { User } from '../interface'
import { MyErrorStateMatcher } from '../input-error-handlers.component';
import { GeneralDialog } from '../general_dialog/general_dialog';

 //TODO put this and date  verification in evaluation in utils
export function ValidateUnderage(control: AbstractControl) {
  if (control.value) {
    const birthday = control.value
    const dateWith18Years = new Date(birthday.getFullYear()+18, birthday.getMonth(), birthday.getDate())
    const today = new Date();
    if (dateWith18Years > today){
      return  { underage: true };
    }

    return null;
  }

  return null;
}

@Component({
  selector: 'app-signup',
  templateUrl: './user_profile.html',
  styleUrls: ['./user_profile.css'],
  /*providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],*/
})
export class UserProfile implements OnInit {

  private error: any;
  public emailFormControl : UntypedFormControl;
  private passwordFormControl: UntypedFormControl;
  private passwordConfirmationFormControl: UntypedFormControl;
  public usernameFormControl: UntypedFormControl;
  private apiRoot: string;
  private category_id: any;
  private searchType: any;
  private business_id: number;
  private searchName: string;
  public birthdayFormControl: UntypedFormControl;
  sexFormControl: UntypedFormControl;
  public today: Date;
  sexChoices = SexChoices;
  enumKeys=[];

  user: any

  public matcher: MyErrorStateMatcher;

  private userEvaluations: any[]

  constructor(private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private routeStateService: RouteStateService,
    private apiService: ApiService) {

      this.today = new Date()
      this.apiRoot = environment.apiUrl.concat('backend_api/accounts/')
      this.enumKeys=Object.keys(this.sexChoices);

      this.usernameFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(8),
      ]);

      this.emailFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]);

      this.birthdayFormControl = new UntypedFormControl('', [
        Validators.required,
        ValidateUnderage
      ]);

      this.sexFormControl = new UntypedFormControl('', [
        Validators.required,
      ]);

      this.matcher = new MyErrorStateMatcher();

    }


  async ngOnInit() {
    this.routeStateService.pathParam.subscribe(
      data => {
        this.category_id = data.category_id
        this.searchType = data.searchType
        this.business_id = data.business_id
        this.searchName = data.searchName
    })

    const userId = this.authService.currentUserValue.id
    await this.getUser(userId)
    //console.log("---------------- this.user ", this.user)
    this.usernameFormControl.setValue(this.user.username);
    this.emailFormControl.setValue(this.user.email)
    this.user.birthday = this.user.birthday? new Date(this.user.birthday) : this.user.birthday
    this.birthdayFormControl.setValue(this.user.birthday)
    this.sexFormControl.setValue(this.user.sex)
  }

  private async getUser(userId){
    await this.authService.getUser(userId).toPromise().then(result => {
      this.user = result
    },
    error => {
      //console.log(">>>>>>>>>>>>>>> getUser error ", error)
    });
  }

  private validateForm(){
    return this.usernameFormControl.valid &&
           this.emailFormControl.valid &&
           this.birthdayFormControl.valid &&
           this.sexFormControl.valid
  }

  onOptionsSelected(ev) {
     //console.log("ev ", ev); // should print option1
     //console.log("this.sexFormControl.value ", this.sexFormControl.value);
  }

  editProfile(){
    if(this.validateForm()){
      const data = this.getUserUpdatedData()
      //console.log("data ", data)
      if (data){
        //needs to change user email and send email confirmation first_name
        this.authService.editUser(data, data.id).toPromise().then(
          success =>  {
            if (success.code){
              switch (success.code) {
                     case 800:
                         this.showLoginErroDialog('Este e-mail já está sendo utilizado na plataforma!',
                           'Por favor, verifique seu cadastro.', false)
                     break;
                     case 801:
                         this.showLoginErroDialog('Este username já está sendo utilizado!',
                           'Por favor, escolha outro username.', false)
                     break;
                     default: throw new Error("Erro desconhecido - EditProfile");
               }
            } else {
              let user = {} as User
              user.id =  success.id
              user.email = success.email
              user.username = success.username
              this.user = success
              this.authService.setUser(user)
              if (data.email){

                  this.showSnackBar('Seus dados foram alterados com sucesso! Enviamos um e-mail de confirmação para seu novo e-mail!', 'success')
              } else {
                  this.showSnackBar('Seus dados foram alterados com sucesso!', 'success')
              }

            }
          },
          error => {
            if (error && error.error && error.error.username && error.error.username[0] === 'A user with that username already exists.') {
              this.showSnackBar('Esse username já está sendo utilizado, por favor escolha outro.')
            } else {
              throw new Error("Erro desconhecido");
            }
            
          })
      } else {
          this.showSnackBar('Você não alterou nenhum dos seus dados!')
      }

      //console.log("data ", data)
    }

    /*this.authService.editUser(data).subscribe(
      success => {
        console.log("##### success ", success);
      },
      error => {
        console.log("##### error ", error);
      })*/
  }

  private showLoginErroDialog(title, msg, isToShowResentEmailConfirmation){
    const dialogRef = this.dialog.open(LoginErroDialog, {
        width: '550px',
        height: '250px',
        data: {title: title,
               message: msg,
               email: this.emailFormControl.value,
               isToShowResentEmailConfirmation: isToShowResentEmailConfirmation}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.isEmailSent){
          this.showSuccessEmailConfirmationNotification()
        }
      });
  }

  private showSuccessEmailConfirmationNotification(){
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos um novo e-mail de confirmação! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private getUserUpdatedData(){
    //console.log("this.usernameFormControl.value ", this.usernameFormControl.value)
    //console.log("this.user.username ", this.user.username)
    let data = {} as User
    data.id = this.user.id
    let hasData = false
    if (this.usernameFormControl.value != this.user.username) {
      data.username = this.usernameFormControl.value
      hasData = true
    }
    if (this.emailFormControl.value != this.user.email) {
      data.email = this.emailFormControl.value
      hasData = true
    }
    if (this.birthdayFormControl.value != this.user.birthday){
      data.birthday = this.birthdayFormControl.value
      hasData = true
    }
    if (this.sexFormControl.value != this.user.sex){
      data.sex = this.sexFormControl.value
      hasData = true
    }

    return hasData ? data : null
  }

  async downloadInfo() {


    await this.apiService.list_all_rating_question_by_user(this.user.id).toPromise().then(
      success =>  {

        //console.log("##### list_all_rating_question_by_user success ", success);
        this.userEvaluations = success
      },
      error => {
        //console.log("##### list_all_rating_question_by_user error ", error);
        throw new Error("Erro desconhecido");
      })
    /*let res = "Text to save in a text file";
    const blob = new Blob([res], { type: 'text/plain; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);*/
    let res = "Seus dados são:"+ "\n"
    res += "Username: " + this.user.username + "\n"
    res += "Email: " + this.user.email+ "\n"
    res += "Data de Nascimento: " + this.user.birthday+ "\n"
    res += "Sexo: " + this.sexChoices[this.user.sex]+ "\n\n"
    //console.log("this.userEvaluations ", this.userEvaluations)
    if (this.userEvaluations) {
      for (let ue of this.userEvaluations) {
        res += "Avaliação da Empresa: " + ue.business_name+ "\n"
        res += "\t Cliente há: "+ ClientForHowLong[ue.client_how_long]+ "\n"
        res += "\t Notas dadas: "+ "\n"
        for (let q of ue.questions){
          res += "\t\t" + q.question + " - "+ q.rating+ "\n"
        }
        if (ue.userComment){
          res += "\t Comentário - "+ CommentStatusUI[ue.userComment_status] +" : "+ ue.userComment+ "\n"
        }
        res += "\n\n"
      }
    }
    const blob = new Blob([res], { type: 'text/plain; charset=utf-8' });
    //const url = window.URL.createObjectURL(blob); --- two lines to test without needing to download a file, it opens a new tab with the info
    //window.open(url);
    var a = document.createElement("a");
         a.href = URL.createObjectURL(blob);
         a.download = 'meus_dados_fincatch';
         // start download
         a.click();

  }

  changePasswordDialog(){
    this.authService.sendResetPasswordEmail(this.user.email).subscribe(
      success => {
        this.showSuccessEmailNotification()
      },
      error => {
        this.showPasswordErrorDialog()
      }
    );
  }

  private showPasswordErrorDialog(){
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Oooops, houve um erro ao tentar trocar sua senha, tente novamente mais tarde.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showSuccessEmailNotification(){
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos seu e-mail de instruções! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showSnackBar(msg, type?, verticalP?){
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = verticalP ? verticalP : 'top';
    this._snackBar.open(msg, 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: [type],
    });
  }

  deactivateUser(){

    this.authService.deactivateUser(this.user.id).toPromise().then(
      success =>  {
        if (success.code){
          switch (success.code) {
                 case 800:
                     this.showLoginErroDialog('Este e-mail já está sendo utilizado na plataforma!',
                       'Por favor, verifique seu cadastro.', false)
                 break;
                 case 801:
                     this.showLoginErroDialog('Este username já está sendo utilizado!',
                       'Por favor, escolha outro username.', false)
                 break;
                 default: throw new Error("Erro desconhecido - EditProfile");
           }
        } else {
          this.logout()
        }
      },
      error => {
        //console.log("##### editUserEmailAndSendConfirmationEmail error ", error);
        throw new Error("Erro desconhecido");
      })
  }

  openConfirmationDialog(){
    const dialogRef = this.dialog.open(GeneralDialog, {
        width: '550px',
        height: '150px',
        data: {title: 'Confirmação',
               message: 'Tem certeza que deseja desativar seu usuário?'}
      });

      dialogRef.afterClosed().subscribe(async result => {
        //console.log("openConfirmationDialog result ", result)
        if (result == 'ok'){
          await this.deactivateUser()
        }
    });
  }

  get logoutLink(): string {
    return this.apiRoot.concat('logout/');
  }

  private logout() {
    if (this.authService.isSocialLogin()){
      document.getElementById('logout').click();
    } else {
      this.authService.logout().subscribe(
       success => {
         this.router.navigate(['home'])
       },
       error => {
          this.authService.clean()
         this.error = error
       }
     );
    }


 }

}
