import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SearchType, Category } from '../interface'


export interface DialogData {
  tcAndPPText: string;
  userChecks: any[]
}

@Component({
  selector: 'tc_and_pp_simple_dialog',
  templateUrl: 'tc_and_pp_simple_dialog.html',
  styleUrls: ['./tc_and_pp_simple_dialog.css']
})
export class TCAndPPDialogSimpleDialog {

  constructor(
   public dialogRef: MatDialogRef<TCAndPPDialogSimpleDialog>,
   @Inject(MAT_DIALOG_DATA) public data: DialogData,
   ) {
     //console.log("data ", data)
   }

   onOkClick()  {
     this.dialogRef.close();
   }


}
