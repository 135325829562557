import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MyErrorStateMatcher } from '../input-error-handlers.component';
import { RouteStateService } from '../route.state.service'
import { ApiService } from '../api.service';
import { LoginErroDialog } from './login_error_dialog'
import { LoginResetPasswordDialog } from './login_reset_password_dialog'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { take, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GeneralDialog } from '../general_dialog/general_dialog';
import { Subject, lastValueFrom } from 'rxjs';
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Meta, Title } from '@angular/platform-browser';
import Utils from '../utils';
import { FincatchDataInterface } from '../interface';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  //encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  private category_id: any;
  private searchType: any;
  private searchName: string;
  private business_id: any;
  public error: any;
  public emailFormControl: UntypedFormControl;
  public passwordFormControl: UntypedFormControl;
  public matcher: MyErrorStateMatcher;
  private apiRoot: string;
  private destroy = new Subject<void>();
  public fincatchData: FincatchDataInterface;

  constructor(private authService: AuthService,
    private router: Router,
    private routeStateService: RouteStateService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {

    this.apiRoot = environment.apiUrl.concat('backend_api/accounts/')
    const code: string = this.activatedRoute.snapshot.queryParamMap.get('code');

    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);

    this.passwordFormControl = new UntypedFormControl('', [
      Validators.required,
    ]);

    this.matcher = new MyErrorStateMatcher();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  ngOnInit() {

    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Login - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Faça login na Fincatch e comece a avaliar fintechs' }
    );
    window.scroll(0, 0);
    const email_confirmed: string = this.activatedRoute.snapshot.paramMap.get('email_confirmed');
    if (email_confirmed) {
      this.showSnackBar('Seu e-mail foi confirmado com sucesso! Faça seu login.', 'success')
    }
    this.routeStateService.pathParam.subscribe(
      data => {
        this.category_id = data.category_id
        this.searchType = data.searchType
        this.business_id = data.business_id
        this.searchName = data.searchName
      })

    //TODO this is needed just in case user uses a social login
    localStorage.setItem('category_id', this.category_id);
    localStorage.setItem('searchType', this.searchType);
    localStorage.setItem('business_id', this.business_id);
    localStorage.setItem('searchName', this.searchName);

    //TODO I dont know why localStorage is not working anymore for social login
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: this.business_id, category_id: this.category_id, searchType: this.searchType, searchName: this.searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.getFincatchData();
  }

  private validateForm() {
    return this.passwordFormControl.valid &&
      this.emailFormControl.valid
  }

  async login() {
    //throw new Error("ERRO CLIENT LOGIN");
    if (this.validateForm()) {
      await this.authService.login(this.emailFormControl.value, this.passwordFormControl.value).toPromise().then(success => {
        this.googleAnalyticsService.loginEvent('email')
        if (success.code) {
          switch (success.code) {
            case 301:
              this.showLoginErroDialog('Ooops, seu e-mail ainda não está confirmado!',
                'Por favor, verifique sua caixa de e-mail, inclusive no spam, pelo e-mail enviado por nós.', true)
              break;
            case 302:
              this.showLoginErroDialog('Ooops, seu e-mail e/ou senha estão incorretos!',
                'Por favor, verifique se já possui cadastro ou tente novamente!', false)
              break;
            case 7000:
              this.openConfirmationDialog(this.emailFormControl.value)
              break;
            case 5000:
              this.showLoginErroDialog('Ooops, usuário não encontrado!',
                'Houve algum problema ao realizar o seu login, por favor, tente novamente mais tarde.', false)
              break;
            default: throw new Error("Erro desconhecido - Login");
          }
        } else if (this.business_id && this.searchType && this.searchName) {
          this.router.navigate(['evaluation', this.searchType, this.searchName, this.business_id])
        } else if (this.searchType && this.category_id) {
          this.router.navigate(['business_list', this.searchType, this.searchName, this.category_id])
        } else {
          this.router.navigate(['home'])
        }
      },
        error => {
          throw new Error("Erro desconhecido");

        })
      /*this.authService.login(this.emailFormControl.value, this.passwordFormControl.value).subscribe(
        success => {
          console.log("LOGIN SUBSCRIBE success ", success)
          if (success.code){
            switch (success.code) {
                   case 301:
                       this.showLoginErroDialog('Ooops, seu e-mail ainda não está confirmado!',
                         'Por favor, verifique sua caixa de e-mail, inclusive no spam, pelo e-mail enviado por nós.', true)
                   break;
                   case 302:
                       this.showLoginErroDialog('Ooops, seu e-mail e/ou senha estão incorretos!',
                         'Por favor, verifique se já possui cadastro ou tente novamente!', false)
                   break;
                   default: throw new Error("Erro desconhecido - Login");
             }
          } else if (this.business_id) {
            this.router.navigate(['evaluation', this.business_id])
          } else if (this.searchType && this.search_id) {
            this.router.navigate(['business_list', this.searchType, this.search_id])
          } else {
            this.router.navigate(['home_tags'])
          }
        },
        (error) => {
          console.log("LOGIN ERROR")
          //TODO remove this error capture
          this.error = error,
          //throw new Error(this.error);
          console.log("Login component ERROR " + this.error)
          console.log("Login component ERROR " + JSON.stringify(this.error))
        }
      );*/

    }

  }

  openConfirmationDialog(email) {
    const dialogRef = this.dialog.open(GeneralDialog, {
      width: '550px',
      height: '150px',
      data: {
        title: 'Esta conta está DESATIVADA',
        message: 'Deseja reativar esta conta?'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == 'ok') {
        await this.activateUser(email)
      } else {
        this.showUserAccountIsNotActivatedAgainMessage()
        this.router.navigate(['home'])
      }
    });
  }

  private async logout() {
    await this.authService.logout().subscribe(
      success => {
        localStorage.setItem('provider', null);
      },
      error => {
        this.authService.clean()
      })
    this.router.navigate(['home'])
  }

  async activateUser(email) {
    this.authService.activateUser(email).toPromise().then(
      success => {
        if (success.code) {
          this.showUserActivationFailMessage()
          this.router.navigate(['login']);
        } else {
          this.showUserAskUserTLoginAgainMessage()
          this.router.navigate(['login']);
        }
      },
      error => {
        this.logout()
        throw new Error("Erro desconhecido");
      })
  }

  private showUserAskUserTLoginAgainMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Sua conta foi reativada, por favor, realize o login novamente.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showUserActivationFailMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Houve algum problema ao reativar sua conta, por favor, tente novamente.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }


  private showUserAccountIsNotActivatedAgainMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Para poder realizar este login você deve reativar sua conta', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }


  goToSignup() {
    this.router.navigate(['signup'])
  }

  openResetPasswordDialog() {
    const dialogRef = this.dialog.open(LoginResetPasswordDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: 'Ooops, seu e-mail ainda não está confirmado!',
        message: 'Por favor, verifique sua caixa de e-mail, inclusive no spam pelo e-mail enviado por nós.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.sendResetPasswordEmail(result).subscribe(
          success => {
            this.showSuccessEmailNotification()
          },
          error => {
            this.showLoginErroDialog('Ooops, houve um erro ao mudar sua senha!',
              'Por favor, tente novamente mais tarde.', true)
          }
        );
      }
    });
  }

  private showSuccessEmailNotification() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos seu e-mail de instruções! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showLoginErroDialog(title, msg, isToShowResentEmailConfirmation) {
    const dialogRef = this.dialog.open(LoginErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg,
        email: this.emailFormControl.value,
        isToShowResentEmailConfirmation: isToShowResentEmailConfirmation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEmailSent) {
        this.showSuccessEmailConfirmationNotification()
      }
    });
  }

  private showSuccessEmailConfirmationNotification() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos um novo e-mail de confirmação! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showSnackBar(msg, type?) {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open(msg, 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: [type],
    });
  }

  get facebookLoginLink(): string {
    return this.apiRoot.concat('facebook/login');
  }

  get googleLoginLink(): string {
    return this.apiRoot.concat('google/login');
  }

  getFincatchData() {
    lastValueFrom(
      this.apiService
      .get_fincatch_data())
      .then((result) => {
        if (result && result.length == 1) {
          console.log("RESSSSSSSSSSSSSSUUUUUUUUUUUUUUULT " + JSON.stringify(result[0]))
          this.fincatchData = result[0];
        } else {
          this.fincatchData = null;
        }
      })
  }


}
