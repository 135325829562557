import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MyErrorStateMatcher, CrossFieldErrorMatcher } from '../input-error-handlers.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog'
import { LoginErroDialog } from '../login/login_error_dialog'
import { LoginPasswordErroDialog } from '../login/login_password_error_dialog'
import { TCAndPPDialogForUserAccepetance } from '../tc_and_pp_dialog_for_user_acceptance/tc_and_pp_dialog_for_user_acceptance'
import { environment } from 'src/environments/environment';
import { RouteStateService } from '../route.state.service'
import { AbstractControl } from '@angular/forms';
import { SexChoices } from '../interface'
import { ApiService } from '../api.service';
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Meta, Title } from '@angular/platform-browser';
import Utils from '../utils';

//TODO put this and date  verification in evaluation in utils
export function ValidateUnderage(control: AbstractControl) {
  if (control.value) {
    const birthday = control.value
    const dateWith18Years = new Date(birthday.getFullYear() + 18, birthday.getMonth(), birthday.getDate())
    const today = new Date();
    if (dateWith18Years > today) {
      return { underage: true };
    }

    return null;
  }

  return null;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  /*providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],*/
})
export class SignupComponent implements OnInit {

  private error: any;
  public emailFormControl: UntypedFormControl;
  private passwordFormControl: UntypedFormControl;
  private passwordConfirmationFormControl: UntypedFormControl;
  public usernameFormControl: UntypedFormControl;
  public matcher: MyErrorStateMatcher;
  public crossMatcher: CrossFieldErrorMatcher;
  public myForm: UntypedFormGroup;
  private apiRoot: string;
  private category_id: any;
  private searchType: any;
  private business_id: number;
  private searchName: string;
  public birthdayFormControl: UntypedFormControl;
  sexFormControl: UntypedFormControl;
  public today: Date;
  sexChoices = SexChoices;
  enumKeys = [];

  tcAndPPText: string;
  userChecks: any[]

  constructor(private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private routeStateService: RouteStateService,
    private apiService: ApiService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {

    this.today = new Date()
    this.apiRoot = environment.apiUrl.concat('backend_api/accounts/')
    this.enumKeys = Object.keys(this.sexChoices);

    this.usernameFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(8),
    ]);

    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);

    this.birthdayFormControl = new UntypedFormControl('', [
      Validators.required,
      ValidateUnderage
    ]);

    this.sexFormControl = new UntypedFormControl('', [
      Validators.required,
    ]);

    /*this.passwordFormControl= new FormControl('', [
      Validators.required,
    ]);

    this.passwordConfirmationFormControl= new FormControl('', [
      Validators.required,
    ]);*/

    this.myForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
      ]),
    },
      this.passwordsShouldMatch,
    )

    this.matcher = new MyErrorStateMatcher();

    this.crossMatcher = new CrossFieldErrorMatcher();
  }

  private passwordsShouldMatch(fGroup: UntypedFormGroup) {
    return fGroup.get('password').value ? fGroup.get('password').value === fGroup.get('confirmPassword').value
      ? null : { 'mismatch': true } : { 'required': true };
  }

  ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Criar conta - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Crie sua conta na Fincatch e comece a avaliar fintechs' }
    );
    this.routeStateService.pathParam.subscribe(
      data => {
        this.category_id = data.category_id
        this.searchType = data.searchType
        this.business_id = data.business_id
        this.searchName = data.searchName
      })

  }

  async signup() {

    if (this.validateForm()) {
      await this.getTCAndPPText()
      await this.getTCAndPPUserChecksText()
      this.showTCAndPPDialogForUserAccepetance()
      //this.showLoginErroDialog("HSAUHAS", "HUXHUSHUSH", false)
    }
    //throw new Error('An error occurred');


  }

  private showSuccessEmailNotification() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos seu e-mail de confirmação! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showLoginErroDialog(title, msg, isToShowResentEmailConfirmation) {
    const dialogRef = this.dialog.open(LoginErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg,
        email: this.emailFormControl.value,
        isToShowResentEmailConfirmation: isToShowResentEmailConfirmation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEmailSent) {
        this.showSuccessEmailConfirmationNotification()
      }
    });
  }

  private showLoginPasswordErroDialog(title, msg) {
    const dialogRef = this.dialog.open(LoginPasswordErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg,
      }
    });
  }

  async getTCAndPPText() {
    await this.apiService.get_tc_and_pp_text().toPromise().then(result => {
      this.tcAndPPText = result[0].content
    });
  }

  async getTCAndPPUserChecksText() {
    await this.apiService.get_tc_and_pp_user_checks_text().toPromise().then(result => {
      this.userChecks = result
      for (var userCheck of this.userChecks) {
        userCheck.userCheckChoice = false;
      }
    });
  }

  private showTCAndPPDialogForUserAccepetance() {
    const dialogRef = this.dialog.open(TCAndPPDialogForUserAccepetance, {
      width: '700px',
      height: '700px',
      data: {
        userChecks: this.userChecks,
        tcAndPPText: this.tcAndPPText
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.userAgreed) {
          this.finalize_signup(result.userChecks)
        } else {
          this.showUserDisagreedMessage()
        }
      } else {
        this.showUserDisagreedMessage()
      }
    });
  }

  private finalize_signup(userChecks) {
    let userChecksDB = []
    for (var uc of userChecks) {
      userChecksDB.push({
        'acceptanceText': uc.id,
        'checked': uc.userCheckChoice
      })
    }
    if (this.validateForm()) {
      let userData = {
        username: this.usernameFormControl.value,
        email: this.emailFormControl.value,
        password1: this.myForm.get('password').value,
        password2: this.myForm.get('confirmPassword').value,
        birthday: this.birthdayFormControl.value,
        sex: this.sexFormControl.value,
        userchecks: userChecksDB
      }
      this.authService.signup(userData).subscribe(
        success => {
          this.showSuccessEmailNotification()
          this.googleAnalyticsService.signUpEvent('email')
          if (this.business_id && this.searchType) {
            this.router.navigate(['evaluation', this.searchType, this.searchName, this.business_id])
          } else if (this.searchType && this.category_id) {
            this.router.navigate(['business_list', this.searchType, this.searchName, this.category_id])
          } else {
            this.router.navigate(['home'])
          }
        },
        error => {
          if (error.error.email && error.error.username) {
            this.showLoginErroDialog('Ooops, este e-mail e username já estão sendo utilizados!',
              'Por favor, tente realizar o login.', false)
          } else if (error.error.email) {
            this.showLoginErroDialog('Ooops, este e-mail já está sendo utilizado!',
              'Por favor, tente realizar o login.', false)
          } else if (error.error.username) {
            this.showLoginErroDialog('Ooops, este username já está sendo utilizado!',
              'Por favor, escolha um novo username.', false)
          } else if (error.error.password1) {
            //TODO improve this
            let msg: string[];
            msg = []
            msg.push("* A senha precisa ter no mínimo 8 caracteres.")
            msg.push("* A senha deve conter letras e caracteres especiais.")
            msg.push("* Não utilize palavras fáceis ou números em sequência.")
            this.showLoginPasswordErroDialog('A senha é muito fraca, por favor, faça as alterações:', msg);
          } else {
            throw new Error(error);
          }
        }
      )
    }

  }

  private showUserDisagreedMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Você precisa concordar com os Termos de Uso e Política de Privacidade para que sua conta seja criada.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }


  private showSuccessEmailConfirmationNotification() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Enviamos um novo e-mail de confirmação! Por favor, verifique sua caixa de entrada.', 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  get facebookLoginLink(): string {
    return this.apiRoot.concat('facebook/login');
  }

  get googleLoginLink(): string {
    return this.apiRoot.concat('google/login');
  }

  private underage() {
    //console.log("this.birthdayFormControl.value ", this.birthdayFormControl.value)
    return null;
  }

  private validateForm() {
    return this.usernameFormControl.valid &&
      this.emailFormControl.valid &&
      this.myForm.controls['password'].valid &&
      this.myForm.controls['confirmPassword'].valid &&
      this.myForm.valid &&
      this.birthdayFormControl.valid &&
      this.sexFormControl.valid
  }

  onOptionsSelected(ev) {
    //onsole.log("ev ", ev); // should print option1
    //console.log("this.sexFormControl.value ", this.sexFormControl.value);
  }

}
