import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteStateService } from '../route.state.service'
import { Subject } from 'rxjs';
import { SearchType } from '../interface'
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export interface DialogData {
  title: string;
  message: string;
  email: string;
  isToShowResentEmailConfirmation: boolean
}

@Component({
  selector: 'login_error_dialog',
  templateUrl: 'login_error_dialog.html',
  styleUrls: ['./login_error_dialog.css']
})
export class LoginErroDialog {

  private emailFormControl: UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<LoginErroDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService) {
    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);

  }

  resentEmailConfirmation(): void {
    this.authService.resendConfirmationEmail(this.data.email).subscribe(
      success => {
        const isEmailSent = true
        this.dialogRef.close(isEmailSent);
      },
      error => {
        //  console.log(">>>>>>> resendConfirmationEmail error ", error)
        //console.log("RESEND EMAIL ERROR")
        //TODO remove this error capture
        //throw new Error(this.error);
        //console.log("LoginErroDialog RESEND EMAIL ERROR " + error)
        //console.log("LoginErroDialog RESEND EMAIL ERROR " + JSON.stringify(error))
      }
    );
  }

}
