
<banner categoryId={{category_id}}></banner>

<div fxLayout="column" fxLayoutAlign="center center">
  <div>
    <p class="search_type_p">Ranking {{searchTypeString}}</p>
    <h1 class="category_product_title">{{category?.name}}</h1>
  </div>
  <div *ngIf="subCategories" class="subcategory-padding">
    <mat-form-field class="subCategory" ngClass.lt-sm="subCategory-sm" appearance="outline">
      <mat-label>Selecione uma Subcategoria</mat-label>
      <mat-select class="select" [(ngModel)]="selected" (selectionChange)='onOptionsSelected($event)'>
        <mat-option class="font-family">Nenhuma</mat-option>
        <mat-option *ngFor="let subCategory of subCategories" [value]="subCategory">
          <span class="subCategory-font-size">{{subCategory.name}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <alert id="alert-success"></alert>
    <div *ngIf="$searchResult">
      <div fxLayout="row wrap" fxLayoutGap="24px grid" fxLayoutGap.lt-md="16px grid" fxLayoutAlign="center none">
        <div
          [ngClass]="{'fxFlex-three-itens-or-less': $searchResult.length < 4 && $searchResult.length > 1, 'fxFlex-one-item': $searchResult.length == 1, 'fxFlex-lots-of-itens': $searchResult.length >= 4}"
          fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let b of $searchResult; let i = index">
          <mat-card appearance="outlined" class="card mat-elevation-z4">
            <mat-card-header class="header-image-padding">
              <div mat-card-avatar class="header-image" [style.background-image]="'url(' + b.business.logo_small + ')'">
              </div>
              <mat-card-title class="title" (click)="evaluationResult(b.business)">{{b.business.name}}
              </mat-card-title>


              <span class="fa-stack fa-lg position" *ngIf="b.businessRate > 0">
                <i *ngIf="b.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
                <i *ngIf="b.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
                <i *ngIf="b.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
                <i *ngIf="b.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
                <i class="fa fa-stack-1x pull-top" style="color: #291749; font-size:1rem">{{b.position}}</i>
              </span>

            </mat-card-header>

            <mat-card-content class="card_content">
              <div class="rating" fxLayout="row" fxLayoutAlign="center center">
                <ng-template #t let-fill="fill">
                  <span class="fas star material-icons " [class.full]="fill === 100">
                    <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
                  </span>
                </ng-template>

                <ngb-rating [(rate)]="b.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

                <span class="rate">{{b.businessRate}}</span>
              </div>


              <div fxLayout="row" fxLayoutAlign="center center">
                <a mat-button class="btn-evaluation" [ngClass]="decideBtnColor(i + 1)"
                  (click)="evaluation(b.business)">Avaliar esta empresa!</a>
              </div>
              <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
                <a class="visualize" (click)="evaluationResult(b.business)"
                  [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
              </div>
              <div *ngIf="!b.isRankable && b.businessRate > 0"><span class="isRankable_msg">Esta fintech ainda não
                  possui o
                  mínimo de avaliações
                  para entrar no ranking.</span>
              </div>
            </mat-card-content>
          </mat-card>

        </div>

      </div>

    </div>
  </div>