
<div class="main_div" fxLayout="row" fxLayoutAlign="center none">

     <div class="padding-text" fxLayout="column" fxLayoutAlign="center stretch">
       <p class="first_part_text">Encontre a fintech perfeita para você e para <span class="last_part_text">sua carteira</span></p>
     </div>
     <div  fxLayout="column" fxLayoutAlign="center stretch" >
         <form class="padding-search" [formGroup]="searchForm" >
           <div class=" div-autocomplete" fxLayout="row" fxLayoutAlign="center center">
             <mat-icon class="fas fa-search"  ></mat-icon>
             <input class="category-form" type="text"  placeholder="Categorias" matInput formControlName="search" [(ngModel)]="search_subCategory" [matAutocomplete]="autoDialog" >
           </div>
             <mat-autocomplete  #autoDialog="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="updateList($event)">
               <mat-option>Nenhuma</mat-option>
               <mat-optgroup *ngIf="$filteredCategorys">-----Categorias
                 <mat-option *ngFor="let search of $filteredCategorys | async" [value]="search">
                   {{search.name}}
                 </mat-option>
               </mat-optgroup>
             </mat-autocomplete>

         </form>
         <div class="padding-or" fxLayout="column" fxLayoutAlign="center center" >
          <span style="color:white">------- OU ---------</span>
         </div>
         <div class="padding-search" fxLayout="row" fxLayoutAlign="center center">
           <input class="input-business" type="text" [(ngModel)]="search_business"  placeholder="Nome da empresa"   >
           <span><a mat-raised-button class="btn-search-business" (click)="searchBusiness(search_business)">BUSCAR</a></span>
         </div>
     </div>
</div>

<div *ngIf="filteredBusinessByCategory">
    <mat-selection-list [multiple]="false" *ngFor="let cat of filteredBusinessByCategory">
      <div *ngIf="cat.business && cat.business.length > 0">
          <div class=" grid-subCategory ">- {{cat.name}}</div>
            <mat-grid-list cols="5" rowHeight="150px">
              <mat-grid-tile  *ngFor="let bus of cat.business" (click)="goToPage(bus, cat.id, cat.name)">
                <mat-card  appearance="outlined"  class="example-card mat-elevation-z4">
                  <mat-card-header>
                    <div mat-card-avatar class="example-header-image"  [style.background-image]="'url(assets/' + bus.logo_small + ')'"></div>
                    <mat-card-title class="title" >{{bus.name}}</mat-card-title>
                  </mat-card-header>
                </mat-card>
              </mat-grid-tile>
          </mat-grid-list>
      </div>
    </mat-selection-list>


</div>

<div fxLayout="column" fxLayoutAlign="center center" class="without-results"  *ngIf="!filteredBusinessByCategory || filteredBusinessByCategory.length < 0"  >
  <p>Realize uma pesquisa para obter resultados</p>
</div>
