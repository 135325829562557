<banner *ngIf="businessResult" businessId={{businessResult.business.id}}></banner>


<div class="business-info" fxLayout="column">
  <div class="label" ngClass.lt-sm="label_lt-sm" fxLayout="row" fxLayoutAlign="start center">
    <span><i>Você está avaliando a empresa:</i></span>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-sm="column">
    <div fxLayout="column" fxLayoutAlign="center start">
      <img *ngIf="businessResult" class="header-image" src='{{ businessResult?.business.logo }}' />
    </div>
    <div ngClass.gt-sm="business-name-desc" fxLayout="column" fxLayoutAlign="center start"
      fxLayoutAlign.lt-sm="center center">
      <h1 class="business-name">{{businessResult?.business.name}}</h1>
      <h2 class="business-description">{{businessResult?.business.description}}</h2>
    </div>
  </div>
  <alert class="center" id="alert-error-answer"></alert>
  <alert class="center" id="warn"></alert>
  <alert class="center"></alert>
  <hr />
</div>

<div class="questions-div">
  <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="center none">
    <div fxFlex="1 0 33%" fxFlex.xs="1 0 100%" fxFlex.sm="1 0 40%" *ngFor="let b of businessResult?.questions">
      <div fxLayout="column" fxLayoutAlign="center center" class="tile-border ">

        <span class="question">{{b.question}}</span>

        <div *ngIf="!b.isRecomendationQuestion" fxLayout="column" fxLayoutAlign="center center">

          <ng-template #t let-fill="fill">
            <span class="fas star material-icons" [class.full]="fill === 100">
              <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>

          <ngb-rating [ngModel]="b.userRating && b.userRating.userRating"
            (ngModelChange)="b.userRating && b.userRating.userRating=$event" [starTemplate]="t" max="5"></ngb-rating>

          <span *ngIf="!isEditing" class="center small-font">Selecione sua nota</span>
          <span *ngIf="isEditing" class="center small-font">Deseja alterar sua nota?</span>

        </div>
        <div *ngIf="b.isRecomendationQuestion" fxLayout="column" fxLayoutAlign="center center">

          <ng-template #t let-fill="fill">
            <span class="fas star material-icons" [class.full]="fill === 100">
              <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>

          <ngb-rating [ngModel]="b.userRating && b.userRating.userRating"
            (ngModelChange)="b.userRating && b.userRating.userRating=$event" [starTemplate]="t" max="10"></ngb-rating>

          <span *ngIf="!isEditing" class="center small-font">Selecione sua nota</span>
          <span *ngIf="isEditing" class="center small-font">Deseja alterar sua nota?</span>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="comment-div">
  <div class="clientHowLong-section" fxLayout="column" fxLayoutAlign="space-around center">
    <mat-form-field class="clientHowLong" appearance="outline">
      <mat-label class="font-family">É cliente da empresa?</mat-label>
      <mat-select class="select" [(ngModel)]="selectedName" (selectionChange)='onChangeEvent($event)'>
        <mat-option class="font-family">Nenhuma</mat-option>
        <mat-option class="font-family" *ngFor="let selectedName of enumKeys" [value]="selectedName">
          {{clientForHowLong[selectedName]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
      <p *ngIf="!isNotEmptyComment" class="comment_label">Escreva sua avaliação:</p>
      <p *ngIf="isNotEmptyComment" class="comment_label">Sua última avaliação:</p>
      <p *ngIf="isPending" ngClass.lt-sm="comment_status">Seu comentário está <b>PENDENTE</b>. Ele está sendo
        processado. </p>
      <p *ngIf="isDenied" ngClass.lt-sm="comment_status">Seu comentário foi <b>NEGADO</b>, por favor, faça a sua
        revisão:</p>

      <mat-form-field ngClass.gt-md="full-width" ngClass.lt-sm="full-width_lt_sm" ngClass.sm="full-width_sm"
        appearance="outline">
        <mat-label class="font-family">Avaliação</mat-label>
        <textarea class="font-family" ngClass.gt-md="textarea" ngClass.lt-sm="textarea_lt_sm" ngClass.sm="textarea_sm"
          [(ngModel)]="comment" [maxlength]="maxChars" matInput placeholder="Máximo de 280 caracteres"
          [disabled]="isCommentDisabled"></textarea>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between center" ngClass.sm="bottom_info_sm"
        ngClass.lt-sm="bottom_info_lt-sm" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm=" stretch">

        <div fxLayout="row" fxLayoutAlign="start center" fxFlexOrder.lt-sm="2">
          <mat-checkbox class="checkBox" ngDefaultControl [(ngModel)]="isAnonymousComment">
            <span class="wrap-mat-checkbox-label font-family">Quero que meu comentário seja anônimo.</span>
          </mat-checkbox>

        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxFlexOrder.lt-sm="1">
          <label class="font-family">{{comment.length}}/{{maxChars}}</label>
        </div>

      </div>

    </div>
  </div>
  <hr />
  <div class="center">
    <a *ngIf="!isEditing" class="btn-send" mat-button (click)="send_evaluation()">Enviar sua Avaliação!</a>
    <a *ngIf="isEditing" [disabled]="isAbleToSendEdit" class="btn-send" mat-button (click)="send_evaluation()">Alterar
      sua Avaliação!</a>
  </div>
</div>