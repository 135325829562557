import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MyErrorStateMatcher, CrossFieldErrorMatcher } from '../input-error-handlers.component';
import { AuthService } from '../auth.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { LoginErroDialog } from './login_error_dialog'
import { MatDialog } from '@angular/material/dialog';
import { LoginPasswordErroDialog } from '../login/login_password_error_dialog'

@Component({
  selector: 'reset_password_confirm',
  templateUrl: './reset_password_confirm.html',
  styleUrls: ['./reset_password_confirm.css']
})
export class ResetPasswordConfirm implements OnInit {

  private matcher: MyErrorStateMatcher;
  public crossMatcher: CrossFieldErrorMatcher;
  private passwordFormControl: UntypedFormControl;
  private passwordConfirmationFormControl: UntypedFormControl;
  public myForm: UntypedFormGroup;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) {

    this.myForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
      ]),
    },
      this.passwordsShouldMatch,
    )

    this.matcher = new MyErrorStateMatcher();

    this.crossMatcher = new CrossFieldErrorMatcher();
  }

  async ngOnInit() {
    //console.log("***************************** ResetPasswordConfirm INIT *****************")
  }

  private showLoginPasswordErroDialog(title, msg) {
    const dialogRef = this.dialog.open(LoginPasswordErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg,
      }
    });
  }

  resetPassword() {
    if (this.myForm.valid) {
      const uid: string = this.activatedRoute.snapshot.paramMap.get('uid');
      const token: string = this.activatedRoute.snapshot.paramMap.get('token');

      this.authService.resetPasswordConfirm(uid, token, this.myForm.get('password').value,
        this.myForm.get('confirmPassword').value).subscribe(
          (success: any) => {

            if (success.code) {
              switch (success.code) {
                case 1000:
                  this.showLoginErroDialog('Ooops, houve algum problema!',
                    'Por favor, tente novamente mais tarde.')
                  break;
                case 801:
                  this.showLoginErroDialog('Houve um problema',
                    'Por favor solicite um novo e-mail para troca de senha e tente novamente através dele.')
                  break;
                case 802:
                  this.showLoginErroDialog('Dados estão incorretos',
                    'Por favor, verifique os dados e tente novamente.')
                  break;
                case 803:
                  let msg: string[];
                  msg = []
                  msg.push("* A senha precisa ter no mínimo 8 caracteres.")
                  msg.push("* A senha deve conter letras e caracteres especiais.")
                  msg.push("* Não utilize palavras fáceis ou números em sequência.")
                  this.showLoginPasswordErroDialog('A senha é muito fraca, por favor, faça as alterações:', msg);
                  break;
                default: throw new Error("Erro desconhecido - Troca de senha");
              }
            } else {
              this.showResetPasswordSuccessNotification()
              this.router.navigate(['login'])
            }
          },
          error => {
            this.showLoginErroDialog('Ooops, houve algum problema!',
              'Por favor, tente novamente mais tarde.')
          }
        );

    }
  }

  private passwordsShouldMatch(fGroup: UntypedFormGroup) {
    return fGroup.get('password').value === fGroup.get('confirmPassword').value
      ? null : { 'mismatch': true };
  }

  private showResetPasswordSuccessNotification() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Sua senha foi alterada com sucesso!', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showLoginErroDialog(title, msg) {
    const dialogRef = this.dialog.open(LoginErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg
      }
    });
  }

}
