import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SearchType, Category } from '../interface'


export interface DialogData {
  tcAndPPText: string;
  userChecks: any[]
}

@Component({
  selector: 'tc_and_pp_dialog_for_user_acceptance',
  templateUrl: 'tc_and_pp_dialog_for_user_acceptance.html',
  styleUrls: ['./tc_and_pp_dialog_for_user_acceptance.css']
})

export class TCAndPPDialogForUserAccepetance {

  userClickedInBtn: boolean


   constructor(
    public dialogRef: MatDialogRef<TCAndPPDialogForUserAccepetance>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

 async ngOnInit() {
   this.userClickedInBtn = false

  }



  onCancelClick() {
    this.userClickedInBtn = true
    this.dialogRef.close({
      userAgreed: false
    });
  }

  onOKClick() {
    this.userClickedInBtn = true
    if (this.validatedUserCheckedAllBoxes()){
      this.dialogRef.close({
        userAgreed: true,
        userChecks: this.data.userChecks
      });
    }
  }

  validatedUserCheckedAllBoxes(){
    let userCheckedAllBoxes = true
    for (let userCheck of this.data.userChecks) {
      if (!userCheck.userCheckChoice) {
        userCheckedAllBoxes = false
      }
    }

    return userCheckedAllBoxes
  }

}
