<h1 mat-dialog-title fxLayoutAlign="center center">{{data?.title}}</h1>
<div mat-dialog-content>
  <p>&nbsp;&nbsp;{{data?.message}}</p>
  <div *ngIf="data?.isToShowResentEmailConfirmation" fxLayout="column"  fxLayoutAlign="center center">
    <mat-divider></mat-divider>
    <div>Mesmo assim, ainda não recebeu seu e-mail de confirmação?</div>
    <button class="btn-block" mat-raised-button color="primary" (click)="resentEmailConfirmation()">Reenviar Email de Confirmação</button>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>
