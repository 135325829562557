import { Component, OnInit } from '@angular/core';
import { FAQInterface } from '../interface'
import { ApiService } from '../api.service'

@Component({
  selector: 'app-list',
  templateUrl: './faq.html',
  styleUrls: ['./faq.css'],
  //TODO this is needed to change the mat-form-field css but affects the mat-button, solve it
  //encapsulation: ViewEncapsulation.None
})
export class FAQ implements OnInit {

  public faqs: FAQInterface[]

  constructor(
    private apiService: ApiService,
  ) { }

  async ngOnInit() {
    await this.getFAQ()
  }

  async getFAQ() {

    let res = await this.apiService.get_faq().toPromise().then(result => {
      this.faqs = result
    })
  }

}
