<h1 mat-dialog-title fxLayoutAlign="center center">{{data?.title}}</h1>
<div mat-dialog-content *ngIf="data.message">
    <div *ngFor="let msg of data.message">
        <p>&nbsp;&nbsp;{{msg}}</p>

    </div>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>