import {
   Injectable
} from '@angular/core';
import {
   Meta,
   Title
} from '@angular/platform-browser';

@Injectable()
export class SEOService {
   constructor(private title: Title, private meta: Meta) { }

   updateTitle(title: string) {
      this.title.setTitle(title);
   }

   updateOgUrl(url: string) {
      this.meta.updateTag({
         name: 'og:url',
         content: url
      })
   }

   updateDescription(desc: string) {
      this.meta.updateTag({ name: 'description', content: desc })
      /* if (this.meta.getTag('description')) {
           this.meta.updateTag({ content: desc} , 'name="description"' )
         console.log("UPDATED ")
       } else {
           this.meta.addTags([{ name: 'description', content: desc },]);
            console.log("ADDED")
       }*/
   }

   updateKeywords(keywords: string) {
      this.meta.updateTag({
         name: 'keywords',
         content: keywords
      })
   }
}