import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { RouteStateService } from '../route.state.service'
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

import { SearchType, CommentStatus, ClientForHowLong } from '../interface'

import { AlertService } from '../_alert';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { GeneralDialog } from '../general_dialog/general_dialog';

import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Meta, Title } from '@angular/platform-browser';
import Utils from '../utils';


@Component({
  selector: 'app-list',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css'],
  //TODO this is needed to change the mat-form-field css but affects the mat-button, solve it
  //encapsulation: ViewEncapsulation.None
})
export class EvaluationComponent implements OnInit {

  private category_id: any;
  searchType: any;
  private business_id: any;
  public businessResult: any;
  private error: any;
  public isEditing = false;
  public isAbleToSendEdit = false;
  private originalQuestionRatingsByUser: any;
  searchName: string;
  public maxChars: number;
  public comment: string;
  userComment: any;
  isCommentDisabled: any;
  clientForHowLong = ClientForHowLong;
  enumKeys = [];
  selectedHowLong: any;
  selectedName: any;
  savedIsAnonymousComment: boolean
  isAnonymousComment: boolean

  constructor(private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routeStateService: RouteStateService,
    private authService: AuthService,
    protected alertService: AlertService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {
    Object.keys(this.clientForHowLong).forEach(key => {
      if (key != 'Z') {
        this.enumKeys.push(key)
      }
    })
    this.maxChars = 280
    this.comment = ""
    this.business_id = +this.activatedRoute.snapshot.paramMap.get('business_id');
    this.category_id = +this.activatedRoute.snapshot.paramMap.get('category_id');
    this.searchType = this.activatedRoute.snapshot.paramMap.get('searchType');
    this.searchName = this.activatedRoute.snapshot.paramMap.get('search_name');
  }

  async ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Avaliação de fintech - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Faça a sua avaliação da fintech escolhida.' }
    );
    window.scroll(0, 0);
    //TODO which kind of object is this.routeStateService.pathParam
    //TODO access the value better
    this.routeStateService.pathParam.subscribe(
      data => {
        this.category_id = data.category_id
      }
    )
    await this.getBusinessById()
    await this.getQuestionsByCategoryById()

  }

  async getQuestionsByCategoryById() {

    await this.apiService.list_question_by_subCategory(this.business_id).toPromise().then(result => {
      this.businessResult.questions = result
    });
    await this.getRatingQuestionByBusinessAndUser()
    await this.gerUSerComment()

  }

  async getBusinessById() {
    let res = await this.apiService.list_business(this.business_id).toPromise().then(result => {
      this.businessResult = {
        business: result
      }
      if (!this.businessResult.business.logo.includes('http')) {
        this.businessResult.business.logo = 'assets/' + this.businessResult.business.logo
      }
    });
  }

  async getRatingQuestionByBusinessAndUser() {
    let questionRatingsByUser: any
    await this.apiService.list_rating_question_by_business_and_user(this.business_id, this.authService.currentUserValue.id).toPromise()
      .then(result => {
        questionRatingsByUser = result[0]
      });

    if (questionRatingsByUser && questionRatingsByUser.questionRating && Array.isArray(questionRatingsByUser.questionRating) && questionRatingsByUser.questionRating.length) {
      this.isEditing = true;
      this.alertService.warn('Você já avaliou essa empresa! Deseja alterar sua avaliação?', { id: 'warn' })
      this.businessResult.selectedClientHL = questionRatingsByUser.client_how_long
      this.selectedName = this.businessResult.selectedClientHL
    }
    this.businessResult.questions.map(question => {
      let q = questionRatingsByUser ? questionRatingsByUser.questionRating.find(x => x.question__id === question.id) : null
      question.userRating = {
        userRating: q ? q.rating : null,
        id: q ? q.id : null
      }
    })

    //way to clone the array
    this.originalQuestionRatingsByUser = JSON.parse(JSON.stringify(this.businessResult.questions));
  }

  async gerUSerComment() {
    await this.apiService.get_user_comment_by_group(this.business_id, this.authService.currentUserValue.id).toPromise()
      .then(result => {
        this.userComment = result[0]
        //TODO improve how to keep user comment information
        this.comment = this.userComment ? this.userComment.text : ''
        this.savedIsAnonymousComment = this.userComment ? this.userComment.isAnonymousComment : false
        this.isAnonymousComment = this.userComment ? this.userComment.isAnonymousComment : false
        this.disableComment()
      });
  }

  disableComment() {
    if (this.userComment && this.userComment.comment_status) {
      if (this.userComment.comment_status == CommentStatus.Approve.valueOf()) {
        //this.isCommentDisabled = false
        this.isCommentDisabled = this.checkIfCommentIsOneMonthOld()
      } else if (this.userComment.comment_status == CommentStatus.Denied.valueOf()) {
        this.isCommentDisabled = false
      } else {
        this.isCommentDisabled = true
      }
    }
  }

  get isPending() { return this.userComment && this.userComment.comment_status == CommentStatus.Pending.valueOf() }

  get isDenied() { return this.userComment && this.userComment.comment_status == CommentStatus.Denied.valueOf() }

  get isNotEmptyComment() { return this.userComment && this.userComment.text }

  //TODO put this and underage verification in sigunp in utils
  checkIfCommentIsOneMonthOld() {
    if (this.userComment && this.userComment.updated_at) {
      const date = new Date(this.userComment.updated_at)
      const dateWith1Month = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
      const today = new Date();
      if (dateWith1Month > today) {
        return true
      }

      return false;
    }

    return null;
  }

  private verifyIfAnyAnswerIsZero() {
    let atLeastOneAnswerIsZero = false
    for (let q of this.businessResult.questions) {

      if (!q.isRecomendationQuestion && !q.userRating.userRating) {
        atLeastOneAnswerIsZero = true;
        break;
      }
    }
    return atLeastOneAnswerIsZero
  }

  private verifyUserHasChangedCommentText() {
    let hasChangedComment = false

    //user had given rates to the bussiness but now is just addiing a comment
    if (this.comment && !this.userComment) {
      hasChangedComment = true
      //user updated his comment
    } else if (this.userComment && (this.comment.trim().toLowerCase().replace(/\s/g, "") != this.userComment.text.trim().toLowerCase().replace(/\s/g, ""))) {
      hasChangedComment = true
      //user is rating and creating a comment all for the first time
    }
    return hasChangedComment
  }

  private verifyUserHasChangedClientForHowLong() {
    let hasChangedClientForHowLong = false
    if (this.businessResult.selectedClientHL && this.businessResult.selectedClientHL != this.selectedHowLong) {
      if (ClientForHowLong[this.businessResult.selectedClientHL] == ClientForHowLong.Z && (this.selectedHowLong == null || this.selectedHowLong == undefined)) {
        hasChangedClientForHowLong = false
      } else {
        hasChangedClientForHowLong = true
      }
    }
    return hasChangedClientForHowLong
  }

  private verifyUserChangedIsAnonymousComment() {
    let hasChangedIsAnonymousComment = false
    if (this.userComment) {
      hasChangedIsAnonymousComment = this.userComment.isAnonymousComment != this.isAnonymousComment
    } else if (this.comment) {
      hasChangedIsAnonymousComment = this.savedIsAnonymousComment != this.isAnonymousComment
    }
    return hasChangedIsAnonymousComment
  }

  private generateDataForEdit() {
    let questionsRatings = []
    for (let q of this.businessResult.questions) {
      questionsRatings.push({
        rating: q.userRating.userRating,
        question: q.id,
        id: q.userRating.id,
      })
    }

    //user is editing a evaluation which already had a comment
    let userCommentToSend = null
    if (this.userComment) {
      userCommentToSend = this.userComment
      userCommentToSend.text = this.verifyUserHasChangedCommentText() ? this.comment : this.userComment.text
      userCommentToSend.isAnonymousComment = this.verifyUserChangedIsAnonymousComment() ? this.isAnonymousComment : this.userComment.isAnonymousComment

      //user is adding a comment to an existing evaluation
    } else if (this.comment && !this.userComment) {
      userCommentToSend = {
        text: this.comment,
        isAnonymousComment: this.isAnonymousComment
      }
    }
    let data = {
      questions_ratings: questionsRatings,
      user_comment: userCommentToSend,
      groupData: {
        business: this.businessResult.business.id,
        user: this.authService.currentUserValue.id,
        //if user didnt change How Long field, this.selectedHowLong is undefined
        client_how_long: this.selectedHowLong ? this.selectedHowLong : this.businessResult.selectedClientHL,
      },
    }
    return data
  }

  private generateDataForCreating() {
    let questionsRatings = []
    for (let q of this.businessResult.questions) {
      let rating = q.userRating.userRating
      if (q.isRecomendationQuestion && (rating == null || rating == undefined)) {
        rating = 0
      }
      questionsRatings.push({
        rating: rating,
        question: q.id,
      })
    }

    if (this.comment) {
      this.userComment = {
        text: this.comment,
        isAnonymousComment: this.isAnonymousComment
      }
    }
    let data = {
      questions_ratings: questionsRatings,
      user_comment: this.userComment ? this.userComment : null,
      groupData: {
        business: this.businessResult.business.id,
        user: this.authService.currentUserValue.id,
        //if user didnt change How Long field, this.selectedHowLong is undefined
        client_how_long: this.selectedHowLong,
      },
    }
    return data
  }

  async send_evaluation() {
    if (this.isEditing) {
      if (this.verifyIfAnyAnswerIsZero()) {
        this.showErrorMsg("A nota mínima para cada questão é 1!")
      } else {
        if (this.validateChange() || this.verifyUserHasChangedCommentText() || this.verifyUserChangedIsAnonymousComment() || this.verifyUserHasChangedClientForHowLong()) {
          if (this.verifyUserHasChangedCommentText()) {
            this.openConfirmationDialog()
          } else {
            let data = this.generateDataForEdit()
            await this.updateEvaluation(data)
            this.googleAnalyticsService.updateEvaluationWithoutCommentEvent(this.businessResult.business.name)
          }

        } else {
          this.showErrorMsg("Você não mudou sua avaliação!")
        }
      }
    } else {
      if (this.verifyIfAnyAnswerIsZero()) {
        this.showErrorMsg("A nota mínima para cada questão é 1!")
      } else {
        if (this.verifyUserHasChangedCommentText()) {
          this.openConfirmationDialog()
        } else {
          let data = this.generateDataForCreating()
          await this.createEvaluation(data)
          this.googleAnalyticsService.createEvaluationWithoutCommentEvent(this.businessResult.business.name)
        }
      }
    }
  }

  showErrorMsg(message) {
    this.alertService.clear('alert-error-answer')
    this.alertService.error(message, { id: 'alert-error-answer' })
    window.scroll(0, 0);
  }

  validateChange() {
    let isChanged = false;
    let item: any;
    this.originalQuestionRatingsByUser.forEach(element => {
      item = this.businessResult.questions.find(e => e.id == element.id);
      isChanged = isChanged ? isChanged : (item.userRating.userRating != element.userRating.userRating)
    });
    return isChanged
  }

  async updateEvaluation(data) {
    await this.apiService.update_rating_question(data).toPromise().then(success => {
      this.showSnackBar('Sua avaliação foi salva com sucesso!', 'success')
      this.goToPageBreadcrumb()
    },
      error => {
        throw new Error(error);
      });;

  }

  async createEvaluation(data) {
    await this.apiService.create_rating_question(data).toPromise().then(result => {
      this.showSnackBar('Sua avaliação foi salva com sucesso!', 'success')
      this.goToPageBreadcrumb()
    },
      error => {
        throw new Error(error);
      });;
  }

  private showSnackBar(msg, type?, verticalP?) {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = verticalP ? verticalP : 'top';
    this._snackBar.open(msg, 'Fechar', {
      duration: 5000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: [type],
    });
  }

  onChangeEvent(ev) {
    if (ev.value) {
      this.selectedHowLong = ev.value
    } else {
      this.selectedHowLong = ClientForHowLong.Z
    }
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(GeneralDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: 'Obrigado por comentar! ',
        message: ' Seu comentário será processado, você receberá notificações por e-mail.'
          + ' Confirmar envio da Avaliação com o comentário?'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == 'ok') {
        if (this.isEditing) {
          let data = this.generateDataForEdit();
          await this.updateEvaluation(data)
          this.googleAnalyticsService.updateEvaluationWithCommentEvent(this.businessResult.business.name)
        } else {
          let data = this.generateDataForCreating();
          await this.createEvaluation(data)
          this.googleAnalyticsService.createEvaluationWithCommentEvent(this.businessResult.business.name)
        }
      }
    });
  }

  goToPageBreadcrumb() {
    if (this.searchType == SearchType.Tag.valueOf() || this.searchType == SearchType.Fintech.valueOf()) {
      localStorage.setItem('searchType', this.searchType);
      localStorage.setItem('searchName', this.searchName);
      localStorage.setItem('category_id', this.category_id);
      localStorage.setItem('business_id', this.business_id);
      this.router.navigate(['home'])
    } else if (this.searchType == SearchType.Category.valueOf() && this.searchType && this.searchName && this.category_id) {
      this.router.navigate(['business_list', this.searchType, this.searchName, this.category_id])
    } else {
      this.router.navigate(['home'])
    }
  }



}
