import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../auth.service';

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'general_dialog',
  templateUrl: 'general_dialog.html',
  styleUrls: ['./general_dialog.css']
})
export class GeneralDialog {

  constructor(
    public dialogRef: MatDialogRef<GeneralDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService) {}

    onOkClick(): void {
      this.dialogRef.close('ok');
    }
    onCancelClick(): void {
      this.dialogRef.close('cancel');
    }

}
