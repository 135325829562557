import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpHeaders } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';

import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { tap, shareReplay, switchMap, catchError } from 'rxjs/operators';

import jwt_decode from 'jwt-decode';
import * as moment from 'moment';

import { environment } from '../environments/environment';

import { User, JWTPair, UserStatus } from './interface'
import Utils from './utils';


@Injectable()
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private apiRoot: string;
  private apiRootAuth: string;

  private isDevEnviroment: boolean

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.apiRootAuth = environment.apiUrl + 'backend_api/auth/';
    this.apiRoot = environment.apiUrl
    this.isDevEnviroment = Utils.isDevEnviroment()
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  private setSession(authResult) {
    if (authResult.refresh) {
      const refresh = authResult.refresh;
      const payloadRefresh = <JWTPayload>jwt_decode(refresh);
      const refreshExpiresAt = moment.unix(payloadRefresh.exp);

      localStorage.setItem('refresh', refresh);
      localStorage.setItem('refresh_expires_at', JSON.stringify(refreshExpiresAt.valueOf()));
    }

    if (authResult.access) {
      const access = authResult.access;
      const payloadAccess = <JWTPayload>jwt_decode(access);
      const refreshAccessAt = moment.unix(payloadAccess.exp);

      localStorage.setItem('access', access);
      localStorage.setItem('access_expires_at', JSON.stringify(refreshAccessAt.valueOf()));
    }
  }

  private eraseSession(authResult?) {
    localStorage.removeItem('refresh');
    localStorage.removeItem('refresh_expires_at');
    localStorage.removeItem('access');
    localStorage.removeItem('access_expires_at');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('category_id');
    localStorage.removeItem('searchType');
    localStorage.removeItem('business_id');
    localStorage.removeItem('searchName');
    this.currentUserSubject.next(null);
  }

  get tokenAccess(): string {
    return localStorage.getItem('access');
  }

  get tokenRefresh(): string {
    return localStorage.getItem('refresh');
  }

  getAccessExpiration() {
    const expiration = localStorage.getItem('access_expires_at');
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }

  getRefreshExpiration() {
    const expiration = localStorage.getItem('refresh_expires_at');
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }

  setUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  login(email: string, password: string) {
    let url = 'backend_api/custom/login/'
    if (this.isDevEnviroment) url = this.apiRoot + url
    return this.http.post(
      url,
      //'backend_api/custom/login/',
      { email, password }
    ).pipe(
      /*catchError(error => {
      }),*/
      switchMap((data: any) => {
        if (data.code) {
          return of(data)
        }
        return this.getToken(email, password, data.user.id, data.user.username);
      }),
      tap(response => {
        this.setSession(response)
      }),
      shareReplay()
    );
  }

  signup(userData) {
    let url = 'backend_api/auth/signup/'
    if (this.isDevEnviroment) url = this.apiRoot + url
    return this.http.post(
      url,
      //'backend_api/auth/signup/',
      //this.apiRootAuth.concat('signup/'),
      //this.apiRoot.concat('custom/signup/'),
      userData
    ).pipe(
      tap(response => {
        this.setSession(response)
      }),
      shareReplay(),
    );
  }

  verifyUserHasAcceptedTCAndPP(email) {
    let params = new HttpParams().set('email', email);
    let url = 'backend_api/finance_api/verifyUserHasAcceptedTCAndPP'
    if (this.isDevEnviroment) url = this.apiRoot + url
    return this.http.get(url, { params });
  }


  editUser(data, id) {
    const headers = new HttpHeaders({ 'credentials': 'include' });
    let url = 'backend_api/finance_api/userUpdate/' + id
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.patch<any>(url, data)
  }

  getUser(id) {
    let params = new HttpParams().set('id', id);
    let url = 'backend_api/auth/user'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.get(url, { params });
  }

  deactivateUser(id) {
    const headers = new HttpHeaders({ 'credentials': 'include' });
    let url = 'backend_api/finance_api/deactivateUser/' + id
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.patch<any>(url, { status: UserStatus.I })
  }

  activateUser(email) {
    let params = new HttpParams().set('email', email);
    //TODO improve this, sending random id just to activate user
    let url = 'backend_api/finance_api/activateUser/' + 12425
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.patch<any>(url, { email })
  }


  clean() {
    this.eraseSession();
  }

  logout() {
    let url = 'backend_api/auth/logout/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      url, {}).pipe(
        tap(response => {
          this.eraseSession(response)
        }),
        shareReplay(),
      );
  }

  //TODO it is not working needs to investigate
  socialLogout() {
    //window.allauth.facebook.logout()
    const headers = new HttpHeaders({ 'credentials': 'include' });
    let url = 'backend_api/accounts/logout/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.get(url)
    /*.pipe(
    tap(response => doSomething()),
    shareReplay(),
  );*/
  }

  refreshToken() {
    let url = 'backend_api/auth/token/refresh/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      url,
      { refresh: this.tokenRefresh }
    ).pipe(
      catchError(error => {
        //TODO deal with user end session better
        return throwError(error);

      }),
      tap(response => this.setSession(response)),
      shareReplay(),
    )
  }

  private getToken(email, password, user_id, username) {
    let user = {} as User
    user.id = user_id
    user.email = email
    user.username = username
    this.setUser(user)
    let url = 'backend_api/auth/token/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      url,
      { username: email, password: password }
    );
  }

  socialLogin(email, userChecks) {
    let url = 'backend_api/social_login/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      url,
      { email: email, userChecks: userChecks },
    ).pipe(
      tap((response: any) => {
        if (response.user) {
          let user = {} as User
          user.id = response.user.pk
          user.email = response.user.email
          user.username = response.user.username
          this.setUser(user)
          this.setSession(response)
        }
      }),
      shareReplay(),
    );;
  }

  sendResetPasswordEmail(email) {
    let url = 'backend_api/auth/password/reset/'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      url,
      { email }
    );
  }

  resetPasswordConfirm(uid, token, new_password1, new_password2) {
    let url = 'backend_api/custom/password/reset/confirm'
    if (this.isDevEnviroment) url = this.apiRoot.concat(url)
    return this.http.post(
      //this.apiRoot.concat('password/reset/confirm/'),
      url,
      { uid: uid, token: token, new_password1: new_password1, new_password2: new_password2 }
    );
  }

  resendConfirmationEmail(email) {
    let url = 'backend_api/resend_confirmation_email/'
    if (this.isDevEnviroment) url = this.apiRoot.concat('backend_api/resend_confirmation_email/')
    return this.http.post(
      //this.apiRoot.concat('password/reset/confirm/'),
      url,
      { email: email }
    );
  }

  isLogin() {
    const provider = localStorage.getItem('provider');
    return provider == null || provider == undefined || provider == "null" || provider == "undefined";
  }

  isSocialLogin() {
    const provider = localStorage.getItem('provider');
    //provider value must be equal to the one generated in the LoginSocialAdapter
    //to change its value is needed to modify the SocialLoginComponent
    return provider == 'login_facebook' || provider == 'login_google' || provider == 'login_instagram'
  }

  isLoggedIn() {
    return moment().isBefore(this.getRefreshExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getUserName() {
    const user = localStorage.getItem('currentUser');
    const userObj = JSON.parse(user);

    return userObj.username;
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access');
    if (token && token != "null" && token != "undefined") {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer '.concat(token))
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      //this.authService.refreshToken();

      return true;
    } else {
      /*this.authService.logout().toPromise().then(result => {
         doSomething()
      },
      error => {
          doSomething()
          throw new Error(error);
      });*/
      //TODO needs to logout user but the tokens are expired
      this.authService.clean()
      this.authService.logout().toPromise().then(result => {
        //doSomething()
      },
        error => {
          //doSomething()
          throw new Error(error);
        });
      this.router.navigate(['login']);

      return false;
    }
  }
}

interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
}
