import { Component, OnInit } from "@angular/core";
import {
  ExpertsDataInterface
} from "../interface";
import { ApiService } from "../api.service";

@Component({
  selector: "app-experts",
  templateUrl: "./experts_page.component.html",
  styleUrls: ["./experts_page.component.css"],
})
export class ExpertsPage implements OnInit {

  public experts: ExpertsDataInterface[];

  constructor(private apiService: ApiService,) { }

  async ngOnInit() {
    await this.getExpertsData();
  }

  async getExpertsData() {
    await this.apiService
      .get_experts_data()
      .toPromise()
      .then((result) => {
        this.experts = result;
      });
  }

}