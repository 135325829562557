<app-loader></app-loader>
<mat-toolbar ngClass.xl="main-body position-fixed" ngClass.lt-xl="main-body-sm position-fixed">

  <mat-toolbar-row>
    <a *ngIf="isStagingEnvoriment" class="is_staging_font">STAGING</a>
    <img (click)="goToHome()" ngClass="logo" ngClass.lt-sm="log-lt-sm"
      src="assets/fincatch/logo-colorido-cabecalho.svg" />


    <a class="category font-normal padding-for-button" ngClass.lt-sm="category-lt-sm" mat-button
      [matMenuTriggerFor]="menu">Categorias</a>
    <mat-menu #menu="matMenu">
      <div *ngFor="let c of categories">
        <a mat-menu-item (click)="goToCategory(c)">{{c.name}}</a>
      </div>
    </mat-menu>
    <a fxShow="true" fxShow.lt-lg="false" class="font-normal padding-for-button-fintech" ngClass.lt-sm="category-lt-sm"
      mat-button [matMenuTriggerFor]="menuFintech">Para sua fintech</a>
    <mat-menu #menuFintech="matMenu">
      <a mat-menu-item
        href="https://docs.google.com/forms/d/e/1FAIpQLSfiNC-bvcheS_1wVCUzGbadx1WNyRWGM-u790EKCvWpYoSTww/viewform"
        target="_blank">Cadastre a sua fintech</a>
      <a mat-menu-item href="https://forms.gle/FEXYT3aGGqsRmYbv7" target="_blank">Anuncie sua fintech</a>
    </mat-menu>

    <a fxShow="true" fxShow.lt-lg="false" class="font-normal padding-for-button"  mat-button
      href="https://www.blog.fincatch.com.br/" target="_blank">Blog</a>

    <a fxShow="true" fxShow.lt-lg="false" class="font-normal blog_margin "  ngClass.lt-sm="category-lt-sm"
      mat-button [matMenuTriggerFor]="menuBuscador">O que você precisa</a>
    <mat-menu #menuBuscador="matMenu">
      <a mat-menu-item
        href="https://forms.gle/9J78MvfACQwY9ZN47"
        target="_blank">Pessoa Física</a>
      <a mat-menu-item href="https://forms.gle/T82fDifM1WULV3Za7" target="_blank">Pessoa Jurídica</a>
    </mat-menu>

    
    <a fxShow="true" fxShow.lt-lg="false" class="font-normal" mat-button *ngIf="authService.isLoggedOut()"
      (click)="goToLogin()">Login</a><img fxShow="true" fxShow.lt-lg="false" *ngIf="authService.isLoggedOut()"
      class="login" src="assets/fincatch/usuario.svg" />
    <a style="color: white;" fxShow="true" fxShow.lt-lg="false" mat-button class="btn btn-signup" *ngIf="authService.isLoggedOut()"
      (click)="goToSignup()">Cadastrar</a>

    <a class="font-normal user-logged-padding user-logged-padding-xl" ngClass.lt-md="user-logged-padding-lt-md"
      mat-button *ngIf="authService.isLoggedIn() && authService.isLogin()" [matMenuTriggerFor]="menuLoggedUser">Olá,
      {{authService.getUserName()}}</a>
    <mat-menu #menuLoggedUser="matMenu">
      <div fxLayout="column" fxLayoutAlign="center start">
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button
          href="https://docs.google.com/forms/d/e/1FAIpQLSfiNC-bvcheS_1wVCUzGbadx1WNyRWGM-u790EKCvWpYoSTww/viewform"
          target="_blank">Cadastre a sua fintech</a>
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://forms.gle/FEXYT3aGGqsRmYbv7"
          target="_blank">Anuncie sua fintech</a>
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://www.blog.fincatch.com.br/"
          target="_blank">Blog</a>
        <!--<a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button (click)="goToFAQAndContact()">FAQ</a>-->
        <!--<a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button (click)="goToFAQAndContact()">Contato</a>-->
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://forms.gle/9J78MvfACQwY9ZN47"
          target="_blank">Pessoa Física</a>
          <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://forms.gle/T82fDifM1WULV3Za7"
          target="_blank">Pessoa Jurídica</a>
        <a class="font-normal" mat-menu-item (click)="profile()">Perfil</a>
        <a class="font-normal" mat-menu-item (click)="logout()">Sair</a>
      </div>
    </mat-menu><img fxShow="true" fxShow.lt-sm="false" *ngIf="authService.isLoggedIn() && authService.isLogin()"
      class="login" src="assets/fincatch/usuario.svg" />


    <a class="font-normal user-logged-padding user-logged-padding-xl" ngClass.lt-lg="user-logged-padding-lt-xl"
      ngClass.lt-md="user-logged-padding-lt-md" mat-button
      *ngIf="authService.isLoggedIn() && authService.isSocialLogin()" [matMenuTriggerFor]="menuLoggedSocialUser">Olá,
      {{authService.getUserName()}}</a>
    <mat-menu #menuLoggedSocialUser="matMenu">
      <div>
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button
          href="https://docs.google.com/forms/d/e/1FAIpQLSfiNC-bvcheS_1wVCUzGbadx1WNyRWGM-u790EKCvWpYoSTww/viewform"
          target="_blank">Cadastre a sua fintech</a>
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://forms.gle/FEXYT3aGGqsRmYbv7"
          target="_blank">Anuncie sua fintech</a>
        <a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button href="https://www.blog.fincatch.com.br/"
          target="_blank">Blog</a>
        <!--<a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button (click)="goToFAQAndContact()">FAQ</a>-->
        <!--<a fxShow="false" fxShow.lt-lg="true" class="font-normal" mat-button (click)="goToFAQAndContact()">Contato</a>-->
        <a class="font-normal" mat-menu-item (click)="profile()">Perfil</a>
        <a class="font-normal" mat-menu-item href="{{logoutLink}}">Sair</a>
      </div>
    </mat-menu><img *ngIf="authService.isLoggedIn() && authService.isSocialLogin()" class="login"
      src="assets/fincatch/usuario.svg" />

    <a fxShow="false" fxShow.lt-lg="true" mat-button [matMenuTriggerFor]="menuSmallScreen"
      *ngIf="!authService.isLoggedIn() && !authService.isSocialLogin()">
      <button mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
    </a>
    <mat-menu #menuSmallScreen="matMenu">
      <div fxLayout="column" fxLayoutAlign="center start">
        <a class="font-normal" mat-button
          href="https://docs.google.com/forms/d/e/1FAIpQLSfiNC-bvcheS_1wVCUzGbadx1WNyRWGM-u790EKCvWpYoSTww/viewform"
          target="_blank">Cadastre a sua fintech</a>
        <a class="font-normal" mat-button href="https://forms.gle/FEXYT3aGGqsRmYbv7" target="_blank">Anuncie sua
          fintech</a>
        <a class="font-normal" mat-button href="https://www.blog.fincatch.com.br/" target="_blank">Blog</a>
        <!--<a  class="font-normal"  mat-button (click)="goToFAQAndContact()">FAQ</a>-->
        <!-- <a  class="font-normal"  mat-button (click)="goToFAQAndContact()">Contato</a>-->
        <a class="font-normal" mat-button *ngIf="authService.isLoggedOut()" (click)="goToLogin()">Login</a>
        <a mat-button class="font-normal" *ngIf="authService.isLoggedOut()" (click)="goToSignup()">Cadastrar</a>
      </div>
    </mat-menu>

  </mat-toolbar-row>

  <mat-toolbar-row class="toolbar-row-divider">
    <hr class="menu-divider" />
  </mat-toolbar-row>

</mat-toolbar>
<div ngClass="main-body content-top" ngClass.lt-xl="main-body-sm content-top">
  <router-outlet></router-outlet>
  

  <div fxShow="true" fxShow.lt-lg="false" class='fixedButton1' (mouseover)="hoverForum=true" (mouseleave)="hoverForum=false">
    <a mat-button class='roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 30px" 
    *ngIf="hoverForum"
     (click)="openForum()" >Conheça nosso Fórum</a>
    <a *ngIf="!hoverForum" mat-button class='roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 25px"  >
      <i fxLayoutAlign="center center" class="fas fa-comments fa-stack-2x"></i></a>
  </div>

  <div fxShow="true" fxShow.lt-lg="false" class='fixedButton2' (mouseover)="hoverPodcast=true" (mouseleave)="hoverPodcast=false">
    <a mat-button class='roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 30px" 
    *ngIf="hoverPodcast"
     (click)="openPodcast()">Ouça nosso Podcast</a>
    <a *ngIf="!hoverPodcast" mat-button class='roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 25px"  >
      <i  fxLayoutAlign="center center" class="fas fa-podcast fa-stack-2x"></i></a>
  </div>

  <a (click)="openForum()" fxShow="false" fxShow.lt-lg="true" mat-button class='fixedButton1 roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 25px"  >
    <i fxLayoutAlign="center center" class="fas fa-comments fa-stack-2x"></i></a>

  <a (click)="openPodcast()" fxShow="false" fxShow.lt-lg="true" mat-button class='fixedButton2 roundedFixedBtn mat-elevation-z4' style="color: white;background-color: var(--primary-color);padding: 25px"  >
    <i  fxLayoutAlign="center center" class="fas fa-podcast fa-stack-2x"></i></a>

  <div class="cookies-container font-normal" *ngIf="!isCookieMessageViewed">
    <div class="cookies-content" fxLayout="row" fxLayoutAlign="space-between center">
      <p>A Fincatch usa alguns cookies para fornecer ao usuário uma melhor experiência. Saiba mais em nossa <a href="/tc_and_pp">Política de 
        Privacidade</a>.</p>
      <div>
      <button class="btn_cookie font-normal" (click)="setCookieMessageViewed()" mat-button>Entendido</button>    
    </div>
  </div>
</div>

<footer class="footer main-body" ngClass.lt-sm="footer main-body-sm">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center"
    class="footer-partners-padding-bottom">
    <div fxLayout="column" fxLayoutAlign="center start">
      <p class="footer-title">Parceiros</p>
      <div fxLayout="row" fxLayoutAlign="space-around center" class="footer-partners">
        <drag-scroll scrollbar-hidden="true" ngClass="drag-scroll" ngClass.lt-sm="drag-scroll-sm">
          <img class="footer-partners-padding" drag-scroll-item src="assets/fincatch/Abfintechs.jpg" />
          <img class="footer-partners-padding" drag-scroll-item src="assets/fincatch/Startup_Scanner.png" />
          <img class="footer-partners-padding" drag-scroll-item src="assets/fincatch/DNAUSP.jpg" />
          <img class="footer-partners-padding" drag-scroll-item src="assets/fincatch/Inova.jpg" />
          <img class="footer-partners-padding footer-padding-for-campinas-tech-brasil-logo" drag-scroll-item
            src="assets/fincatch/CampinasTech.jpg" />
          <img class="footer-partners-padding" drag-scroll-item src="assets/fincatch/start.jpg" />
        </drag-scroll>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <p class="footer-title">Acelarado por</p>
      <div fxLayout="row" fxLayoutAlign="space-around center" class="footer-partners">
        <drag-scroll scrollbar-hidden="true" ngClass="drag-scroll" ngClass.lt-sm="drag-scroll-sm">
          <img class="footer-partners-padding footer-padding-for-lift-logo" drag-scroll-item
            src="assets/fincatch/logo_lift.681af0db.jpg" />
          <img class="footer-partners-padding footer-padding-for-lift-logo" drag-scroll-item
            src="assets/fincatch/Baita.png" />
          <img class="footer-partners-padding footer-padding-for-lift-logo" drag-scroll-item
            src="assets/fincatch/Nexus.png" />
          <img class="footer-partners-padding footer-padding-for-lift-logo" drag-scroll-item
            src="assets/fincatch/Inovativa.jpg" />
          <img class="footer-partners-padding footer-padding-for-distrito-logo" drag-scroll-item
            src="assets/fincatch/Distrito.jpg" />
        </drag-scroll>
      </div>
    </div>
  </div>
  <hr />
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="space-between center" class="footer-font-info-padding">
    <div><img (click)="goToHome()" ngClass.gt-md="logo" src="assets/fincatch/logo-colorido-cabecalho.svg" /></div>
    <div ngClass.lt-md="div-contact-md"><span class="link_font">contato@fincatch.com.br</span></div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <div class="footer-font social-padding">Nossas redes sociais:</div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <a class="social-padding" href="https://www.instagram.com/fincatch/" target="_blank"><img
            src="assets/fincatch/instagram.svg" /></a>
        <a class="social-padding" href="https://twitter.com/FincatchOficial" target="_blank"><img
            src="assets/fincatch/twitter.svg" /></a>
        <a class="social-padding" href="https://www.youtube.com/channel/UCb4hI2yjItp7YVIOipDh6DQ?view_as=public"
          target="_blank"><img src="assets/fincatch/youtube.svg" /></a>
        <a class="social-padding" href="https://t.me/fincatch" target="_blank"><img
            src="assets/fincatch/telegram.svg" /></a>
        <a class="social-padding" href="https://www.linkedin.com/company/65865949" target="_blank"><img
            src="assets/fincatch/linkedin.svg" /></a>
        <a class="social-padding" href="https://medium.com/fincatch" target="_blank"><img
            src="assets/fincatch/medium.svg" /></a>
      </div>
    </div>
  </div>
  <hr />
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column" class="footer-font-info-padding">
    <div class="copyright_font">© 2020 Copyright:
      <a class="link_font" href="#!"> fincatch.com.br</a>
    </div>
    <a ngClass.lt-md="tc-and-pp-padding-md" class="link_font" href="javascript:void(0);"
      (click)="tcAndPPDialogSimpleDialog()">Termos de uso e Política de Privacidade</a>
  </div>


</footer>