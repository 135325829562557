import { Component, OnInit } from "@angular/core";
import {
    UsersRankingInterface
} from "../interface";
import { ApiService } from "../api.service";
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import Utils from "../utils";
import { Meta, Title } from "@angular/platform-browser";
import { GoogleAnalyticsService } from "../google-analytics-service";
import { runInThisContext } from "vm";

@Component({
    selector: "app-users-ranking",
    templateUrl: "./users_ranking.html",
    styleUrls: ["./users_ranking.css"],
})
export class UsersRankingPage implements OnInit {

    public usersRanking: any;

    public hasMoreThanFourUsers: boolean

    public displayedColumns: string[]

    constructor(private apiService: ApiService,
        private router: Router,
        public authService: AuthService,
        private titleService: Title,
        private metaTagService: Meta,
        private googleAnalyticsService: GoogleAnalyticsService,) { }

    async ngOnInit() {
        this.displayedColumns = ['position', 'name', 'total'];
        Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Ranking de usuários - Fincatch');
        this.metaTagService.updateTag(
            { name: 'description', content: 'Ranking dos usuários que realizaram ações na plataforma Fincatch' }
        );
        this.googleAnalyticsService.openUsersRankingPage()
        await this.getUsersRankingData();
    }

    private getInitials(element) {
        //TODO save in DB when user signup
        let upperCaseWords = element.name.match(/[A-Z]/g);
        if (upperCaseWords && upperCaseWords.length > 1)
            element.initials = upperCaseWords[0] + upperCaseWords[1]
        else
            element.initials = element.name.slice(0, 2).toUpperCase()
    }

    async getUsersRankingData() {
        await this.apiService
            .get_users_ranking_data()
            .toPromise()
            .then((result) => {
                this.usersRanking = {
                    'first_three_users': result.slice(0, 3),
                    'forth_up_users': result.slice(3),
                }
                this.hasMoreThanFourUsers = this.usersRanking.forth_up_users.length > 0
                if (this.usersRanking.forth_up_users.length < 7) {
                    let size = this.usersRanking.forth_up_users.length
                    let count = (7 - size) + 1
                    for (let index = size; index < 7; index++) {
                        this.usersRanking.forth_up_users[index] = {
                            name: "............",
                            total: "?"
                        }
                    }
                }
            });
    }

    goToSignup() {
        this.router.navigate(['signup'])
    }

}