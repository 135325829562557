<div class="main-div">

  <div  fxLayout="column" fxLayoutAlign="center center" >
    <p class="title">FAQ</p>
  </div>



  <div  fxLayout="column" fxLayoutAlign="center stretch">

        <div *ngFor="let f of faqs">
          <div fxLayout="column" fxLayoutAlign=" start"  >
          <p class="question">{{f.question}}</p>
          <p class="answer" [innerHtml]="f.answer"></p>

          </div>
          <div class="hr-padding">
            <hr  />
          </div>
        </div>
  </div>
</div>
