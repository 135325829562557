import { environment } from 'src/environments/environment';
import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {

    private googleAnalyticsId: string;
    private renderer2: Renderer2;
    private scriptsLoaded: boolean = false;

    constructor(
        private rendererFactory2: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.googleAnalyticsId = environment.tracking_id;
    }

    init() {
        if (!this.scriptsLoaded) {
            //this.insertMainScript();
            this.injectScripts()
        }
    }

    private injectScripts() {
        const gtmScriptTag = this.renderer2.createElement('script');
        gtmScriptTag.type = 'text/javascript';
        gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleAnalyticsId;
        this.renderer2.appendChild(this._document.body, gtmScriptTag);

        const gtagInitScript = this.renderer2.createElement('script');
        gtagInitScript.type = 'text/javascript';
        const text = 'window.dataLayer = window.dataLayer || [];\n' +
            'function gtag() {\n' +
            'dataLayer.push(arguments);\n' +
            '}\n' +
            'gtag(\'js\', new Date());\n' +

            'gtag(\'config\',\'' + this.googleAnalyticsId + '\');'
        gtagInitScript.text = text;
        this.renderer2.appendChild(this._document.body, gtagInitScript);
    }

    private insertMainScript() {
        if (this.googleAnalyticsId) {
            const script: HTMLScriptElement = this.renderer2.createElement('script');
            script.type = 'text/javascript';
            script.onload = this.insertSecondHalfOfScript.bind(this);
            script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
            script.text = '';
            this.renderer2.appendChild(this._document.body, script);
        }
    }

    private insertSecondHalfOfScript() {
        const script: HTMLScriptElement = this.renderer2.createElement('script');
        script.type = 'text/javascript';
        script.src = '/assets/scripts/analytics/analytics-starting-script.js';
        script.text = '';
        this.renderer2.appendChild(this._document.body, script);
        script.onload = () => {
            this.scriptsLoaded = true;
        };
    }

    private trackSinglePageView(event: NavigationEnd) {
        if (this.googleAnalyticsId && this.scriptsLoaded) {
            gtag('config', this.googleAnalyticsId, { page_path: event.urlAfterRedirects });
        }
    }

    trackPageViews() {
        return this._router.events.pipe(
            filter(() => this.scriptsLoaded === true),
            filter((evt: RouterEvent) => evt instanceof NavigationEnd),
            tap((event: NavigationEnd) => {
                this.trackSinglePageView(event);
            }),
        );
    }

    private eventEmitter(eventName: string, eventCategory: string, eventLabel: string = null, eventAction: string = null) {
        gtag('event', eventName, {
            //group of similar events, ex: video
            eventCategory: eventCategory,
            //name of the user interactions, ex: the name of a video user watched
            eventLabel: eventLabel,
            //action performed by user, ex: video play
            eventAction: eventAction,
            //numerical value associated with the event, ex: length of the video
            //eventValue: eventValue
        })
    }

    //Google analytics 4
    //1: gtag('event', <event_name>, {
    //
    //    2: <parameter_1>: <parameter_1_value>,
    //    
    //    3: <parameter_2>: <parameter_2_value>,
    //    
    //    4: <parameter_3>: <parameter_3_value>,
    //    
    //    5: ...
    //    
    //    6: });
    private eventEmitterOpenCategoryGA4(categoryName: string) {
        gtag('event', 'category_open', {
            'category_name': categoryName,
        })
    }

    private eventEmitterChooseSubCategoryGA4(subCategoryName: string) {
        gtag('event', 'subcategory_choose', {
            'subCategory_name': subCategoryName,
        })
    }

    private eventCreateEvaluationWithCommentEventGA4(fintechName: string) {
        gtag('event', 'evaluation_creation', {
            'created_evaluation_comment_fintech_name': fintechName,
        })
    }

    private eventCreateCommonEvaluationEventGA4(fintechName: string) {
        gtag('event', 'evaluation_creation', {
            'created_common_evaluation_fintech_name': fintechName,
        })
    }

    private eventUpdateEvaluationWithCommentEventGA4(fintechName: string) {
        gtag('event', 'evaluation_update', {
            'updated_evaluation_comment_fintech_name': fintechName,
        })
    }

    private eventUpdateCommonEvaluationEventGA4(fintechName: string) {
        gtag('event', 'evaluation_update', {
            'updated_common_evaluation_fintech_name': fintechName,
        })
    }

    private eventUserClickedFintechSocialURLEventGA4(fintechName: string, socialNetwork: string) {
        gtag('event', 'click_fintech_social_network_from_its_page', {
            'fintech_name': fintechName,
            'social_network': socialNetwork
        })
    }

    private eventUserClickedOnWhatOffersLinkFromFintechURLEventGA4(fintechName: string) {
        gtag('event', 'click_fintech_what_offers_from_its_page', {
            'fintech_name': fintechName,
        })
    }

    private eventOpenUsersRankingPageEventGA4() {
        gtag('event', 'open_users_ranking_page')
    }

    private eventSignUpEventEventGA4(provider: string) {
        gtag('event', 'signup_event', {
            'provider': provider,
        })
    }

    private eventLoginEventGA4(provider: string) {
        gtag('event', 'login_event', {
            'provider': provider,
        })
    }

    private eventSearchByTagEventGA4(tagName: string, fromPage: string) {
        gtag('event', 'search_by_tag', {
            'tag_name': tagName,
            'fromPage': fromPage,
        })
    }

    private eventSearchByFintechEventGA4(fintechName: string) {
        gtag('event', 'search_by_fintech', {
            'search_fintech_name': fintechName,
        })
    }


    private eventOpenFintechEvaluationEventGA4(fintechName: string) {
        gtag('event', 'evaluation_page_open', {
            'open_evaluation_fintech_name': fintechName,
        })
    }

    private eventOpenFintechPageResultsEventGA4(fintechName: string) {
        gtag('event', 'fintech_page_results_open', {
            'open_results_fintech_name': fintechName,
        })
    }



    public signUpEvent(provider: string) {
        let eventAction = 'signup_' + provider
        this.eventEmitter("signup_event", 'auth', eventAction, 'click');
        this.eventSignUpEventEventGA4(provider)
    }

    public loginEvent(provider: string) {
        let eventAction = 'login_' + provider
        this.eventEmitter("login_event", 'auth', eventAction, 'click');
        this.eventLoginEventGA4(provider)
    }

    public searchByTagEvent(tagName: string) {
        this.eventEmitter("search_by_tag", 'search', tagName, 'click');
        this.eventSearchByTagEventGA4(tagName, 'search_field')
    }

    public searchByTagEventFromFintechPage(tagName: string, fintechName: string) {
        let eventAction = 'to_tagName: ' + tagName + '; from_fintechName: ' + fintechName;
        this.eventEmitter("search_by_tag", 'search', eventAction, 'click');
        this.eventSearchByTagEventGA4(tagName, fintechName + '_page')
    }

    public searchByFintechEvent(fintechName: string) {
        this.eventEmitter("search_by_fintech", 'search', fintechName, 'click');
        this.eventSearchByFintechEventGA4(fintechName)
    }

    public openFintechEvaluationEvent(fintechName: string) {
        this.eventEmitter("evaluation_open", 'open', fintechName, 'click');
        this.eventOpenFintechEvaluationEventGA4(fintechName)
    }

    public openFintechPageResultsEvent(fintechName: string) {
        this.eventEmitter("fintech_page_results_open", 'open', fintechName, 'click');
        this.eventOpenFintechPageResultsEventGA4(fintechName)
    }

    public openCategoryEvent(categoryName: string) {
        this.eventEmitter("category", 'open', categoryName, 'click');
        this.eventEmitterOpenCategoryGA4(categoryName)
    }

    public chooseSubCategoryEvent(subCategoryName: string) {
        this.eventEmitter("category", 'choose_subcategory', subCategoryName, 'click');
        this.eventEmitterChooseSubCategoryGA4(subCategoryName)
    }

    public createEvaluationWithCommentEvent(fintechName: string) {
        this.eventEmitter("evalutation_create", 'create_with_comment', fintechName, 'click');
        this.eventCreateEvaluationWithCommentEventGA4(fintechName)
    }

    public createEvaluationWithoutCommentEvent(fintechName: string) {
        this.eventEmitter("evalutation_create", 'create_without_comment', fintechName, 'click');
        this.eventCreateCommonEvaluationEventGA4(fintechName)
    }

    public updateEvaluationWithCommentEvent(fintechName: string) {
        this.eventEmitter("evalutation_update", 'update_with_comment', fintechName, 'click');
        this.eventUpdateEvaluationWithCommentEventGA4(fintechName)
    }

    public updateEvaluationWithoutCommentEvent(fintechName: string) {
        this.eventEmitter("evalutation_update", 'update_without_comment', fintechName, 'click');
        this.eventUpdateCommonEvaluationEventGA4(fintechName)
    }

    public userClickedFintechSocialURL(socialName: string, fintechName: string) {
        let eventAction = 'social: ' + socialName + '; from_fintechName: ' + fintechName;
        this.eventEmitter("open_social_network_from_fintech_page", 'click', eventAction, 'click');
        this.eventUserClickedFintechSocialURLEventGA4(fintechName, socialName)
    }

    public userClickedOnWhatOffersLinkFromFintechURL(fintechName: string) {
        let eventAction = 'user clicked what offers link from_fintechName: ' + fintechName;
        this.eventEmitter("open_what_offers_link_from_fintech_page", 'click', eventAction, 'click');
        this.eventUserClickedOnWhatOffersLinkFromFintechURLEventGA4(fintechName)
    }

    public openUsersRankingPage() {
        this.eventEmitter("open_users_ranking_page", 'open');
        this.eventOpenUsersRankingPageEventGA4()
    }


}
