import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, of, throwError, Subject } from 'rxjs';
import { filter, startWith, map, switchMap, catchError, takeUntil } from 'rxjs/operators';
import { Category, SearchType } from '../interface'
import { RouteStateService } from '../route.state.service'


@Component({
  selector: 'app-root',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  title = 'frontend';

  private error: any;

  private $allCategorys: Category[];
  public $filteredCategorys: Observable<Category[]>;

  private destroy = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private routeStateService: RouteStateService) {
  }

  async ngOnInit() {
    //console.log("****** SearchComponent INIT *********")
    await this.getCategorys()

    this.$filteredCategorys = this.search.valueChanges
     .pipe(
       startWith(''),
       map(value => this.filterCategorys(value)),
       catchError(error => {
        return throwError(error.statusText);
      })
     );

  }

  ngOnDestroy(){
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  async getCategorys() {

        await this.apiService.list_categorys().toPromise().then(result => {
          this.$allCategorys = result
          for (var category of this.$allCategorys) {
            category.searchType = SearchType.Category;
          }
        });
}


  private filterCategorys(value: string | Category): Category[] {
   let filterValue = '';
   if (value) {
     filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
     return this.$allCategorys.filter(category => {
       return category.name.toLowerCase().includes(filterValue)
       /*let isFiltered = category.name.toLowerCase().includes(filterValue)
       let hasSubCategory = false;
       if (!isFiltered) {
         hasSubCategory = category.subCategory.some(subCategory => {
            return subCategory.name.toLowerCase().includes(filterValue)
          })
       }
       return isFiltered || hasSubCategory*/
     })
       //map(category => category.filter(category => category.name.toLowerCase().includes(filterValue))
     //);
   } else {
     return this.$allCategorys;
   }
 }

 public displayFn(category?: Category): string | undefined {
   return category ? category.name : undefined;
 }

 searchForm = this.formBuilder.group({
   search: [null, null]
 });

 get search() {
    return this.searchForm.get('search');
  }

 goToPage(obj) {
   //add params so it can be read in outside component
   this.activatedRoute.paramMap.pipe(
     map(paramMap => ({  category_id: obj.option.value.id, searchType: obj.option.value.searchType, searchName: obj.option.value.name})),
     takeUntil(this.destroy)
   ).subscribe(routePathParam => {
     this.routeStateService.updatePathParamState(routePathParam)
   })
   //console.log("obj.option.value.searchType ", obj.option.value.searchType)
   this.router.navigate(['business_list', obj.option.value.searchType, obj.option.value.name,obj.option.value.id])
 }

}
