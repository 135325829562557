
<banner *ngIf="isTagSearch" isHomePage="true"></banner>

<div *ngIf="isTagSearch" fxLayout="column" fxLayoutAlign="center center">
  <div class="selected-tag-div">
    <span class="selected-tag-label">Tag selecionada:&nbsp;</span>
    <h1 class="selected-tag" ngClass.lt-sm="selected-tag-lt-sm" *ngIf="this.info">{{this.info.name}}</h1>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="24px grid" fxLayoutGap.lt-md="16px grid" fxLayoutAlign="center none">
    <div
      [ngClass]="{'fxFlex-three-itens-or-less': fintechsList.length < 4 && fintechsList.length > 1, 'fxFlex-one-item': fintechsList.length == 1, 'fxFlex-lots-of-itens': fintechsList.length >= 4}"
      fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let b of fintechsList; let i = index">
      <mat-card appearance="outlined" class="card mat-elevation-z4">
        <mat-card-header class="header-image-padding">
          <div mat-card-avatar class="header-image" [style.background-image]="'url(' + b.business.logo_small + ')'">
          </div>
          <mat-card-title class="title" (click)="evaluationResult(b.business)">{{b.business.name}}</mat-card-title>
          <span class="fa-stack fa-lg position" *ngIf="b.businessRate > 0">
            <i *ngIf="b.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
            <i *ngIf="b.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
            <i *ngIf="b.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
            <i *ngIf="b.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
            <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{b.position}}</i>
          </span>
        </mat-card-header>

        <mat-card-content class="card_content">
          <div class="rating" fxLayout="row" fxLayoutAlign="center center">
            <ng-template #t let-fill="fill">
              <span class="fas star material-icons" [class.full]="fill === 100">
                <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>

            <ngb-rating [(rate)]="b.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

            <span class="rate">{{b.businessRate}}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center">
            <a mat-button class="btn-evaluation" [ngClass]="decideBtnColor(i + 1)"
              (click)="evaluation(b.business)">Avaliar esta empresa!</a>
          </div>
          <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
            <a class="visualize" (click)="evaluationResult(b.business)"
              [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
          </div>
          <div *ngIf="!b.isRankable && b.businessRate > 0"><span class="isRankable_msg">Esta fintech ainda não
              possui o
              mínimo de avaliações
              para entrar no ranking.</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="!isTagSearch && selectedFintech" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="selected-tag-div">
      <span class="selected-tag-label">Empresa Selecionada:&nbsp;</span>
      <h1 class="selected-tag" ngClass.lt-sm="selected-tag-lt-sm">{{selectedFintech.business.name}}</h1>
    </div>
    <div>
      <mat-card appearance="outlined" class="card-related_fintechs mat-elevation-z4">
        <mat-card-header class="header-image-padding">
          <div mat-card-avatar class="header-image"
            [style.background-image]="'url(' + selectedFintech.business.logo_small + ')'"></div>
          <mat-card-title class="title" (click)="evaluationResult(selectedFintech.business)">
            {{selectedFintech.business.name}}
          </mat-card-title>
          <span class="fa-stack fa-lg position" *ngIf="selectedFintech.business.businessRate > 0">
            <i *ngIf="selectedFintech.business.position == 1" class="fa fa-award fa-stack-2x"
              style="color: #FFD700"></i>
            <i *ngIf="selectedFintech.business.position == 2" class="fa fa-award fa-stack-2x"
              style="color: #C0C0C0"></i>
            <i *ngIf="selectedFintech.business.position == 3" class="fa fa-award fa-stack-2x"
              style="color: #E57B19"></i>
            <i *ngIf="selectedFintech.business.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
            <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{selectedFintech.business.position}}</i>
          </span>
        </mat-card-header>

        <mat-card-content class="card_content">
          <div class="rating" fxLayout="row" fxLayoutAlign="center center">
            <ng-template #t let-fill="fill">
              <span class="fas star material-icons " [class.full]="fill === 100">
                <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>

            <ngb-rating [(rate)]="selectedFintech.businessRate" [starTemplate]="t" [readonly]="true" max="5">
            </ngb-rating>

            <span class="rate">{{selectedFintech.businessRate}}</span>
          </div>


          <div fxLayout="row" fxLayoutAlign="center center">
            <a mat-button class="btn-evaluation btn-selected-fintech-color"
              (click)="evaluation(selectedFintech.business)">Avaliar esta empresa!</a>
          </div>
          <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
            <a class="visualize" (click)="evaluationResult(selectedFintech.business)"
              [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <hr />
  <h2 class="related-fintech-label">Empresas Relacionadas:&nbsp;</h2>
  <div *ngIf="fintechsList && fintechsList.length > 0" class="div-related_fintechs">
    <div fxLayout="row wrap" fxLayoutGap="24px grid" fxLayoutGap.lt-md="16px grid" fxLayoutAlign="center none">
      <div
        [ngClass]="{'fxFlex-three-itens-or-less': fintechsList.length < 4 && fintechsList.length > 1, 'fxFlex-one-item': fintechsList.length == 1, 'fxFlex-lots-of-itens': fintechsList.length >= 4}"
        fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let b of fintechsList; let i = index">
        <mat-card appearance="outlined" class="card mat-elevation-z4">
          <mat-card-header class="header-image-padding">
            <div mat-card-avatar class="header-image" [style.background-image]="'url(' + b.business.logo_small + ')'">
            </div>
            <mat-card-title class="title" (click)="evaluationResult(b.business)">{{b.business.name}}</mat-card-title>
            <span class="fa-stack fa-lg position" *ngIf="b.businessRate > 0">
              <i *ngIf="b.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
              <i *ngIf="b.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
              <i *ngIf="b.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
              <i *ngIf="b.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
              <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{b.position}}</i>
            </span>
          </mat-card-header>

          <mat-card-content class="card_content">
            <div class="rating" fxLayout="row" fxLayoutAlign="center center">
              <ng-template #t let-fill="fill">
                <span class="fas star material-icons " [class.full]="fill === 100">
                  <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>

              <ngb-rating [(rate)]="b.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

              <span class="rate">{{b.businessRate}}</span>
            </div>


            <div fxLayout="row" fxLayoutAlign="center center">
              <a mat-button class="btn-evaluation" [ngClass]="decideBtnColor(i + 1)"
                (click)="evaluation(b.business)">Avaliar esta empresa!</a>
            </div>
            <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
              <a class="visualize" (click)="evaluationResult(b.business)"
                [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </div>

</div>