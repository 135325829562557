import {Component} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../input-error-handlers.component';
import { ApiService } from '../api.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-request-budget-popup',
  templateUrl: './request_budget_popup.html',
  styleUrls: ['./request_budget_popup.css']
})
export class RequestBudgetPopup {

  public contactFormControl : UntypedFormControl;
  public usernameFormControl: UntypedFormControl;
  public messageFormControl: UntypedFormControl;

  public matcher: MyErrorStateMatcher;

  public isToShowRequestBudgetSucess = false;


  constructor(
      private apiService: ApiService,
      public dialogRef: MatDialogRef<RequestBudgetPopup>,) {

    this.contactFormControl= new UntypedFormControl('', [
        Validators.email, Validators.required
      ]);
      this.usernameFormControl = new UntypedFormControl('', [
        Validators.required,
      ]);
      this.messageFormControl = new UntypedFormControl('', [
        Validators.required,
      ]);
      this.matcher = new MyErrorStateMatcher();
  }

  async sendRequestBudgetEmail() {
    if (this.usernameFormControl.value && this.contactFormControl.value && this.messageFormControl.value) {
      await this.apiService.send_request_budget_email(this.usernameFormControl.value, this.contactFormControl.value, this.messageFormControl.value).toPromise().then(result => {
        this.usernameFormControl.reset()
        this.contactFormControl.reset()
        this.messageFormControl.reset()
        this.isToShowRequestBudgetSucess = true
        setTimeout(() => {
          this.isToShowRequestBudgetSucess = false
        }, 3000)
      }
    )
    }
  }

  onCancelClick(): void {
    this.dialogRef.close('cancel');
  }

}