import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from './_alert';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';

import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table'


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



import { EvaluationComponent } from './evaluation/evaluation.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { BusinessListComponent } from './business_list/business_list.component';
import { BusinessEvaluationResultComponent } from './business_evaluation_result/business_evaluation_result.component';
import { SearchComponent } from './search/search.component';
import { MyErrorStateMatcher } from './input-error-handlers.component';
import { AuthService, AuthInterceptor, AuthGuard } from './auth.service';
import { RouteStateService } from './route.state.service'
import { ApiService } from './api.service';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader-interceptor.service';
import { LoaderComponent } from './loader/loader.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { GlobalErrorHandler } from './global-error-handler';
import { SocialLoginComponent } from './login/social_login.component';
import { SearchDialog } from './search_dialog/search_dialog.component'
import { SignupEmailConfirmationComponent } from './signup/signup_email_confirmation.component'
import { LoginErroDialog } from './login/login_error_dialog'
import { LoginPasswordErroDialog } from './login/login_password_error_dialog'
import { LoginResetPasswordDialog } from './login/login_reset_password_dialog'
import { ResetPasswordConfirm } from './login/reset_password_confirm';
import { HomeTags } from './home_tags/home_tags'
import { Home } from './home/home'
import { TCAndPPDialogForUserAccepetance } from './tc_and_pp_dialog_for_user_acceptance/tc_and_pp_dialog_for_user_acceptance'
import { TCAndPPDialogSimpleDialog } from './tc_and_pp_simple_dialog/tc_and_pp_simple_dialog'
import { TCAndPPDialogSimpleScreen } from './tc_and_pp_simple_screen/tc_and_pp_simple_screen'
import { FormsPopUp } from './forms_pop_up/forms_pop_up'
import { RequestBudgetPopup } from './request_budget_popup/request_budget_popup'
import { UserProfile } from './user_profile/user_profile'
import { GoogleAnalyticsService } from './google-analytics-service'
import { FAQ } from './faq/faq'
import { ExpertsPage } from './experts_page/experts_page.component'
import { UsersRankingPage } from './users_ranking/users_ranking'
import { SearchPage } from './search_page/search_page'
import { SEOService } from './seo.service';
import { AppBannerComponent } from './google_ad_banner_horizontal/banner.component'
import { BannerComponent } from './banner/banner.component';

import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DragScrollModule } from 'ngx-drag-scroll';


export class ngMaterialModule { }

@NgModule({
  declarations: [
    AppComponent,
    EvaluationComponent,
    LoginComponent,
    SignupComponent,
    BusinessListComponent,
    SearchComponent,
    BusinessEvaluationResultComponent,
    LoaderComponent,
    SocialLoginComponent,
    SearchDialog,
    SignupEmailConfirmationComponent,
    LoginErroDialog,
    LoginPasswordErroDialog,
    LoginResetPasswordDialog,
    ResetPasswordConfirm,
    HomeTags,
    Home,
    TCAndPPDialogForUserAccepetance,
    UserProfile,
    TCAndPPDialogSimpleDialog,
    TCAndPPDialogSimpleScreen,
    FormsPopUp,
    FAQ,
    SearchPage,
    RequestBudgetPopup,
    ExpertsPage,
    UsersRankingPage,
    AppBannerComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatGridListModule,
    MatDividerModule,
    MatListModule,
    AlertModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    //MomentDateModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgbModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    HttpClientXsrfModule.withOptions({ cookieName: 'csrftoken', headerName: 'X-CSRFToken' }),
    MatSidenavModule,
    MatBottomSheetModule,
    DragScrollModule,
    MatRadioModule,
    MatTableModule,
  ],
  exports: [
    MatCardModule,
    NgbModule,
    MatCheckboxModule
  ],
  providers: [
    RouteStateService,
    ApiService,
    SEOService,
    GoogleAnalyticsService,
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MyErrorStateMatcher,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [Injector]
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      deps: [LoaderService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
