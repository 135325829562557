import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  //TODO improve this, maybe a Interceptor before the loader interceptor....
  //tried to use a header to regulate this but then I would have to change the backend to accept the header, not worth at the time
  public not_loading_tag = '/tag'
  public not_loading_get_faq = '/get_faq'
  public not_loading_get_wix_posts = '/get_wix_posts'
  public not_loading_get_fincatch_data = '/get_fincatch_data'
  public not_loading_categorys = '/categorys'


  public isLoading = new BehaviorSubject(false);
  constructor() { }
}
