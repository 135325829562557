<h1 mat-dialog-title>Troca de senha</h1>
<div mat-dialog-content>
  <p>Esqueceu sua senha? Preencha seu e-mail para receber instruções para trocá-la.</p>
  <mat-form-field class="example-full-width" appearance="outline">
      <input matInput placeholder="E-mail" [formControl]="emailFormControl" >
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          E-mail inválido
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
          E-mail é <strong>obrigatório</strong>
      </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Não Obrigado</button>
  <button mat-button [mat-dialog-close]="emailFormControl.value" cdkFocusInitial>Ok</button>
</div>
