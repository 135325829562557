
import { environment } from '../environments/environment';
export default class Utils {
  //TODO improve this from DB
  static computeRating(questionRatings: any[]) {
    let sumOfRatings = 0
    questionRatings.forEach(rating => {
      sumOfRatings += +rating.questionRatingAvg
    })
    let ratingMedium = 0
    sumOfRatings > 0 ? ratingMedium = sumOfRatings / questionRatings.length : ratingMedium = 0
    return Number.isInteger(ratingMedium) ? ratingMedium : ratingMedium.toFixed(2)
  }

  //TODO improve this from DB
  static rearrangeQuestionRatingsAttrNames(questionRatings: any[]) {
    questionRatings = questionRatings.map(item => {
      return {
        questionRatingAvg: item.ratingAvarage,
        questionId: item.question__id
      };
    })
    return questionRatings
  }

  static isNotNullOrEmptyOrUndefined(value) {
    return !value;
  }

  static isStagingEnviroment() {
    //I dont know I made this verification like this
    return (!environment.production && !environment.test && environment.staging)
  }

  static isDevEnviroment() {
    //I dont know I made this verification like this
    return (!environment.production && !environment.test && !environment.staging)
  }

  static removeSpecialCaractheresAndSpaceFromURLPart(urlPart) {
    let newUrl = urlPart.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return newUrl.replaceAll(" ", "-")
  }

}
