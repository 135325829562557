import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Params } from './interface'

@Injectable({ providedIn: 'root'})
export class RouteStateService {

  private pathParamState =  new BehaviorSubject<Params>({} as Params);

  pathParam: Observable<Params>;


  constructor() {
    this.pathParam = this.pathParamState.asObservable();
  }

  updatePathParamState(newPathParam: Params) {
    this.pathParamState.next(newPathParam);
  }


}
