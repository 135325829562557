
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title class="login-title">Login</mat-card-title>
    </mat-card-header>

    <form class="example-form">
      <mat-card-content>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="E-mail" [formControl]="emailFormControl">
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                E-mail inválido
            </mat-error>
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('required')">
                E-mail é <strong>obrigatório</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Senha" [formControl]="passwordFormControl"
               [errorStateMatcher]="matcher"  type='password'>
            <mat-error class="font-family" *ngIf="passwordFormControl.hasError('required')">
                Senha é <strong>obrigatória</strong>
            </mat-error>
        </mat-form-field>
        <div fxLayoutAlign="center center" class="font-family font-size-create-account">
          Não possui cadastro?&nbsp;
         <a  class="font-family font-size-create-account" (click)="goToSignup()" href="javascript:void(0);"> Crie sua conta</a>
         </div>
        </mat-card-content>
        <button class="btn-block" mat-raised-button color="primary" (click)="login()">Login</button>

        <a  mat-raised-button href="{{facebookLoginLink}}"  class="btn btn-block btn-social btn-facebook" style="background-color:#3b5998;text-align:center">
          <img src="https://img.icons8.com/fluent/24/000000/facebook-new.png"/>&nbsp;Login Facebook
        </a>

        <a   mat-raised-button href="{{googleLoginLink}}" class="btn btn-block btn-social btn-google"
         style="background-color:#FFFFFF;text-align:center">
           <img src="https://img.icons8.com/cute-clipart/24/000000/google-logo.png"/>&nbsp;Login Google
        </a>

        <div class="forgot_pass" fxLayoutAlign="start start">
         <a class="font-family"  (click)="openResetPasswordDialog()" href="javascript:void(0);">Esqueci minha senha</a>
         </div>

        <!--><p>{{ error?.message }}</p>
        <p *ngIf="error">{{ error?.error | json }}</p><!-->

    </form>
  </mat-card>

</div>
