<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title class="profile-title">Perfil</mat-card-title>
    </mat-card-header>

    <form class="example-form" >
      <mat-card-content>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Usuário" [formControl]="usernameFormControl"
               [errorStateMatcher]="matcher"  maxlength="8">
            <mat-error class="font-family" *ngIf="usernameFormControl.hasError('required')">
                Usuário é <strong>obrigatório</strong>
            </mat-error>
            <mat-error class="font-family" *ngIf="usernameFormControl.hasError('maxlength')">
                Tamanho máximo é de 8 caracteres
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="E-mail" [formControl]="emailFormControl"
               [errorStateMatcher]="matcher" >
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                E-mail inválido
            </mat-error>
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('required')">
                E-mail é <strong>obrigatório</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label >Data de Nascimento</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="birthdayFormControl" [max]="today" autocomplete="off" >
            <mat-datepicker-toggle class="font-family" matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker class="font-family" #dp
                  startView="multi-year"
                  panelClass="example-month-picker">
            </mat-datepicker>
            <mat-error class="font-family" *ngIf="birthdayFormControl.hasError('required')">
                Data de Nascimento é <strong>obrigatória</strong>
            </mat-error>
            <mat-error class="font-family" *ngIf="birthdayFormControl.hasError('underage')">
                É preciso ser maior de 18 anos para utilizar a Fincatch!
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width top_margin" appearance="outline" >
          <mat-label style="padding-left: 20px">Gênero</mat-label>
          <mat-select  class="select" (selectionChange)='onOptionsSelected($event)' [formControl]="sexFormControl">
          <mat-option class="font-family" *ngFor="let sex of enumKeys" [value]="sex">
            {{sexChoices[sex]}}
           </mat-option>
          </mat-select>
          <mat-error class="font-family" *ngIf="sexFormControl.hasError('required')">
            Gênero é <strong>obrigatório</strong>
          </mat-error>

        </mat-form-field>
        <div class="forgot_pass" fxLayoutAlign="start start">
         <a  class="font-family" (click)="changePasswordDialog()" href="javascript:void(0);">Trocar minha senha</a>
         </div>
        </mat-card-content>
        <button class="btn-block btn-margin-bottom" mat-raised-button color="primary" (click)="editProfile()">Editar Perfil</button>
        <button class="btn-block" mat-raised-button color="primary" (click)="downloadInfo()">Download dos meus dados</button>
        <div class="deactivate_user" fxLayout="column" fxLayoutAlign="end center" >
         <a  class="font-family" (click)="openConfirmationDialog()" href="javascript:void(0);">Desativar minha conta</a>
         </div>

    </form>
  </mat-card>

<a href="{{logoutLink}}" id="logout"></a>
</div>
