export interface Category {
    id: number;
    name: string;
    searchType: SearchType;
}

//TODO change it!!
export enum SearchType {
    Category = "categoria",
    Tag = "tag",
    Fintech = "fintech"
}

export interface Params {
    category_id: string;
    searchType: string;
    searchName: string;
    business_id?: number;
}

export interface QuestionRating {
    rating: number;
    business: number;
    question: number
}

export interface User {
    id: number;
    email: string;
    username: string;
    sex: SexChoices;
    birthday: Date
}

export interface Business {
    id: number;
    name: string;
    logo: string;
    description: string;
    instagram: string;
    twitter: string;
    facebook: string;
    youtube: string;
    linkedin: string;
    employees_range: string;
    city: string;
    state: string;
    foundation: string;
    acceleration: string;
    what_offers: string;
    what_offers_link: string;
    isActive: boolean;
}

export interface Question {
    id: number;
    question: string;
}

export interface JWTPair {
    provider: string;
    code: string;
}

export enum CommentStatus {
    Approve = "A",
    Denied = "D",
    Pending = "P"
}

export enum CommentStatusUI {
    A = "Aprovado",
    D = "Negado",
    P = "Pendente"
}

export enum CommentSentimentAnalysis {
    Positive = "P",
    Negative = "N",
    Neutral = "NL"
}

export enum ClientForHowLong {
    /*No = "N",
    NotAnymore = "NA",
    LessThan6Months = "L6M",
    LessThanOneYear = "L1Y",
    MoreThanOneYear = "M1Y"*/
    Z = "",
    N = "Não sou cliente",
    NA = "Não sou mais cliente",
    L6M = "Cliente há menos de 6 meses",
    L1Y = "Cliente há menos de 1 ano",
    M1Y = "Cliente há mais de 1 ano"

}

export enum SexChoices {
    F = "Feminino",
    M = "Masculino",
    NI = "Prefiro não responder",
    O = "Outros"
}

export enum FintechTagType {
    Personnal = 'P',
    Business = 'B',
}

export enum UserStatus {
    A = "ACTIVE",
    I = "INATIVE"
}

export interface Tag {
    id: number;
    name: string;
    type: string;
}

export interface FAQInterface {
    id: number;
    question: string;
    answer: string;
    order: number;
}

export interface FincatchDataInterface {
    id: number;
    evaluationsNumber: number;
    fintechsNumber: number;
    isToShowFintechsSocialMedia: boolean;
    isToAllowFacebookLogin: boolean;
    isToAllowGoogleLogin: boolean;
}

export interface ExpertsDataInterface {
    name: string;
    about: string;
    photo: string;
    link: string;
}

export interface UsersRankingInterface {
    initials: string,
    name: string;
    total: string;
}

export interface Banner {
    id: number;
    image_url: string;
    image_path: string;
    on_click_banner: string;
}
