import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import  Banner  from './banner';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.css']
})
export class AppBannerComponent implements AfterViewInit {

@Input() banner: Banner;
//showAd = environment.adsense.show;
showAd= false;
    constructor() {  
      }

    ngAfterViewInit() {
        setTimeout(() => {
            try {
                (window['adsbygoogle'] = window['adsbygoogle'] || []).push({
                    overlays: {bottom: true}
                });
            } catch (e) {
                console.error(e);
            }
        }, 0);
    }

}