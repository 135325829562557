import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

export interface DialogData {
  title: string;
  message: string;
}

/**
 * @title Dialog reset password
 */
@Component({
  selector: 'login_reset_password_dialog',
  templateUrl: 'login_reset_password_dialog.html',
  styleUrls: ['login_reset_password_dialog.css'],
})
export class LoginResetPasswordDialog {

  private email: string;
  public emailFormControl : UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<LoginResetPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



}
