import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { Observable, throwError } from "rxjs";
import {
  Tag,
  FAQInterface,
  Business,
  FincatchDataInterface,
} from "../interface";
import { ApiService } from "../api.service";
import { startWith, map, catchError, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../google-analytics-service";
import { MyErrorStateMatcher } from "../input-error-handlers.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { FormsPopUp } from "../forms_pop_up/forms_pop_up";
import { RequestBudgetPopup } from "../request_budget_popup/request_budget_popup";

import { MatDialog } from "@angular/material/dialog";
import { Meta, Title } from '@angular/platform-browser';
import Utils from "../utils";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./home.html",
  styleUrls: ["./home.css"],
})
export class Home implements OnInit {
  public search_Tag: string;

  public filteredTagsForUser: Observable<Tag[]>;
  private allTagsForUser: Tag[];

  public filteredTagsForBusiness: Observable<Tag[]>;
  private allTagsForBusiness: Tag[];

  public filteredBusinessByName: Observable<Business[]>;
  private allBusiness: Business[];

  public newsletterEmailFormControl: UntypedFormControl;
  public matcher: MyErrorStateMatcher;

  public fincatchNews: any[];

  public contactFormControl: UntypedFormControl;
  public usernameFormControl: UntypedFormControl;
  public messageFormControl: UntypedFormControl;

  public panelOpenState: boolean;

  public isToShowNewsLetterEmailSucess = false;

  public isToShowContactEmailSucess = false;

  public faqs: FAQInterface[];

  public fincatchData: FincatchDataInterface;

  public isPhonePortrait = false;
  public isTabletPortrait = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private formsPopUp: MatBottomSheet,
    public dialog: MatDialog,
    private titleService: Title,
    private metaTagService: Meta,
    private responsive: BreakpointObserver
  ) {
    this.panelOpenState = false;
    this.newsletterEmailFormControl = new UntypedFormControl("", [
      Validators.email,
      Validators.required,
    ]);
    this.contactFormControl = new UntypedFormControl("", [
      Validators.email,
      Validators.required,
    ]);
    this.usernameFormControl = new UntypedFormControl("", [Validators.required]);
    this.messageFormControl = new UntypedFormControl("", [Validators.required]);
    this.matcher = new MyErrorStateMatcher();
  }

  async ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Home - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Ajudando pessoas e empresas a encontrarem soluções financeiras e Contribuindo para o crescimento do movimento fintech' }
    );

    this.getFAQ();
    this.getFincatchData();
    await this.getTagsForUser();
    await this.getTagsForBusiness();
    await this.getBusiness();
    this.defineFiltersForTags();
    this.getWixPosts();

    this.responsive.observe([Breakpoints.TabletPortrait, Breakpoints.HandsetPortrait])
      .subscribe(result => {

        this.isPhonePortrait = false;
        this.isTabletPortrait = false;

        const breakpoints = result.breakpoints;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isPhonePortrait = true;
        }

    });

  }

  displayFn(tag?: Tag): string | undefined {
    return tag ? tag.name : undefined;
  }

  searchForm = this.formBuilder.group({
    search: [null, null],
  });

  get search() {
    return this.searchForm.get("search");
  }

  getTagsForUser() {
    this.apiService
      .list_tags_by_type("P")
      .toPromise()
      .then((result) => {
        this.allTagsForUser = result;
      });
  }

  getTagsForBusiness() {
    this.apiService
      .list_tags_by_type("B")
      .toPromise()
      .then((result) => {
        this.allTagsForBusiness = result;
      });
  }

  async getBusiness() {
    await this.apiService.list_simple_business().toPromise()
      .then((result) => {
        this.allBusiness = result.filter(obj => obj.isActive);
      });
  }

  private defineFiltersForTags() {
    this.filteredTagsForUser = this.search.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterTagsForUser(value)),
      catchError((error) => {
        return throwError(error.statusText);
      })
    );

    this.filteredTagsForBusiness = this.search.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterTagsForBusiness(value)),
      catchError((error) => {
        return throwError(error.statusText);
      })
    );

    this.filteredBusinessByName = this.search.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterBusinessByName(value)),
      catchError((error) => {
        return throwError(error.statusText);
      })
    );
  }

  private filterTagsForUser(value: string | Tag): Tag[] {
    let filterValue = "";
    if (value) {
      filterValue =
        typeof value === "string"
          ? value.toLowerCase()
          : value.name.toLowerCase();
      return this.allTagsForUser.filter((tag) => {
        return tag.name.toLowerCase().includes(filterValue);
      });
    } else {
      return this.allTagsForUser;
    }
  }

  private filterBusinessByName(value: string | Business): Business[] {
    let filterValue = "";
    if (value) {
      filterValue =
        typeof value === "string"
          ? value.toLowerCase()
          : value.name.toLowerCase();
      return this.allBusiness.filter((business) => {
        return business.name.toLowerCase().includes(filterValue);
      });
    } else {
      return this.allBusiness;
    }
  }

  private filterTagsForBusiness(value: string | Tag): Tag[] {
    let filterValue = "";
    if (value) {
      filterValue =
        typeof value === "string"
          ? value.toLowerCase()
          : value.name.toLowerCase();
      return this.allTagsForBusiness.filter((tag) => {
        return tag.name.toLowerCase().includes(filterValue);
      });
    } else {
      return this.allTagsForBusiness;
    }
  }

  async goToSearchResultPage(obj) {
    if (obj.type) {
      this.googleAnalyticsService.searchByTagEvent(obj.name);
      localStorage.setItem("search_page_route", "search_page")
      let cleanObjName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(obj.name)
      this.router.navigateByUrl("search_page/tag/" + obj.id + '/' + cleanObjName, {
        state: { info: obj },
      });
    } else {
      this.googleAnalyticsService.searchByFintechEvent(obj.name);
      localStorage.setItem("search_page_route", "search_page");
      let cleanObjName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(obj.name)
      this.router.navigateByUrl("search_page/fintech/" + obj.id + '/' + cleanObjName, {
        state: { info: obj },
      });
    }
  }

  goToSignup() {
    this.router.navigate(["signup"]);
  }

  async addNewsLetterEmail() {
    if (this.newsletterEmailFormControl.value) {
      await this.apiService
        .add_news_letter_email(this.newsletterEmailFormControl.value)
        .toPromise()
        .then((result) => {
          this.newsletterEmailFormControl.reset();
          this.isToShowNewsLetterEmailSucess = true;
          setTimeout(() => {
            this.isToShowNewsLetterEmailSucess = false;
          }, 3000);
        });
    }
  }

  async sendContactEmail() {
    if (
      this.usernameFormControl.value &&
      this.contactFormControl.value &&
      this.messageFormControl.value
    ) {
      await this.apiService
        .send_contact_email(
          this.usernameFormControl.value,
          this.contactFormControl.value,
          this.messageFormControl.value
        )
        .toPromise()
        .then((result) => {
          this.usernameFormControl.reset();
          this.contactFormControl.reset();
          this.messageFormControl.reset();
          this.isToShowContactEmailSucess = true;
          setTimeout(() => {
            this.isToShowContactEmailSucess = false;
          }, 3000);
        });
    }
  }

  getFAQ() {
    this.apiService
      .get_faq()
      .toPromise()
      .then((result) => {
        this.faqs = result;
      });
  }

  getWixPosts() {
    this.apiService
      .get_wix_api_posts()
      .toPromise()
      .then((result) => {
        this.fincatchNews = result;
      },
      error => {
        //ignore errors from wix
      });
  }

  getFincatchData() {
    lastValueFrom(
      this.apiService
      .get_fincatch_data())
      .then((result) => {
        if (result && result.length == 1) {
          this.fincatchData = result[0];
        } else {
          this.fincatchData = null;
        }
      })
  }

  requestBudget() {
    const dialogRef = this.dialog.open(RequestBudgetPopup, {
      width: "550px",
      height: "600px",
    });
  }

  requestMediaKit() {
    const dialogRef = this.dialog.open(RequestBudgetPopup, {
      width: "550px",
      height: "600px",
    });
  }
}
