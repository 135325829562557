import { Component, Injectable, Inject, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics-service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { RouteStateService } from './route.state.service'
import { Category, SearchType } from './interface'
import { filter, startWith, map, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';
import { Tag, Business } from './interface'
import { MatDialog } from '@angular/material/dialog';
import { TCAndPPDialogSimpleDialog } from './tc_and_pp_simple_dialog/tc_and_pp_simple_dialog'
import { Meta, Title } from '@angular/platform-browser';
import Utils from './utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private apiRoot: string;

  private error: any;

  public categories: Category[]

  private destroy = new Subject<void>();

  public isToShowSearch: boolean;

  public filteredTagsForUser: Observable<Tag[]>;
  private allTagsForUser: Tag[];

  public filteredTagsForBusiness: Observable<Tag[]>;
  private allTagsForBusiness: Tag[]

  public filteredBusinessByName: Observable<Business[]>;
  private allBusiness: Business[];

  private tcAndPPText: string;

  public isStagingEnvoriment: boolean

  public isCookieMessageViewed : boolean

  public hoverForum: boolean;

  public hoverPodcast: boolean;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public authService: AuthService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private routeStateService: RouteStateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dialog: MatDialog,
    private titleService: Title,
    private metaTagService: Meta,) {

    this.googleAnalyticsService.init();
    this.apiRoot = environment.apiUrl
    this.googleAnalyticsService.trackPageViews().subscribe();

    this.isStagingEnvoriment = Utils.isStagingEnviroment()

  }

  async ngOnInit() {
    //this.matBottomSheet.open(CookiePopUp);
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Fincatch')
    this.metaTagService.addTags([
      { name: 'description', content: 'Ajudando pessoas e empresas a encontrarem soluções financeiras e Contribuindo para o crescimento do movimento fintech' },
      {
        name: 'keywords', content: 'fintech, câmbio, cartões, crédito, criptomoedas, Crowdfunding, Dívidas, Fidelização, Finanças Pessoais, Gestão Empresarial, Investimentos, Meios de Pagamentos, Seguros, Serviços Digitais, Carteiras Digitais, Contas e Bancos Digitais,'
          + 'Contas e Bancos Digitais Empresariais, Contratação de Seguros, Serviços Adicionais, Marketplace & Comparadores, App de Pagamentos, Soluções de Pagamentos, Buscador & Comparador, '
          + 'Robôs, Análises, Compra e Venda de Empresa, P2P, Equity Crowdfunding, Corretoras, Gestão de Investimentos, Contabilidade Online, Gestão Financeira, Precificação, Suporte, Adicionais, Gestão Pessoal,'
          + 'Programas de Fidelidade, Benefícios para Colaborador, Negociação de Dívidas, Projetos, Equity, Investimentos e Marketplaces, Contas Digitais, Consórcios, Empréstimos e Financiamentos, Empresarial, Pré-Pagos, Câmbio e Remessas'
      }
    ]);
    this.isCookieMessageViewed = localStorage.getItem('cookied_message_viewed') == 'true';
    this.getCategorys()
    this.isToShowSearch = false;
    
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  getCategorys() {
    this.apiService.list_categorys().toPromise().then(result => {
      this.categories = result
      for (var category of this.categories) {
        category.searchType = SearchType.Category;
      }
    })
  }

  goToCategory(category) {
    //add params so it can be read in outside component
    let cleanCategoryName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(category.name)
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ category_id: category.id, searchType: category.searchType, searchName: cleanCategoryName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openCategoryEvent(category.name)
    //this.router.navigate(['business_list', category.searchType, category.id])
    this.router.navigate(['/'], { skipLocationChange: true })
      .then(() => this.router.navigate(['business_list', category.searchType, cleanCategoryName, category.id]));
  }

  goToHome() {
    this.router.navigate(['home'])
  }

  goToFAQAndContact() {
    //TODO "SCROOOL TO FAQQQ AND CONTACT")
  }

  goToLogin() {
    this.router.navigate(['login'])
  }

  goToSignup() {
    this.router.navigate(['signup'])
  }

  changeIsToShowSearch() {
    this.isToShowSearch = !this.isToShowSearch;
  }

  displayFn(tag?: Tag): string | undefined {
    return tag ? tag.name : undefined;
  }

  searchForm = this.formBuilder.group({
    search: [null, null]
  });

  get search() {
    return this.searchForm.get('search');
  }

  async goToSearchResultPage(obj) {
    //this is needed because if user is in a evaluation page and wants to use
    //the SearchDialog to go to another evaluation, by Angular design reloading
    //the page is not so simple, so this solves the problem in a simpler way
    //than other approaches
    const route = localStorage.getItem('search_page_route');
    if (route == 'search_page') {
      this.navigateToSearchPage(obj, 'search_page2')
      localStorage.setItem('search_page_route', 'search_page2');
    } else if (route == 'search_page2') {
      this.navigateToSearchPage(obj, 'search_page')
      localStorage.setItem('search_page_route', 'search_page');
    } else {
      this.navigateToSearchPage(obj, 'search_page')
      localStorage.setItem('search_page_route', 'search_page');
    }
  }

  navigateToSearchPage(obj, path) {
    if (obj.type) {
      this.googleAnalyticsService.searchByTagEvent(obj.name)
      let cleanObjName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(obj.name)
      this.router.navigateByUrl(path + '/tag/' + obj.id + '/' + cleanObjName, { state: { info: obj } })
    } else {
      this.googleAnalyticsService.searchByFintechEvent(obj.name)
      let cleanObjName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(obj.name)
      this.router.navigateByUrl(path + '/fintech/' + obj.id + '/' + cleanObjName, { state: { info: obj } })
    }
  }

  async getTagsForUser() {
    this.apiService.list_tags_by_type("P").toPromise().then(result => {
      this.allTagsForUser = result
    });
  }

  async getTagsForBusiness() {
    this.apiService.list_tags_by_type("B").toPromise().then(result => {
      this.allTagsForBusiness = result
    });
  }

  async getBusiness() {
    await this.apiService.list_simple_business().toPromise()
      .then((result) => {
        this.allBusiness = result.filter(obj => obj.isActive);
      });
  }

  private defineFiltersForTags() {
    this.filteredTagsForUser = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterTagsForUser(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );

    this.filteredTagsForBusiness = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterTagsForBusiness(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );

    this.filteredBusinessByName = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterBusinessByName(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );
  }

  private filterTagsForUser(value: string | Tag): Tag[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allTagsForUser.filter(tag => {
        return tag.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allTagsForUser;
    }
  }

  private filterBusinessByName(value: string | Business): Business[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allBusiness.filter(business => {
        return business.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allBusiness;
    }
  }

  private filterTagsForBusiness(value: string | Tag): Tag[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allTagsForBusiness.filter(tag => {
        return tag.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allTagsForBusiness;
    }
  }

  profile() {
    this.router.navigate(['user_profile'])
  }


  logout() {
    if (this.authService.isSocialLogin()) {
      this.authService.socialLogout().subscribe(
        success => {
          this.authService.logout().subscribe(
            success => {
              this.router.navigate(['home'])
            },
            error => {
              this.error = error
            }
          );
        },
        error => {
          this.error = error
        }
      );
    } else {
      this.authService.logout().subscribe(
        success => {
          this.router.navigate(['home'])
        },
        error => {
          this.error = error
        }
      );
    }
  }

  get logoutLink(): string {
    return this.apiRoot.concat('backend_api/accounts/logout/');
  }

  async tcAndPPDialogSimpleDialog() {
    await this.apiService.get_tc_and_pp_text().toPromise().then(result => {
      this.tcAndPPText = result[0].content
    });

    const dialogRef = this.dialog.open(TCAndPPDialogSimpleDialog, {
      width: '700px',
      height: '500px',
      data: {
        tcAndPPText: this.tcAndPPText
      }
    });
  }

  setCookieMessageViewed() {
    localStorage.setItem('cookied_message_viewed', 'true');
    this.isCookieMessageViewed = true;
  }

  openForum(): void {
    window.open('https://www.blog.fincatch.com.br/forum?feedType=all-posts', "_blank");
  }

  openPodcast(): void {
    window.open('https://spotifyanchor-web.app.link/e/KJz2uGXv2zb', "_blank");
  }

}
