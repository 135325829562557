import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams, HttpHeaders } from '@angular/common/http';

import { Category, QuestionRating, Business, Question, Tag, FAQInterface, FincatchDataInterface, ExpertsDataInterface, UsersRankingInterface, Banner } from './interface'
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

  private apiRoot: string;

  private socialLoginRoot: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.apiRoot = environment.apiUrl.concat('backend_api/finance_api')
    this.socialLoginRoot = environment.apiUrl.concat('backend_api/accounts')
  }

  list_categorys(category_id?) {
    let params = new HttpParams();
    params = params.append("id", category_id);
    if (category_id)
      return this.http.get<Category[]>(this.apiRoot.concat('/categorys/' + category_id));
    return this.http.get<Category[]>(this.apiRoot.concat('/categorys'));
  }

  list_categorys_with_subcategories(category_id?) {
    let params = new HttpParams();
    params = params.append("id", category_id);
    if (category_id)
      return this.http.get<Category[]>(this.apiRoot.concat('/categorysWithSubcategories/' + category_id));
    return this.http.get<Category[]>(this.apiRoot.concat('/categorysWithSubcategories'));
  }

  list_categorys_with_business(category_id?) {
    let params = new HttpParams();
    params = params.append("id", category_id);
    if (category_id)
      return this.http.get<Category[]>(this.apiRoot.concat('/categorysWithBusiness/' + category_id));
    return this.http.get<Category[]>(this.apiRoot.concat('/categorysWithBusiness'));
  }

  list_business_by_categorys(category_id?) {
    let params = new HttpParams().set('category', category_id);
    return this.http.get<Business[]>(this.apiRoot.concat('/business'), { params });
  }

  businessGradeList(tag_id?, category_id?, subCategory_id?) {
    let params = {}
    if (category_id) {
      params = new HttpParams().set('category', category_id);
    } else if (subCategory_id) {
      params = new HttpParams().set('subCategory', subCategory_id);
    } else if (tag_id) {
      params = new HttpParams().set('tag_id', tag_id);
    }
    return this.http.get(this.apiRoot.concat('/businessGradeList'), { params });
  }

  businessGradeAndRelatedBusinessListGradeSet(business_id) {
    let params = new HttpParams().set('business_id', business_id);
    return this.http.get(this.apiRoot.concat('/businessGradeAndRelatedBusinessListGradeSet'), { params });
  }

  bussines_grade_info(business_id) {
    let params = new HttpParams().set('business', business_id);
    return this.http.get<any[]>(this.apiRoot.concat('/businessGradeInfo'), { params });
  }

  list_business_by_subCategory(subCategory_id) {
    let params = new HttpParams().set('subCategory', subCategory_id);
    return this.http.get<Business[]>(this.apiRoot.concat('/business'), { params });
  }

  create_rating_question(data: []) {
    return this.http.post(
      this.apiRoot.concat('/questionRating'),
      data
    );
  }

  update_rating_question(data: []) {
    return this.http.patch(
      this.apiRoot.concat('/questionRatingAllowBulkUpd/multi_update'),
      data
    );
  }

  list_rating_question_by_business_and_user(business_id: any, user_id: any) {
    let params = new HttpParams().set('business', business_id).set('user', user_id);
    //params.set('business', business_id);
    //return this.http.get<QuestionRating[]>(this.apiRoot.concat('/questionRating/?business/' + business_id));
    //return this.http.get<QuestionRating[]>(this.apiRoot.concat('/questionRating'), {params});
    return this.http.get<any[]>(this.apiRoot.concat('/questionRatingAvgSet'), { params });
  }

  list_all_rating_question_by_user(user_id: any) {
    let params = new HttpParams().set('user', user_id);
    return this.http.get<any[]>(this.apiRoot.concat('/allQuestionRatingAvgByUserSet'), { params });
  }

  get_user_comment_by_group(business_id: any, user_id: any) {
    let params = new HttpParams().set('business', business_id).set('user', user_id);
    return this.http.get<any[]>(this.apiRoot.concat('/userComment'), { params });
  }

  get_user_comments_by_business(business_id: any) {
    let params = new HttpParams().set('business', business_id);
    return this.http.get<any[]>(this.apiRoot.concat('/userCommentFullInfo'), { params });
  }

  list_question_by_subCategory(business_id) {
    let params = new HttpParams().set('business_id', business_id);
    return this.http.get<any[]>(this.apiRoot.concat('/questions'), { params });
  }

  list_business(business_id?) {
    let params = new HttpParams();
    params = params.append("id", business_id);
    if (business_id)
      return this.http.get<Business[]>(this.apiRoot.concat('/business/' + business_id));
    return this.http.get<Business[]>(this.apiRoot.concat('/business'));
  }

  list_simple_business(business_id?) {
    let params = new HttpParams();
    params = params.append("id", business_id);
    if (business_id)
      return this.http.get<Business[]>(this.apiRoot.concat('/simple_business/' + business_id));
    return this.http.get<Business[]>(this.apiRoot.concat('/simple_business'));
  }

  loginWithFb() {
    return this.http.get(this.socialLoginRoot.concat('/facebook/login'));
  }

  list_tags_by_type(type) {
    let params = new HttpParams();
    params = params.append("type", type);
    if (type)
      return this.http.get<Tag[]>(this.apiRoot.concat('/tag?type=' + type));
    return this.http.get<Tag[]>(this.apiRoot.concat('/tag'));
  }

  get_tag_by_id(tagId) {
    if (tagId)
      return this.http.get<Tag[]>(this.apiRoot.concat('/tag?id=' + tagId));
    return this.http.get<Tag[]>(this.apiRoot.concat('/tag'));
  }

  get_tag_by_name(tagName) {
    if (tagName)
      return this.http.get<Tag[]>(this.apiRoot.concat('/tag?name=' + tagName));
    return this.http.get<Tag[]>(this.apiRoot.concat('/tag'));
  }

  update_tag_access(data) {
    return this.http.patch(
      this.apiRoot.concat('/tag/' + data.id),
      data
    );
  }

  get_tc_and_pp_text() {
    return this.http.get(
      this.apiRoot.concat('/tc_and_pp_view')
    );
  }

  get_tc_and_pp_user_checks_text() {
    return this.http.get<any[]>(
      this.apiRoot.concat('/userTCAndPPAcceptanceText')
    );
  }

  get_faq() {
    return this.http.get<FAQInterface[]>(this.apiRoot.concat('/get_faq'));
  }

  get_wix_api_posts() {
    return this.http.get<any[]>(this.apiRoot.concat('/get_wix_posts'));
  }

  add_news_letter_email(email: string) {
    const data = {
      "newsletter_email": email
    }
    return this.http.post(
      this.apiRoot.concat('/add_news_letter_email'),
      data
    );
  }

  send_contact_email(user_name: string, contact: string, message: string) {
    const data = {
      "user_name": user_name,
      "contact": contact,
      "message": message
    }
    return this.http.post(
      this.apiRoot.concat('/send_contact_email'),
      data
    );
  }

  send_request_budget_email(user_name: string, contact: string, message: string) {
    const data = {
      "user_name": user_name,
      "contact": contact,
      "message": message
    }
    return this.http.post(
      this.apiRoot.concat('/send_request_budget_email'),
      data
    );
  }

  get_fincatch_data() {
    return this.http.get<FincatchDataInterface[]>(this.apiRoot.concat('/get_fincatch_data'));
  }

  get_experts_data() {
    return this.http.get<ExpertsDataInterface[]>(this.apiRoot.concat('/get_experts_data'));
  }

  get_users_ranking_data() {
    return this.http.get<UsersRankingInterface[]>(this.apiRoot.concat('/get_users_rank'));
  }

  list_banners(isHomePage?: boolean , category_id?: string, business_id?: string) {
    let params = new HttpParams();
    if (isHomePage) {
      params = params.append("is_to_show_in_home", isHomePage);
    } else if (category_id) {
      params = params.append("category_id", category_id);
    } else if (business_id) {
      params = params.append("business_id", business_id);
    }

    return this.http.get<Banner[]>(this.apiRoot.concat('/get_banners'), { params });

  }


}
