import { Component} from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'tc_and_pp_simple_screen',
  templateUrl: 'tc_and_pp_simple_screen.html',
  styleUrls: ['./tc_and_pp_simple_screen.css']
})
export class TCAndPPDialogSimpleScreen {

  tcAndPPText: string;

  constructor(private apiService: ApiService,
   ) {
     this.tcAndPPGetData();
     //console.log("data ", data)
   }

   async tcAndPPGetData(){
      await this.apiService.get_tc_and_pp_text().toPromise().then(result => {
        this.tcAndPPText = result[0].content
      });
    }
  }
