import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

export interface DialogData {
    title: string;
    message: string[];
}

@Component({
    selector: 'login_password_error_dialog',
    templateUrl: 'login_password_error_dialog.html',
    styleUrls: ['./login_password_error_dialog.css']
})
export class LoginPasswordErroDialog {

    constructor(
        public dialogRef: MatDialogRef<LoginPasswordErroDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

}
