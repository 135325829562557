import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Observable, of, Subject } from 'rxjs';
import { groupBy, mergeMap, toArray, map, takeUntil } from 'rxjs/operators';
import Utils from '../utils'
import { RouteStateService } from '../route.state.service'
import { CommentSentimentAnalysis, ClientForHowLong, SearchType, Tag } from '../interface'
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-list',
  templateUrl: './business_evaluation_result.component.html',
  styleUrls: ['./business_evaluation_result.component.css']
})
export class BusinessEvaluationResultComponent implements OnInit {

  public business_id: any;
  public businessGradeInfo: any;
  public businessGradeInfoHasSocial: boolean;
  public businessGradeInfoHasInformation: boolean;
  public businessIsFromAssets: boolean;

  private category_id: any;
  searchType: any;
  searchName: string;

  private destroy = new Subject<void>();

  usersComment: any;

  clientForHowLong = ClientForHowLong

  allTagsFromBussiness = ''

  public hover: boolean;

  public isToShowFintechSocialMedia: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private routeStateService: RouteStateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {

    this.business_id = +this.activatedRoute.snapshot.paramMap.get('business_id');
    this.category_id = +this.activatedRoute.snapshot.paramMap.get('category_id');
    this.searchType = this.activatedRoute.snapshot.paramMap.get('searchType');
    this.searchName = this.activatedRoute.snapshot.paramMap.get('search_name');


  }

  async ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Resultado das avaliações da fintech - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Veja aqui o resultado das avaliações da fintech escolhida.' }
    );
    //TODO which kind of object is this.routeStateService.pathParam
    //TODO access the value better
    this.routeStateService.pathParam.subscribe(
      data => {
        this.category_id = data.category_id
      }
    )
    this.getFincatchData();
    await this.getUserCommentsByBusiness()
    await this.getBussinesGradeInfo()
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  async getBussinesGradeInfo() {
    await this.apiService.bussines_grade_info(this.business_id).toPromise().then(result => {
      this.businessGradeInfo = result[0]
      if (!this.businessGradeInfo.business.logo.includes('http')) {
        this.businessGradeInfo.business.logo = 'assets/' + this.businessGradeInfo.business.logo
      }
      this.businessGradeInfoHasSocial = this.businessGradeInfo.business.instagram
        || this.businessGradeInfo.business.twitter
        || this.businessGradeInfo.business.facebook
        || this.businessGradeInfo.business.youtube
        || this.businessGradeInfo.business.linkedin
      this.businessGradeInfoHasInformation = this.businessGradeInfo.business.employees_range
        || (this.businessGradeInfo.business.city || this.businessGradeInfo.business.state)
        || this.businessGradeInfo.business.foundation
        || this.businessGradeInfo.business.acceleration
        || (this.businessGradeInfo.business.what_offers || this.businessGradeInfo.business.what_offers_link)
      /*for (let ft of this.businessGradeInfo.business.fintechTags) {
        this.allTagsFromBussiness += '[' + ft.name + ']'
      }*/
    });
  }

  async getUserCommentsByBusiness() {
    await this.apiService.get_user_comments_by_business(this.business_id).toPromise().then(result => {
      this.usersComment = result
    });
  }

  isPositive(sentiment) { return sentiment == CommentSentimentAnalysis.Positive }

  isNegative(sentiment) { return sentiment == CommentSentimentAnalysis.Negative }

  isNeutral(sentiment) { return sentiment == CommentSentimentAnalysis.Neutral }

  evaluation() {
    //add params so it can be read in outside component
    let cleanSearchName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(this.searchName)
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: this.business_id, category_id: this.category_id, searchType: this.searchType, searchName: cleanSearchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechEvaluationEvent(this.businessGradeInfo.business.name);
    this.router.navigate(['evaluation', this.searchType, cleanSearchName, this.business_id])
  }

  goToExpertsPage() {
    this.router.navigate(['experts'])
  }

  public openExternalLink(externalLink) {
    window.open(externalLink, "_blank");
  }

  public onLinkdinClick(linkedinURL: string) {
    this.googleAnalyticsService.userClickedFintechSocialURL('linikedin', this.businessGradeInfo.business.name)
    window.open(linkedinURL, "_blank");
  }

  public onYoutubeClick(youtubeURL: string) {
    this.googleAnalyticsService.userClickedFintechSocialURL('youtube', this.businessGradeInfo.business.name)
    window.open(youtubeURL, "_blank");
  }

  public onFacebookClick(facebookURL: string) {
    this.googleAnalyticsService.userClickedFintechSocialURL('facebook', this.businessGradeInfo.business.name)
    window.open(facebookURL, "_blank");
  }

  public onTwitterClick(twitterURL: string) {
    this.googleAnalyticsService.userClickedFintechSocialURL('twitter', this.businessGradeInfo.business.name)
    window.open(twitterURL, "_blank");
  }

  public onInstagramClick(instagramURL: string) {
    this.googleAnalyticsService.userClickedFintechSocialURL('instagram', this.businessGradeInfo.business.name)
    window.open(instagramURL, "_blank");
  }

  public onWhatOffersLinkClick(whatOffersLink: string) {
    this.googleAnalyticsService.userClickedOnWhatOffersLinkFromFintechURL(this.businessGradeInfo.business.name)
    window.open(whatOffersLink, "_blank");
  }

  public goToTagSearchPage(tag: Tag) {
    this.googleAnalyticsService.searchByTagEventFromFintechPage(tag.name, this.businessGradeInfo.business.name);
    localStorage.setItem("search_page_route", "search_page");
    let cleanObjName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(tag.name)
    this.router.navigateByUrl("search_page/tag/" + tag.id + "/" + cleanObjName, {
      state: { info: tag },
    });
  }

  async getFincatchData() {
    await this.apiService
      .get_fincatch_data()
      .toPromise()
      .then((result) => {
        if (result && result.length == 1) {
          this.isToShowFintechSocialMedia = result[0].isToShowFintechsSocialMedia;
        }
      });
  }

}
