<div *ngIf="usersRanking && hasMoreThanFourUsers">
    <div class="title-div" fxLayout="column" fxLayoutAlign="center center">
        <h1 class="title" ngClass.lt-md="title-lt-md">Ranking de usuários:</h1>
        <h2 class="description" ngClass.lt-md="description-lt-md">Aqui você vê sua posição em nosso
            ranking de
            acordo com suas
            ações no
            site!</h2>
        <a mat-button class="btn btn-participate center" ngClass.lt-md="btn-participate-lt-md"
            *ngIf="authService.isLoggedOut()" (click)="goToSignup()">Participar</a>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="div-podium" ngClass.lt-md="div-podium-lt-md ">

        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center" class="circles-padding"
                ngClass.lt-md="circles-padding_lt-md">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="circle circle-green">{{usersRanking.first_three_users[1].total}}
                    </div>
                    <div class="userName">{{usersRanking.first_three_users[1].name}}</div>
                </div>
                <div class="badge_second">
                    <img src="assets/fincatch/second_medal.svg" />
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" class="circles-padding first-up"
                ngClass.lt-md="circles-padding_lt-md">
                <div class="badge_first">
                    <img src="assets/fincatch/first_medal.svg" />
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="circle circle-gold">{{usersRanking.first_three_users[0].total}}
                    </div>
                    <div class="userName">{{usersRanking.first_three_users[0].name}}</div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="circle circle-blue">{{usersRanking.first_three_users[2].total}}
                    </div>
                    <div class="userName">{{usersRanking.first_three_users[2].name}}</div>
                </div>
                <div class="badge_third">
                    <img src="assets/fincatch/third_medal.svg" />
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="div-table" ngClass.lt-md="div-table_lt-md">
        <mat-table #table [dataSource]="usersRanking.forth_up_users">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef class="table_columns_name"> Posição </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="cell-user-position">
                    {{i + 4}}°
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef class="table_columns_name"> Usuário </mat-header-cell>
                <mat-cell *matCellDef="let element" class="cell-user-name">
                    {{element.name}} </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef class="table_columns_name"> Pontuação </mat-header-cell>
                <mat-cell *matCellDef="let element" class="cell-total">
                    <div class="rectangle-total">
                        {{element.total}}
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>


    </div>


</div>


<div *ngIf="usersRanking && !hasMoreThanFourUsers" class="empty-rank-div" ngClass.lt-md="empty-rank-div-lt-md">

    <div class="div-card-empty-rank" ngClass.lt-md="div-card-empty-rank-lt-md" fxLayout="column"
        fxLayoutAlign="space-around center">

        <mat-card appearance="outlined" class="card-empty-rank  mat-elevation-z4" ngClass.lt-md="card-empty-rank-lt-md mat-elevation-z4">
            <mat-card-content class="card-content-empty-rank" ngClass.lt-md="card-content-empty-rank-lt-md">
                <div class="div-title-empty-rank" ngClass.lt-md="div-title-empty-rank-lt-md"><span
                        class="title-empty-rank" ngClass.lt-md="title-empty-rank-lt-md">Ranking de usuários</span></div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <span class="no_users" ngClass.lt-md="no_users-lt-md">Que comecem os jogos!</span>
                    <span class="no_users_bold" ngClass.lt-md="no_users_bold-lt-md">Seja o primeiro a pontuar no <br />
                        nosso ranking!</span>
                    <span *ngIf="authService.isLoggedIn() && authService.isLogin()" class="no_users center"
                        ngClass.lt-md="no_users-lt-md">Crie uma
                        avaliação
                        ;-)</span>
                </div>

                <div class="btn_div"><a mat-button class="btn btn-signup center" ngClass.lt-md="btn-signup-lt-md"
                        *ngIf="authService.isLoggedOut()" (click)="goToSignup()">Cadastrar</a></div>

            </mat-card-content>
        </mat-card>
        <img ngClass.lt-md="overlay-hero-lt-md" class="overlay-hero" src="assets/fincatch/users_rank_hero.svg" />
        <img fxShow="true" fxShow.lt-lg="false" class="overlay-circle" src="assets/fincatch/pink_circle.svg" />


    </div>
</div>