

<banner isHomePage="true"></banner>


<div fxLayout="row" fxLayoutAlign="center none" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
  ngClass.gt-sm="top-padding-side">
  <div fxLayout="column" fxLayoutAlign="center start" ngClass.gt-sm="top-padding-side">
    <div>
      <h1 class="main-text" ngClass.lt-md="main-text-sm">Bancos Digitais, <br /> controles financeiros, <br />
        corretoras de
        investimento <br /> e muito mais.</h1>
    </div>

    <div class="search-bloc-div">
      <span class="desc-text">A fintech que você procura está aqui.</span>
    </div>
    <div class="form-search">
      <form [formGroup]="searchForm">
        <div>
          <input class="tag-form" ngClass.lt-sm="tag-form_lt-sm mat-elevation-z10" type="text"
            placeholder="Busque por fintechs ou tags (palavras-chave)" matInput formControlName="search"
            [matAutocomplete]="autoDialog">
          <i class="fas fa-search"></i>
        </div>

        <mat-autocomplete #autoDialog="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)="goToSearchResultPage($event.option.value)">
          <mat-optgroup *ngIf="filteredTagsForUser"> <span class="optgroup_for_you">-----
              Para
              você</span>
            <mat-option *ngFor="let search of filteredTagsForUser | async" [value]="search">
              <span class="auto-complete-results">{{search.name}}</span>
            </mat-option>
          </mat-optgroup>
          <mat-optgroup *ngIf="filteredTagsForBusiness">
            <span class="optgroup_for_your_bussines">---- Para seu negócio</span>
            <mat-option *ngFor="let search of filteredTagsForBusiness | async" [value]="search">
              <span class="auto-complete-results">{{search.name}}</span>
            </mat-option>
          </mat-optgroup>
          <mat-optgroup *ngIf="filteredBusinessByName">
            <span class="optgroup_fintechs">----- Fintechs</span>
            <mat-option *ngFor="let search of filteredBusinessByName | async" [value]="search">
              <span class="auto-complete-results">{{search.name}}</span>
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </form>
    </div>
  </div>
  <div>
    <img ngClass.lt-sm="img-top-size" ngClass.lt-md="img-top-padding-md" src="assets/fincatch/arte-topo.svg" />
  </div>

</div>

<div class="div-numbers-and-promotions" ngClass.lt-md="div-numbers-and-promotions-sm ">
  <div
    *ngIf="fincatchData && ((fincatchData.evaluationsNumber && fincatchData.evaluationsNumber > 0) || (fincatchData.fintechsNumber && fincatchData.fintechsNumber > 0))"
    class="div-numbers" ngClass.lt-md="div-numbers-sm" fxLayout="row" fxLayout.lt-sm="column"
    fxLayoutAlign="space-evenly center">
    <div *ngIf="(fincatchData.fintechsNumber && fincatchData.fintechsNumber > 0)" fxLayout="row"
      fxLayoutAlign="space-between center">
      <img class="numbers-img" src="assets/fincatch/fintech.png" />
      <span class="fintechs-number">+{{fincatchData.fintechsNumber}}</span>
    </div>
    <div *ngIf="(fincatchData.fintechsNumber && fincatchData.fintechsNumber > 0)" fxLayoutAlign.lt-sm="center center"
      ngClass.lt-sm="font-numbers-div-sm"><span class="font-numbers">Fintechs cadastradas <br /> na plataforma</span>
    </div>
    <div *ngIf="(fincatchData.evaluationsNumber && fincatchData.evaluationsNumber > 0)" fxLayout="row"
      fxLayoutAlign="space-between center" ngClass.lt-sm="div-numbers-data">
      <img class="numbers-img" src="assets/fincatch/estrelas.png" />
      <span class="evaluations-number">+{{fincatchData.evaluationsNumber}}</span>
    </div>
    <div *ngIf="(fincatchData.evaluationsNumber && fincatchData.evaluationsNumber > 0)"
      fxLayoutAlign.lt-sm="center center" ngClass.lt-sm="font-numbers-div-sm"><span class="font-numbers">Avaliações de
        <br /> usuários</span></div>
  </div>

  <div>
    <ngb-carousel>
      <ng-template ngbSlide>
        <div class="carousel-template" ngClass.lt-md="carousel-template-md" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="center center">
          <div ngClass.gt-sm="carousel-img-div"><img class="carousel-img" ngClass.lt-sm="carousel-img-md"
              src="assets/fincatch/slide-mentoria.svg" /></div>
          <div fxLayout="column" fxLayoutAlign="center start">
            <h2 class="carousel-info carousel-info-help-color" ngClass.lt-sm="carousel-info-sm">Precisa de ajuda?</h2>
            <span class="carousel-desc" ngClass.lt-sm="carousel-desc-sm">Responda nosso formulário <br /> e receba um
              e-mail personalizado <br /> com as informações que <br /> você necessita!</span>
            <div fxLayout="row" fxLayoutAlign="center center" class="div-btn-carousel">
              <a ngClass.lt-sm="btn-lt-sm btn-for-user" mat-button class="btn-forms btn-for-user"
                href="https://forms.gle/9J78MvfACQwY9ZN47" target="_blank">Para você</a>
              <a ngClass.lt-sm="btn-lt-sm btn-for-business-lt-sm" mat-button class="btn-forms btn-for-business"
                href="https://forms.gle/T82fDifM1WULV3Za7" target="_blank">Para seu negócio</a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="carousel-template" ngClass.lt-md="carousel-template-md" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign="center center">
          <div ngClass.gt-sm="carousel-img-div"><img class="carousel-img" ngClass.lt-sm="carousel-img-md"
              src="assets/fincatch/slide-livro.svg" /></div>
          <div fxLayout="column" fxLayoutAlign="center start">
            <h2 fxShow="true" fxShow.lt-md="false" class="carousel-info carousel-info-budget-color"
              ngClass.lt-sm="carousel-info-sm">Precisa de consultoria financeira <br /> para você ou para seu negócio?
            </h2>
            <h2 fxShow="false" fxShow.lt-md="true" class="carousel-info carousel-info-budget-color"
              ngClass.lt-sm="carousel-info-sm">Precisa de consultoria <br /> financeira <br /> para você ou para seu
              negócio? </h2>
            <span fxShow="true" fxShow.lt-md="false" class="carousel-desc" ngClass.lt-sm="carousel-desc-sm">Peça um
              orçamento! <br />Nos envie um e-mail com <br /> seus dados e sua solicitação, <br />ficaremos felizes em
              atender você. </span>
            <span fxShow="false" fxShow.lt-md="true" class="carousel-desc" ngClass.lt-sm="carousel-desc-sm">Peça um
              orçamento! <br />Nos envie um e-mail<br /> com seus dados e <br /> sua solicitação, <br />ficaremos
              felizes <br /> em atender você. </span>
            <div fxLayout="row" fxLayoutAlign="center center" class="div-btn-carousel">
              <a ngClass.lt-sm="btn-lt-sm btn-for-budget" mat-button class="btn-forms btn-for-budget"
                (click)="requestBudget()">Solicitar Orçamento</a>
            </div>
          </div>
        </div>
      </ng-template>

    </ngb-carousel>
  </div>
</div>

<div ngClass.gt-sm="div-blog" ngClass.lt-md="div-blog-sm">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="center center"
    class="div-blog-news-title">
    <h2 class="blog-title">Blog fincatch</h2>
    <a fxShow="true" fxShow.lt-sm="false" mat-button class="btn-see-more-news" href="https://www.blog.fincatch.com.br/"
      target="_blank">Ver todas as notícias</a>
  </div>
  <drag-scroll scrollbar-hidden="true" fxLayout="row" ngClass.lt-sm="div-blog-drag-scroll-sm"
    ngClass.lt-md="div-blog-drag-scroll-md">
    <div>
      <div drag-scroll-item class="div-blog-add-email-newsletter-card" fxLayout="column"
        fxLayoutAlign="space-between start">
        <div>
          <img src="assets/fincatch/mail.svg" />
        </div>
        <div class="newsletter-text-desc-div">
          <span class="newsletter-text newsletter-text-desc">Fique por dentro das <br /> principais notícias sobre
            <br /> fintechs e receba nossa <br /> newsletter</span>
        </div>
        <div>
          <mat-form-field appearance="outline" class="email-newsletter">
            <div >
              <span class="newsletter-text">Email</span>
            </div>
            <input class="newsletter-email" type="email" placeholder="Ex. ana@seuemail.com.br" matInput
              [formControl]="newsletterEmailFormControl" [errorStateMatcher]="matcher">
            <mat-error class="font-family"
              *ngIf="newsletterEmailFormControl.hasError('email') && !newsletterEmailFormControl.hasError('required')">
              Email inválido
            </mat-error>
            <mat-error class="font-family" *ngIf="newsletterEmailFormControl.hasError('required')">
              E-mail é <strong>obrigatório</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" style="position: relative;">
          <a mat-button class="newsletter-btn" (click)="addNewsLetterEmail()">Cadastrar</a>
          <div class="sucess-add-news-letter-div"><span *ngIf="isToShowNewsLetterEmailSucess"
              class="sucess-add-news-letter">Seu e-mail foi adicionado com sucesso!</span></div>
        </div>
      </div>
    </div>
    <div *ngIf="fincatchNews && fincatchNews.length > 0">
      <div drag-scroll-item *ngFor="let news of fincatchNews" class="div-blog-newsletter-card" fxLayout="column"
        fxLayoutAlign="space-between start">
        <div>
          <img class="news-img" src='{{news.image}}' />
        </div>
        <div class="div-news-title">
          <h3 class="news-title">{{news.title}}</h3>
        </div>
        <div class="div-news-content">
          <span class="news-content">{{news.excerpt}}</span>
        </div>
        <div class="div-news-read-more">
          <a mat-button class="news-read-more-btn" href="{{news.link}}" target="_blank">Leia mais</a>
        </div>
      </div>
    </div>
  </drag-scroll>
  <div fxShow="false" fxShow.lt-sm="true" class="div-btn-see-more-news-sm">
    <a mat-button class="btn-see-more-news" href="https://www.blog.fincatch.com.br/" target="_blank">Ver todas as
      notícias</a>
  </div>
</div>

<div class="hr-divider-faq">
  <hr style="height:3px;" />
</div>

<div>
  <div *ngIf="faqs && faqs.length > 0" #faqTarget fxLayout="row" fxLayoutAlign="center" fxFlex.gt-md=60%>
    <h2 class="faq-title">FAQ</h2>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-md="column" ngClass.gt-sm="div-faq-contact">
  <div *ngIf="faqs && faqs.length > 0" fxFlex.gt-md=60% fxLayout="column" fxLayoutAlign="space-between center"
    class="div-faq">
    <div *ngFor="let faq of faqs">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true"
          class="faq-accordion" ngClass.lt-sm="faq-accordion-sm">
          <mat-expansion-panel-header [collapsedHeight]="'35px'" [expandedHeight]="'35px'" class="expansion-panel">
            <mat-panel-title>
              <h3 class="expansion-panel-title">{{faq.question}}</h3>
            </mat-panel-title>
            <img *ngIf="!panelOpenState" src="assets/fincatch/mais.svg" />
            <img *ngIf="panelOpenState" src="assets/fincatch/menos.svg" />

          </mat-expansion-panel-header>

          <div class="expansion-panel-content-div">
            <p class="expansion-panel-content" [innerHTML]="faq.answer"></p>
          </div>


        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div fxFlex.gt-md=40% class="div-contact-card" ngClass.lt-sm="div-contact-card-sm" fxLayout="column"
    fxLayoutAlign="space-between start">
    <div>
      <span class="contact-title">Contato</span>
    </div>
    <div>
      <mat-form-field appearance="outline" class="form-field-contact-full-width contact-name-padding">
        <div>
          <span class="newsletter-text">Nome</span>
        </div>
        <input class="newsletter-email" type="text" placeholder="Ex. Ana Maria" matInput
          [formControl]="usernameFormControl" [errorStateMatcher]="matcher">
        <mat-error class="font-family" *ngIf="usernameFormControl.hasError('required')">
          Nome é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field-contact-full-width contact-fields">
        <div>
          <span class="newsletter-text">Email</span>
        </div>
        <input class="newsletter-email" type="email" placeholder="Ex. ana@seuemail.com.br" matInput
          [formControl]="contactFormControl" [errorStateMatcher]="matcher">
        <mat-error class="font-family"
          *ngIf="contactFormControl.hasError('email') && !contactFormControl.hasError('required')">
          Email inválido
        </mat-error>
        <mat-error class="font-family" *ngIf="contactFormControl.hasError('required')">
          E-mail é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field-contact-full-width contact-fields">
        <div>
          <span class="newsletter-text">Mensagem</span>
        </div>
        <textarea class="contact-message-input" type="text" placeholder="Ex. Olá, gostaria de fazer uma sugestão."
          matInput [formControl]="messageFormControl" [errorStateMatcher]="matcher"></textarea>
        <mat-error class="font-family" *ngIf="messageFormControl.hasError('required')">
          Mensagem é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>

      <div class="send-contact-div" fxLayout="column" fxLayoutAlign="center center" style="position: relative;">
        <a mat-button class="send-contact-email-btn" (click)="sendContactEmail()">Enviar</a>
        <div class="sucess-contact-div"><span *ngIf="isToShowContactEmailSucess" class="sucess-add-news-letter">Seu
            e-mail foi enviado com sucesso!</span></div>
      </div>

    </div>

  </div>