import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "../api.service";
import { lastValueFrom } from 'rxjs';
import {
    Banner
  } from "../interface";

@Component({
    selector: "banner",
    templateUrl: "./banner.component.html",
    styleUrls: ["./banner.component.css"],
  })
export class BannerComponent implements OnInit {
    @Input() isHomePage?: boolean;
    @Input() categoryId?: string;
    @Input() businessId?: string;

    public banners: Banner[];

    constructor(
        private apiService: ApiService,) {}
 
    async ngOnInit() {
        this.getBanners();
    }

    getBanners() {
        lastValueFrom(this.apiService
            .list_banners(this.isHomePage, this.categoryId, this.businessId))
            .then((result) => {
            this.banners = result
        })
    }

    public openExternalLink(externalLink: string) {
        window.open(externalLink, "_blank");
      }

}