import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { RouteStateService } from '../route.state.service'
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from '../api.service';
import { TCAndPPDialogForUserAccepetance } from '../tc_and_pp_dialog_for_user_acceptance/tc_and_pp_dialog_for_user_acceptance'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GeneralDialog } from '../general_dialog/general_dialog';
import { LoginErroDialog } from '../login/login_error_dialog'
import { UserStatus } from '../interface'
import { GoogleAnalyticsService } from '../google-analytics-service';

@Component({
  template: '<div></div>'
})
export class SocialLoginComponent implements OnInit {

  private category_id: any;
  private searchType: any;
  private business_id: number;
  private searchName: string;
  private destroy = new Subject<void>();


  tcAndPPText: string;
  userChecks: any[]

  private hasToShowTCAndPP: any

  private userIsActive: any


  constructor(private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private routeStateService: RouteStateService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  async ngOnInit() {
    const email: string = this.activatedRoute.snapshot.paramMap.get('email');
    const provider: string = this.activatedRoute.snapshot.paramMap.get('provider');
    const active: string = this.activatedRoute.snapshot.paramMap.get('active');
    this.category_id = +localStorage.getItem('category_id') || null;
    this.searchType = localStorage.getItem('searchType') || null;
    this.searchName = localStorage.getItem('searchName') || null;
    this.business_id = +localStorage.getItem('business_id') || null;

    //TODO for some reason, localStorage is not working anymore for this
    this.category_id = this.category_id ? this.category_id : this.activatedRoute.snapshot.paramMap.get('category_id')
    this.searchType = this.searchType ? this.searchType : this.activatedRoute.snapshot.paramMap.get('searchType')
    this.searchName = this.searchName ? this.searchName : this.activatedRoute.snapshot.paramMap.get('searchName')
    this.business_id = this.business_id ? this.business_id : +this.activatedRoute.snapshot.paramMap.get('business_id')


    const activeStatus = UserStatus[active]

    switch (provider) {
      case 'login_facebook':
      case 'login_google':      //login
      case 'login_instagram':      //login
        if (activeStatus == UserStatus.A) {
          this.verifyUserHasAcceptedTCAndPP(email, provider)
        } else {
          //TODO show reactivate dialog
          this.openConfirmationDialog(email)
        }
        break;
      case 'logout':     //forbidden
        this.logout()
        break;
      default:
        //TODO throwError
        break;
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  async login(email: string, provider: string, userChecks: any[]) {
    await this.authService.socialLogin(email, userChecks).subscribe(
      success => {
        localStorage.setItem('provider', provider);
        this.googleAnalyticsService.loginEvent(provider)
        //add params so it can be read in outside component
        this.activatedRoute.paramMap.pipe(
          map(paramMap => ({ business_id: this.business_id, category_id: this.category_id, searchType: this.searchType, searchName: this.searchName })),
          takeUntil(this.destroy)
        ).subscribe(routePathParam => {
          this.routeStateService.updatePathParamState(routePathParam)
        })
        if (this.business_id && this.searchType && this.searchName) {
          this.router.navigate(['evaluation', this.searchType, this.searchName, this.business_id])
        } else if (this.searchType && this.category_id) {
          this.router.navigate(['business_list', this.searchType, this.searchName, this.category_id])
        } else {
          this.router.navigate(['home'])
        }
      },
      error => {
        //TODO remove this error capture
        this.logout()
        throw new Error(error);
      }
    );
  }

  private async logout() {
    await this.authService.logout().subscribe(
      success => {
        localStorage.setItem('provider', null);
      },
      error => {
        this.authService.clean()
      })
    this.router.navigate(['home'])
  }

  private async verifyUserHasAcceptedTCAndPP(email, provider) {
    await await this.authService.verifyUserHasAcceptedTCAndPP(email).toPromise().then(success => {
      this.hasToShowTCAndPP = success[0]
    },
      error => {
        this.logout()
        throw new Error(error);
      });
    if (this.hasToShowTCAndPP) {
      if (this.hasToShowTCAndPP.hasToShowTCAndPP) {
        await this.getTCAndPPText()
        await this.getTCAndPPUserChecksText()
        this.showTCAndPPDialogForUserAccepetance(email, provider)
      } else {
        await this.login(email, provider, [])
      }
    }
    /*
    this.authService.verifyUserHasAcceptedTCAndPP(email).subscribe(
     success => {
       console.log("$$$$$$$$$$$$$$$$$$ verifyUserHasAcceptedTCAndPP success ", success)
       let hasToShowTCAndPP = success[0].hasToShowTCAndPP
       if (hasToShowTCAndPP){
         await this.getTCAndPPText()
         await this.getTCAndPPUserChecksText()
         this.showTCAndPPDialog(email, provider)
       } else {
         await this.login(email, provider, [])
       }
     },
     error => {
 
     })*/
  }

  private showTCAndPPDialogForUserAccepetance(email, provider) {
    const dialogRef = this.dialog.open(TCAndPPDialogForUserAccepetance, {
      width: '700px',
      height: '700px',
      data: {
        userChecks: this.userChecks,
        tcAndPPText: this.tcAndPPText
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.userAgreed) {
          let userChecksDB = []
          for (var uc of result.userChecks) {
            userChecksDB.push({
              'acceptanceText': uc.id,
              'checked': uc.userCheckChoice
            })
          }
          this.login(email, provider, userChecksDB)
        } else {
          this.router.navigate(['home'])
          this.showUserDisagreedMessage()
        }
      } else {
        this.router.navigate(['home'])
        this.showUserDisagreedMessage()
      }
    });
  }

  async getTCAndPPText() {
    await this.apiService.get_tc_and_pp_text().toPromise().then(result => {
      this.tcAndPPText = result[0].content
    });
  }

  async getTCAndPPUserChecksText() {
    await this.apiService.get_tc_and_pp_user_checks_text().toPromise().then(result => {
      this.userChecks = result
      for (var userCheck of this.userChecks) {
        userCheck.userCheckChoice = false;
      }
    });
  }

  private showUserDisagreedMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Você precisa concordar com os Termos de Uso e Política de Privacidade para que sua conta seja criada.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  openConfirmationDialog(email) {
    const dialogRef = this.dialog.open(GeneralDialog, {
      width: '550px',
      height: '150px',
      data: {
        title: 'Esta conta está DESATIVADA',
        message: 'Deseja reativar esta conta?'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == 'ok') {
        await this.activateUser(email)
      } else {
        this.showUserAccountIsNotActivatedAgainMessage()
        this.router.navigate(['home'])
      }
    });
  }

  async activateUser(email) {
    this.authService.activateUser(email).toPromise().then(
      success => {
        if (success.code) {
          this.showUserActivationFailMessage()
          this.router.navigate(['login']);
        } else {
          this.showUserAskUserTLoginAgainMessage()
          this.router.navigate(['login']);
        }
      },
      error => {
        this.logout()
        throw new Error("Erro desconhecido");
      })
  }

  private showUserAskUserTLoginAgainMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Sua conta foi reativada, por favor, realize o login novamente.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showUserAccountIsNotActivatedAgainMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Para poder realizar este login você deve reativar sua conta', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showUserActivationFailMessage() {
    let horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    let verticalPosition: MatSnackBarVerticalPosition = 'top';
    this._snackBar.open('Houve algum problema ao reativar sua conta, por favor, tente novamente.', 'Fechar', {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  private showLoginErroDialog(title, msg, isToShowResentEmailConfirmation) {
    const dialogRef = this.dialog.open(LoginErroDialog, {
      width: '550px',
      height: '250px',
      data: {
        title: title,
        message: msg,
        email: "",
        isToShowResentEmailConfirmation: isToShowResentEmailConfirmation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isEmailSent) {
        //this.showSuccessEmailConfirmationNotification()
      }
    });
  }


}
