<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title>Trocar senha</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="myForm">
      <mat-card-content>


        <mat-form-field class="example-full-width">
            <input matInput placeholder="Senha" formControlName="password"
               [errorStateMatcher]="crossMatcher"  type='password'>
            <mat-error *ngIf="myForm.get('password').hasError('required')">
                Senha é <strong>obrigatória</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input matInput placeholder="Confirmar Senha" formControlName="confirmPassword"
               [errorStateMatcher]="crossMatcher"  type='password'>
               <mat-error   *ngIf="myForm.hasError('mismatch') && !myForm.get('confirmPassword').hasError('required')">
                   Senha incompatível
               </mat-error>
                <mat-error *ngIf="myForm.get('confirmPassword').hasError('required')">
                Confirmar Senha é <strong>obrigatória</strong>
              </mat-error>

        </mat-form-field>

        </mat-card-content>
        <button class="btn-block" mat-raised-button color="primary" (click)="resetPassword()">Trocar senha</button>

    </form>
  </mat-card>

</div>
