import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.service';

import { EvaluationComponent } from './evaluation/evaluation.component';
import { LoginComponent } from './login/login.component';
import { SocialLoginComponent } from './login/social_login.component';
import { SignupComponent } from './signup/signup.component';
import { BusinessListComponent } from './business_list/business_list.component';
import { SearchComponent } from './search/search.component';
import { BusinessEvaluationResultComponent } from './business_evaluation_result/business_evaluation_result.component';
import { SignupEmailConfirmationComponent } from './signup/signup_email_confirmation.component'
import { ResetPasswordConfirm } from './login/reset_password_confirm';
import { Home } from './home/home';
import { UserProfile } from './user_profile/user_profile'
import { FAQ } from './faq/faq'
import { ExpertsPage } from './experts_page/experts_page.component'
import { UsersRankingPage } from './users_ranking/users_ranking'
import { TCAndPPDialogSimpleScreen } from './tc_and_pp_simple_screen/tc_and_pp_simple_screen'
import { SearchPage } from './search_page/search_page'

import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:email_confirmed', component: LoginComponent },
  { path: 'password-reset/confirm/:uid/:token', component: ResetPasswordConfirm },
  { path: 'signup', component: SignupComponent },
  { path: 'signup_email_confirmation', component: SignupEmailConfirmationComponent },
  { path: 'business_list/:searchType/:search_name/:category_id', component: BusinessListComponent },
  { path: 'evaluation/:searchType/:search_name/:business_id', component: EvaluationComponent, canActivate: [AuthGuard] },
  { path: 'evaluation2/:searchType/:search_name/:business_id', component: EvaluationComponent, canActivate: [AuthGuard] },
  { path: 'evaluation', component: EvaluationComponent, canActivate: [AuthGuard] },
  { path: 'business_evaluation_result/:searchType/:search_name/:business_id', component: BusinessEvaluationResultComponent },
  { path: 'social_login/:provider/:email/:active', component: SocialLoginComponent },
  { path: 'home', component: Home },
  { path: 'user_profile', component: UserProfile, canActivate: [AuthGuard] },
  { path: 'faq', component: FAQ },
  { path: 'tc_and_pp', component: TCAndPPDialogSimpleScreen },
  { path: 'search_page/:type/:id/:search_field', component: SearchPage },
  { path: 'search_page2/:type/:id/:search_field', component: SearchPage },
  { path: 'experts', component: ExpertsPage },
  { path: '', component: Home },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule]
})

export class AppRoutingModule { }
