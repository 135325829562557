import { Component, ViewChild } from '@angular/core';
import { Tag, FintechTagType, SearchType, Business } from '../interface'
import { Observable, throwError, Subject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { startWith, map, catchError, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { RouteStateService } from '../route.state.service'
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../google-analytics-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormsPopUp } from '../forms_pop_up/forms_pop_up';

@Component({
  selector: 'app-root',
  templateUrl: './home_tags.html',
  styleUrls: ['./home_tags.css']
})
export class HomeTags {

  filteredTagsForUser: Observable<Tag[]>;
  private allTagsForUser: Tag[];

  filteredTagsForBusiness: Observable<Tag[]>;
  private allTagsForBusiness: Tag[]

  filteredBusinessByName: Observable<Business[]>;
  private allBusiness: Business[];

  isTagForUser = false

  isOpen: boolean;

  tagsToShow: Tag[]

  tagsSliceSize: number

  fintechSliceSize: number;

  fintechsByTags: any;

  fintechByName: any;

  relatedFintechsToFintechByName: any;

  hover: boolean;

  chosenTag: any;

  private destroy = new Subject<void>();

  search_Tag: string

  private business_id: any;
  private category_id: any;
  private searchType: any;
  private searchName: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private routeStateService: RouteStateService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private formsPopUp: MatBottomSheet) {
    this.business_id = localStorage.getItem('business_id');
    this.category_id = localStorage.getItem('category_id');
    this.searchType = localStorage.getItem('searchType');
    this.searchName = localStorage.getItem('searchName');

  }

  async ngOnInit() {
    this.isOpen = false
    this.tagsSliceSize = 10
    this.fintechSliceSize = 10
    await this.getTagsForUser()
    await this.getTagsForBusiness()
    await this.getBusiness()
    this.defineFiltersForTags()
    if (this.searchType && this.searchType == SearchType.Tag) {
      if (this.searchName) {
        this.search_Tag = this.searchName
        await this.apiService.get_tag_by_name(this.searchName).toPromise().then(result => {
          this.chosenTag = result[0]
          this.search_Tag = this.chosenTag.name
          this.populateListBySelectedTag(this.chosenTag.id)
        });
      }
    } else if (this.searchType == SearchType.Fintech) {
      if (this.searchName) {
        this.search_Tag = this.searchName
        await this.populateListBySelectedFintech(this.business_id)
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  displayFn(tag?: Tag): string | undefined {
    return tag ? tag.name : undefined;
  }

  searchForm = this.formBuilder.group({
    search: [null, null]
  });

  get search() {
    return this.searchForm.get('search');
  }

  private defineFiltersForTags() {
    this.filteredTagsForUser = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterTagsForUser(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );

    this.filteredTagsForBusiness = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterTagsForBusiness(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );

    this.filteredBusinessByName = this.search.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterBusinessByName(value)),
        catchError(error => {
          return throwError(error.statusText);
        })
      );
  }

  private filterTagsForUser(value: string | Tag): Tag[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allTagsForUser.filter(tag => {
        return tag.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allTagsForUser;
    }
  }

  private filterBusinessByName(value: string | Business): Business[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allBusiness.filter(business => {
        return business.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allBusiness;
    }
  }

  private filterTagsForBusiness(value: string | Tag): Tag[] {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allTagsForBusiness.filter(tag => {
        return tag.name.toLowerCase().includes(filterValue)
      })
    } else {
      return this.allTagsForBusiness;
    }
  }

  async getTagsForUser() {
    //TODO needs to get the key value from the enum
    await this.apiService.list_tags_by_type("P").toPromise().then(result => {
      this.allTagsForUser = result
    });
  }

  async getBusiness() {
    await this.apiService.list_business().toPromise().then(result => {
      this.allBusiness = result;
    });
  }

  async getTagsForBusiness() {
    //TODO needs to get the key value from the enum
    await this.apiService.list_tags_by_type("B").toPromise().then(result => {
      this.allTagsForBusiness = result
    });
  }

  showTagsForUser() {
    this.isOpen = true
    this.tagsToShow = this.allTagsForUser
    this.isTagForUser = true
  }

  showTagsForBusiness() {
    this.isOpen = true
    this.tagsToShow = this.allTagsForBusiness
    this.isTagForUser = false
  }

  async populateListByAutoComplete(obj) {
    //TODO improve this, it is verifying if it is a Tag or a Fintech by the presence of type attribute in Tag
    //send the object type from the screen
    if (obj.type) {
      //TODO remove this and put in a method to use here and populateListByTagList
      await this.setTagChosen(obj)
    } else {
      await this.setFintechChosen(obj)
    }
  }

  async populateListByTagList(tag) {
    this.search_Tag = undefined
    this.setTagChosen(tag)
  }

  async setTagChosen(tag) {
    this.fintechByName = null
    this.relatedFintechsToFintechByName = null
    this.populateListBySelectedTag(tag.id)
    this.chosenTag = tag
    this.updateTagAccess(this.chosenTag)
    this.googleAnalyticsService.searchByTagEvent(tag.name)
  }

  async setFintechChosen(obj) {
    this.fintechsByTags = null;
    this.chosenTag = null;
    this.populateListBySelectedFintech(obj.id);
    this.googleAnalyticsService.searchByFintechEvent(obj.name)
  }

  async populateListBySelectedTag(tag_id) {
    await this.apiService.businessGradeList(tag_id).toPromise().then(result => {
      this.fintechsByTags = result
    });
  }

  async populateListBySelectedFintech(business_id) {
  
    await this.apiService.businessGradeAndRelatedBusinessListGradeSet(business_id).toPromise().then(result => {
      this.relatedFintechsToFintechByName = result
      this.relatedFintechsToFintechByName.forEach((item, index) => {
        if (item.business.id == business_id) {
          this.fintechByName = item
          this.relatedFintechsToFintechByName.splice(index, 1)
        };
      });
    });
  }

  addItems() {
    this.tagsSliceSize += 10
  }

  isToShowAddTagBtn() {
    return !(this.tagsToShow && this.tagsSliceSize >= this.tagsToShow.length)
  }

  tagItemColor() {
    return this.isTagForUser ? "tag-items-user" : "tag-items-business"
  }

  addFintechs() {
    this.fintechSliceSize += 10
  }

  isToShowAddBtn() {
    if (!this.fintechsByTags) {
      return false
    } else if (this.fintechSliceSize < this.fintechsByTags.length) {
      return true
    }
  }

  decidePositionColor(position) {
    if (position == 1) {
      return "first-position-color"
    } else if (position == 2) {
      return "second-position-color"
    } else if (position == 2) {
      return "third-position-color"
    } else {
      return "other-position-color"
    }
  }

  getFintechTagsNames(fintechTags): string {
    let text = 'Tags: '
    for (var i = 0; i < fintechTags.length; i++) {
      text += fintechTags[i].name
      if (i < (fintechTags.length - 1)) {
        text += ", "
      }
    }
    return text
  }

  evaluationResult(business) {
    let searchType = null;
    let searchName = null;
    if (this.chosenTag) {
      searchType = SearchType.Tag
      searchName = this.chosenTag.name
    } else {
      searchType = SearchType.Fintech
      searchName = business.name
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['business_evaluation_result', searchType, searchName, business.id])
    );
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: business.category_id, searchType: searchType, searchName: searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechPageResultsEvent(business.name);
    //TODO improve this, user maybe able to click in the button and open it directly on current tab or choose in context menu: Open New Tab
    window.open(url, "_blank");
    //this.router.navigate(['business_evaluation_result',SearchType.Tag, this.chosenTag.name, business.id])

  }

  evaluation(business) {
    let searchType = null;
    let searchName = null;
    if (this.chosenTag) {
      searchType = SearchType.Tag
      searchName = this.chosenTag.name
    } else {
      searchType = SearchType.Fintech
      searchName = business.name
    }
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: business.category_id, searchType: searchType, searchName: searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechEvaluationEvent(business.name);
    this.router.navigate(['evaluation', searchType, searchName, business.id])
  }

  updateTagAccess(tag) {
    tag.access = tag.access + 1
    this.apiService.update_tag_access(tag).toPromise().then(result => {
    });

  }

  openFormsPopUp(): void {
    this.formsPopUp.open(FormsPopUp);
  }
}
