import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  /* use for tests
  private async sleepExample() {
     //console.log("++++++ LoaderInterceptor Beforep: " + new Date().toString());
     // Sleep thread for 3 seconds
     await this.delay(100000000);
     //console.log("++++++ LoaderInterceptor Afterp:  " + new Date().toString());
   }
 
   private delay(ms: number) {
     return new Promise(resolve => setTimeout(resolve, ms));
   }*/

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //TODO improve this, this urls are not intercepeted by loader
    if (req.url.includes(this.loaderService.not_loading_tag) ||
      req.url.includes(this.loaderService.not_loading_get_faq) ||
      req.url.includes(this.loaderService.not_loading_get_wix_posts) ||
      req.url.includes(this.loaderService.not_loading_get_fincatch_data) ||
      req.url.includes(this.loaderService.not_loading_categorys)) {
      return next.handle(req)
    }
    this.requests.push(req);

    this.loaderService.isLoading.next(true);
    //this.sleepExample();
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
