import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { RouteStateService } from '../route.state.service'
import { Category, SearchType, Params } from '../interface'
import Utils from '../utils'
import { AlertService } from '../_alert';
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-list',
  templateUrl: './business_list.component.html',
  styleUrls: ['./business_list.component.css']
})
export class BusinessListComponent implements OnInit {

  private error: any;
  public category_id: any;
  public searchType: any;
  public $searchResult: any;
  public category: any;
  public searchTypeString: string;
  private subCategory: any;
  public searchName: string;
  public selected: any;
  public hover: boolean;

  private destroy = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private routeStateService: RouteStateService,
    private router: Router,
    private authService: AuthService,
    protected alertService: AlertService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {

    this.category_id = this.activatedRoute.snapshot.paramMap.get('category_id');
    this.searchType = this.activatedRoute.snapshot.paramMap.get('searchType');
    this.searchName = this.activatedRoute.snapshot.paramMap.get('search_name');
  }

  async ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Lista de fintechs por categoria - Fincatch');

    this.metaTagService.updateTag(
      { name: 'description', content: 'Lista de todas as fintechs da categoria escolhida.' }
    );
    this.alertService.success('Avaliação salva com sucesso!', { id: 'alert-success' })

    if (this.searchType == SearchType.Category.valueOf()) {
      this.searchTypeString = "da Categoria"
      await this.getCategory()
      await this.getOrderedBusinessByCategoryId()
    } else {
      throw new Error('An error occurred, search type is empty');
    }

    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ category_id: this.category_id, searchType: this.searchType, searchName: this.searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })

  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }

  async getCategory() {
    await this.apiService.list_categorys_with_subcategories(this.category_id).toPromise().then((result: any) => {
      this.category = result
    })
  }

  private processBusinessResult(result) {
    this.$searchResult = result;
    this.$searchResult = this.$searchResult.filter(obj => obj.business.isActive)
    this.$searchResult.forEach(element => {
      if (!element.business.logo_small.includes('http')) {
        element.business.logo_small = 'assets/' + element.business.logo_small
      }
    });
  }

  async getOrderedBusinessByCategoryId() {
    await this.apiService.businessGradeList(undefined, this.category_id).toPromise().then(result => {
      this.processBusinessResult(result)
    });
  }

  async getOrderedBusinessByProdyuctId() {
    await this.apiService.businessGradeList(null, this.category_id).toPromise().then(result => {
      this.processBusinessResult(result)
    });
  }

  async getBusinessSubCategoryById(subCategoryId) {
    await this.apiService.businessGradeList(undefined, undefined, subCategoryId).toPromise().then(result => {
      this.processBusinessResult(result)
    });
  }

  evaluation(business) {
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: this.category_id, searchType: this.searchType, searchName: this.searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechEvaluationEvent(business.name);
    this.router.navigate(['evaluation', this.searchType, this.searchName, business.id])
  }

  evaluationResult(business) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['business_evaluation_result', this.searchType, this.searchName, business.id])
    );
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: this.category_id, searchType: this.searchType, searchName: this.searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechPageResultsEvent(business.name);
    //TODO improve this, user maybe able to click in the button and open it directly on current tab or choose in context menu: Open New Tab
    window.open(url, "_blank");
    //this.router.navigate(['business_evaluation_result',this.searchType, this.searchName, businessId])
  }

  decideBtnColor(i) {
    if ((i + 1) % 4 === 0) {
      return 'orange'
    }
    if ((i + 1) % 3 === 0) {
      return 'green'
    }
    if ((i + 1) % 2 === 0) {
      return 'blue'
    }
    return 'pink'
  }

  decideRateColor(i) {
    if ((i + 1) % 4 === 0) {
      return 'orange_color'
    }
    if ((i + 1) % 3 === 0) {
      return 'green_color'
    }
    if ((i + 1) % 2 === 0) {
      return 'blue_color'
    }
    return 'pink_color'
  }

  public async onOptionsSelected(event) {
    const value = event.value;
    //TODO it may be better to have list in memory?
    if (!value) {
      await this.getOrderedBusinessByCategoryId()
    } else {
      this.googleAnalyticsService.chooseSubCategoryEvent(value.name);
      await this.getBusinessSubCategoryById(value.id)
    }
  }


  get subCategories() { return (this.category && this.category.subCategory && this.category.subCategory.length) ? this.category.subCategory : null }


}
