<div class='main-div' fxLayout="column" fxLayoutAlign="space-around center" >
<p class='font-desktop' ngClass.lt-sm='font-small'>Quer descobrir o que você precisa?</p>
<p class='font-desktop' ngClass.lt-sm='font-small'>Responda nosso formulário e receba um e-mail personalizado com as
  informações que você necessita!</p>
<div>
  <a ngClass.lt-sm="btn-lt-sm btn-for-user" mat-button class="btn btn-for-user" href="https://forms.gle/9J78MvfACQwY9ZN47" target="_blank">Para você</a>
  <a  ngClass.lt-sm="btn-lt-sm btn-for-business-lt-sm" mat-button class="btn btn-for-business" href="https://forms.gle/T82fDifM1WULV3Za7" target="_blank">Para seu negócio</a>

</div>
</div>
