import {Component} from '@angular/core';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-forms_pop_up',
  templateUrl: './forms_pop_up.html',
  styleUrls: ['./forms_pop_up.css']
})
export class FormsPopUp {
  constructor(private _formsPopUpRef: MatBottomSheetRef<FormsPopUp>) {}

  openLink(event: MouseEvent): void {
      this._formsPopUpRef.dismiss();
      event.preventDefault();
    }

}
