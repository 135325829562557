<div class="div-request-budget-card" ngClass.lt-sm="div-request-budget-sm" fxLayout="column" fxLayoutAlign="space-between start" >
    <div>
      <span class="request-budget-title">Solicitar Orçamento</span>
    </div>
    <div>
    <mat-form-field appearance="outline" class="form-field-contact-full-width request-budget-name-padding">
      <div class="request-budget-label-text-div">
        <span class="request-budget-label-text">Nome</span>
      </div>
      <input class="input-text" type="text"  placeholder="Ex. Ana Maria" matInput [formControl]="usernameFormControl" [errorStateMatcher]="matcher">
      <mat-error class="font-family" *ngIf="usernameFormControl.hasError('required')">
        Nome é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-field-contact-full-width">
      <div class="request-budget-label-text-div">
        <span class="request-budget-label-text">Email</span>
      </div>
      <input class="input-text" type="email"  placeholder="Ex. ana@seuemail.com.br" matInput [formControl]="contactFormControl" [errorStateMatcher]="matcher">
      <mat-error class="font-family" *ngIf="contactFormControl.hasError('email') && !contactFormControl.hasError('required')">
          Email inválido
      </mat-error>
      <mat-error class="font-family" *ngIf="contactFormControl.hasError('required')">
        E-mail é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-field-contact-full-width">
      <div class="request-budget-label-text-div">
        <span class="request-budget-label-text">Mensagem</span>
      </div>
      <textarea class="request-budget-message-input" type="text"  placeholder="Ex. Olá, gostaria de solicitar um orçamento." matInput [formControl]="messageFormControl" [errorStateMatcher]="matcher"></textarea>
      <mat-error class="font-family" *ngIf="messageFormControl.hasError('required')">
        Mensagem é <strong>obrigatório</strong>
      </mat-error>
    </mat-form-field>

    <div class="send-request-budget-div" fxLayout="column" fxLayoutAlign="center center" style="position: relative;">
      <a mat-button class="send-request-budget-email-btn" (click)="sendRequestBudgetEmail()">Solicitar Orçamento</a>
      <a mat-button class="send-request-budget-cancel-btn" (click)="onCancelClick()">Cancelar</a>
      <div class="sucess-request-budget-div" ><span *ngIf="isToShowRequestBudgetSucess" class="sucess-request-budget">Sua solicitação foi enviada com sucesso!</span></div>
    </div>

  </div>

</div>