import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-email-confirmation',
  templateUrl: './signup_email_confirmation.component.html',

})
export class SignupEmailConfirmationComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
    setTimeout(() =>
    {
      this.router.navigate(['home']);
    },
    15000);
  }

}
