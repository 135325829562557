import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { SearchType, Category } from '../interface'


export interface DialogData {
  categories :  any[],
  businessByCategory: any[]
}

@Component({
  selector: 'search_dialog',
  templateUrl: 'search_dialog.component.html',
  styleUrls: ['./search_dialog.component.css']
})
export class SearchDialog {

  private $allCategorys: Category[];
  public $filteredCategorys: Observable<Category[]>;

  private businessByCategory: any[];
  public filteredBusinessByCategory: any[]
  public search_business: any[]
  public search_subCategory: any[]

   constructor(
    public dialogRef: MatDialogRef<SearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService) {}

 async ngOnInit() {

      this.$allCategorys = this.data.categories
      this.businessByCategory = this.data.businessByCategory

      this.$filteredCategorys = this.search.valueChanges
       .pipe(
         startWith(''),
         map(value => this.filterCategorys(value))
       );
  }

  private filterCategorys(value: string | Category): Category[] {
   let filterValue = '';
   if (value) {
     filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
     return this.$allCategorys.filter(category => category.name.toLowerCase().includes(filterValue))
       //map(category => category.filter(category => category.name.toLowerCase().includes(filterValue))
     //);
   } else {
     return this.$allCategorys;
   }
 }

 public displayFn(category?: Category): string | undefined {
   return category ? category.name : undefined;
 }

 searchForm = this.formBuilder.group({
   search: [null, null]
 });

 get search() {
    return this.searchForm.get('search');
  }

 updateList(obj) {
   this.search_business = undefined
   const value = obj.option.value
   if (value) {
     const filterValue = value.id
     this.filteredBusinessByCategory = this.businessByCategory.filter(category => {
       return category.id == filterValue
     })
   } else {
     this.filteredBusinessByCategory = null
   }
 }

 searchBusiness(business){
   this.search_subCategory = undefined
   this.filteredBusinessByCategory = null
   let filterValue = typeof business === 'string' ? business.toLowerCase() : business.toLowerCase();
   let filter = this.businessByCategory.filter((category) => {
      let filteredBusiness = category.business.filter((business)=>{
         if(business.name.toLowerCase().includes(filterValue)){
           return business
         }
      })
      if(filteredBusiness.length>0){
        return filteredBusiness;
      }
    });
    let filterSecondLevel = this.businessByCategory.map((category)=>{
        return {
          id: category.id,
          name: category.name,
          business: category.business.filter((business) => business.name.toLowerCase().includes(filterValue))
        }
      })

   this.filteredBusinessByCategory = filterSecondLevel

 }

  onNoClick() {
    this.dialogRef.close();
  }



  goToPage(business, category_id, searchName) {
    //console.log(">>>>>>>> SearchDialog goToPage business", business)
    //  console.log(">>>>>>>> SearchDialog goToPage category_id", category_id)
    this.dialogRef.close({ business_id: business.id, category_id: category_id, searchType: SearchType.Category, searchName: searchName } );
  }

}
