<banner *ngIf="businessGradeInfo" businessId={{businessGradeInfo.business.id}}></banner>


<div class="business-info" fxLayout="column" *ngIf="businessGradeInfo">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-around center">
    <div fxLayout="column" fxLayoutAlign="center start">
      <img class="header-image" src='{{ businessGradeInfo.business.logo }}' />
      <div *ngIf="isToShowFintechSocialMedia && businessGradeInfoHasSocial" fxLayout="row"
        fxLayoutAlign="space-around center" class="social_media_div">
        <a *ngIf="businessGradeInfo.business.instagram" class="social-padding"
          (click)="onInstagramClick(businessGradeInfo.business.instagram)"><img
            src="assets/fincatch/instagram.svg" /></a>
        <a *ngIf="businessGradeInfo.business.twitter" class="social-padding"
          (click)="onTwitterClick(businessGradeInfo.business.twitter)"><img src="assets/fincatch/twitter.svg" /></a>
        <a *ngIf="businessGradeInfo.business.facebook" class="social-padding"
          (click)="onFacebookClick(businessGradeInfo.business.facebook)"><img src="assets/fincatch/facebook.svg" /></a>
        <a *ngIf="businessGradeInfo.business.youtube" class="social-padding"
          (click)="onYoutubeClick(businessGradeInfo.business.youtube)"><img src="assets/fincatch/youtube.svg" /></a>
        <a *ngIf="businessGradeInfo.business.linkedin" class="social-padding"
          (click)="onLinkdinClick(businessGradeInfo.business.linkedin)"><img src="assets/fincatch/linkedin.svg" /></a>
      </div>
    </div>
    <div ngClass.gt-md="business-name-desc" ngClass.lt-md="business-name-desc_lt-md" fxLayout="column"
      fxLayoutAlign="center start" fxLayoutAlign.lt-sm="space-between center">
      <h1 class="business-name">{{businessGradeInfo.business.name}}</h1>
      <h2 class="business-description" ngClass.lt-sm="business-description_lt-sm">
        {{businessGradeInfo.business.description}}</h2>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
        <a *ngIf="businessGradeInfo.business.external_link && businessGradeInfo.business.external_link.length > 0"
          mat-button class="btn-go-to-business-site"
          (click)="openExternalLink(businessGradeInfo.business.external_link[0].link)">
          Conheça esta empresa!</a>
        <div ngClass.gt-sm="btn-evaluation-div" ngClass.lt-sm="btn-evaluation-div_lt-sm" fxLayoutAlign="center center">
          <a class="evaluation" mat-button class="btn-evaluation" (click)="evaluation()">
            Faça sua avaliação!</a>
        </div>
      </div>

    </div>

  </div>

  <div ngClass.xs="tag1-info1-div-xs" ngClass.sm="tag1-info1-div-sm" class="tag-info-div" fxLayout.xs="column"
    fxLayoutAlign.xs="start">
    <div fxLayout.xs="row" fxLayoutAlign.xs="center center">
      <span class="font-family"><i>Categoria:</i> &nbsp;<span
          class="tag-info">{{businessGradeInfo.categoryName}}</span></span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="center center">
      <span ngClass.xs="related_tags"> <i>Tags relacionadas: </i>&nbsp;</span>
      <div class="tags_wrapper">
        <div *ngFor="let tag of this.businessGradeInfo.business.fintechTags">
          <a class="tag-info tag-info_tag" ngClass.xs="tag-info_lt_sm" (click)="goToTagSearchPage(tag)"
            target="_blank">[{{tag.name}}]</a>
        </div>
      </div>
    </div>
  </div>


  <div class="hr-padding" ngClass.lt-sm="hr-padding_lt-sm">
    <hr />
  </div>

</div>

<div fxLayout="column" *ngIf="businessGradeInfo && businessGradeInfoHasInformation" class="infos_div"
  ngClass.lt-sm="infos_div_lt-sm">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center">
    <div *ngIf="businessGradeInfo.business.employees_range" fxLayout="column" fxLayoutAlign="space-between center">
      <img src="assets/fincatch/icons8-team-64.png" />
      <span class="infos">{{businessGradeInfo.business.employees_range}} funcionários</span>
    </div>
    <div ngClass.lt-sm="info_spacing" *ngIf="businessGradeInfo.business.city || businessGradeInfo.business.state"
      fxLayout="column" fxLayoutAlign="space-between center">
      <img src="assets/fincatch/icons8-map-64(1).png" />
      <span class="infos">{{businessGradeInfo.business.city}} {{businessGradeInfo.business.state}}</span>
    </div>
    <div ngClass.lt-sm="info_spacing" *ngIf="businessGradeInfo.business.foundation" fxLayout="column"
      fxLayoutAlign="space-between center">
      <img src="assets/fincatch/icons8-calendar-64.png" />
      <span class="infos">{{businessGradeInfo.business.foundation}}</span>
    </div>
    <div ngClass.lt-sm="info_spacing" *ngIf="businessGradeInfo.business.acceleration" fxLayout="column"
      fxLayoutAlign="space-between center">
      <img src="assets/fincatch/icons8-rocket-64.png" />
      <span class="infos">{{businessGradeInfo.business.acceleration}}</span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center start"
    *ngIf="businessGradeInfo.business.what_offers || businessGradeInfo.business.what_offers_link"
    class="what_offers_div">
    <span class="what_offers_title">O que a {{businessGradeInfo.business.name}} oferece?</span>
    <span class="what_offers" ngClass.lt-sm="what_offers_lt-sm"
      *ngIf="businessGradeInfo.business.what_offers || businessGradeInfo.business.what_offers_link">{{businessGradeInfo.business.what_offers}}<span
        class="what_offers" ngClass.lt-sm="what_offers_lt-sm" *ngIf="businessGradeInfo.business.what_offers_link">-
      </span><a class="what_offers what_offers_link" ngClass.lt-sm="what_offers_lt-sm"
        *ngIf="businessGradeInfo.business.what_offers_link"
        (click)="onWhatOffersLinkClick(businessGradeInfo.business.what_offers_link)">{{businessGradeInfo.business.what_offers_link}}</a></span>

  </div>
</div>
<div class="hr-padding" *ngIf="businessGradeInfoHasInformation">
  <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 5px;">
    <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" target="_blank"
      href="https://icons8.com/icon/klCcEAOoz0iF/calendar">[Calendar </a> <span class="iconsByIcon8"
      ngClass.lt-sm="iconsByIcon8_lt-sm"> icon
      by </span><a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" target="_blank" href="https://icons8.com">
      Icons8]</a>
    <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" target="_blank"
      href="https://icons8.com/icon/YIMggPaPu63i/team">[Team </a> <span class="iconsByIcon8"
      ngClass.lt-sm="iconsByIcon8_lt-sm"> icon
      by </span><a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" ngClass.lt-sm="iconsByIcon8_lt-sm"
      target="_blank" href="https://icons8.com">Icons8]</a>
    <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" target="_blank"
      href="https://icons8.com/icon/1XIvlZbzKpcI/rocket">[Rocket </a> <span class="iconsByIcon8"
      ngClass.lt-sm="iconsByIcon8_lt-sm"> icon
      by </span> <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" ngClass.lt-sm="iconsByIcon8_lt-sm"
      target="_blank" href="https://icons8.com">Icons8]</a>
    <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" target="_blank"
      href="https://icons8.com/icon/B4tfcZqlPN77/map">[Map </a><span class="iconsByIcon8"
      ngClass.lt-sm="iconsByIcon8_lt-sm"> icon
      by </span> <a class="iconsByIcon8" ngClass.lt-sm="iconsByIcon8_lt-sm" ngClass.lt-sm="iconsByIcon8_lt-sm"
      target="_blank" href="https://icons8.com">Icons8]</a>
  </div>
  <hr />
</div>


<div *ngIf="businessGradeInfo && businessGradeInfo.totalEvaluations > 0">
  <div class=" bussiness-rating" fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutAlign="center center">
      <ng-template #t let-fill="fill">
        <span class="fas star material-icons " [class.full]="fill === 100">
          <span class="fas half material-icons"
            [ngClass]="{'half-question-red': businessGradeInfo.businessRate <= 2, 'half-question-yellow': businessGradeInfo.businessRate > 2 && businessGradeInfo.businessRate < 4, 'half-question-green': businessGradeInfo.businessRate >=4}"
            [style.width.%]="fill">&#9733;</span>&#9733;
        </span>
      </ng-template>

      <ngb-rating [(rate)]="businessGradeInfo.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

      <span
        [ngClass]="{'half-question-red': businessGradeInfo.businessRate <= 2, 'half-question-yellow': businessGradeInfo.businessRate > 2 && businessGradeInfo.businessRate < 4, 'half-question-green': businessGradeInfo.businessRate >=4}"
        class="rate">{{businessGradeInfo.businessRate}}</span>
    </div>
    <span *ngIf="businessGradeInfo.totalEvaluations > 1"
      class="avaliations-number"><b>{{businessGradeInfo.totalEvaluations}}</b> Avaliações </span>
    <span *ngIf="businessGradeInfo.totalEvaluations <= 1"
      class="avaliations-number"><b>{{businessGradeInfo.totalEvaluations}}</b> Avaliação </span>


  </div>
  <div *ngIf="businessGradeInfo.expertsEvaluation || businessGradeInfo.scannerStartupsEvaluation" fxLayout="column"
    fxLayoutAlign="center center">
    <div class="hr-padding-experts">
      <hr />
    </div>
    <div fxLayout.lt-sm="column" fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="businessGradeInfo.expertsEvaluation" class=" bussiness-rating" fxLayout="column"
        fxLayoutAlign="center center">
        <div>
          <ng-template #t let-fill="fill">
            <span class="fas star-experts material-icons" [class.full]="fill === 100">
              <span class="fas half-experts material-icons"
                [ngClass]="{'half-question-red': businessGradeInfo.expertsEvaluation <= 2, 'half-question-yellow': businessGradeInfo.expertsEvaluation > 2 && businessGradeInfo.expertsEvaluation < 4, 'half-question-green': businessGradeInfo.expertsEvaluation >=4}"
                [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>


          <ngb-rating [(rate)]="businessGradeInfo.expertsEvaluation" [starTemplate]="t" [readonly]="true" max="5">
          </ngb-rating>
          <span
            [ngClass]="{'half-question-red': businessGradeInfo.expertsEvaluation <= 2, 'half-question-yellow': businessGradeInfo.expertsEvaluation > 2 && businessGradeInfo.expertsEvaluation < 4, 'half-question-green': businessGradeInfo.expertsEvaluation >=4}"
            class="rate-experts">{{businessGradeInfo.expertsEvaluation}}</span>
        </div>
        <span class="avaliations-experts-number">Avaliação dos especialistas</span>
        <div (mouseover)="hover=true" (mouseleave)="hover=false">
          <a class="know-more" (click)="goToExpertsPage()" target="_blank"
            [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Saiba mais</a>
        </div>
      </div>

      <div *ngIf="businessGradeInfo.scannerStartupsEvaluation" ngClass.gt-sm="bussiness-rating_space_between"
        class="bussiness-rating" fxLayout="column" fxLayoutAlign="center center">
        <div>
          <ng-template #t let-fill="fill">
            <span class="fas star-experts material-icons" [class.full]="fill === 100">
              <span class="fas half-experts material-icons"
                [ngClass]="{'half-question-red': businessGradeInfo.scannerStartupsEvaluation <= 2, 'half-question-yellow': businessGradeInfo.scannerStartupsEvaluation > 2 && businessGradeInfo.scannerStartupsEvaluation < 4, 'half-question-green': businessGradeInfo.scannerStartupsEvaluation >=4}"
                [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>


          <ngb-rating [(rate)]="businessGradeInfo.scannerStartupsEvaluation" [starTemplate]="t" [readonly]="true"
            max="5">
          </ngb-rating>
          <span
            [ngClass]="{'half-question-red': businessGradeInfo.scannerStartupsEvaluation <= 2, 'half-question-yellow': businessGradeInfo.scannerStartupsEvaluation > 2 && businessGradeInfo.scannerStartupsEvaluation < 4, 'half-question-green': businessGradeInfo.scannerStartupsEvaluation >=4}"
            class="rate-experts">{{businessGradeInfo.scannerStartupsEvaluation}}</span>
        </div>
        <span class="avaliations-experts-number">Avaliação dos usuários Startup Scanner</span>
        <div (mouseover)="hover=true" (mouseleave)="hover=false">
          <a class="know-more" href="https://startupscanner.com/" target="_blank"
            [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Saiba mais</a>
        </div>
      </div>
    </div>
  </div>



  <div class="questionsTest">
    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="center none">
      <div fxFlex="1 0 33%" fxFlex.xs="1 0 100%" fxFlex.sm="1 0 40%"
        *ngFor="let q of businessGradeInfo.questionFullInfo">
        <div fxLayout="column" fxLayoutAlign="center center" class="tile-border ">
          <p class="question">{{q.questionText}}</p>
          <div *ngIf="!q.isRecomendationQuestion" fxLayout="row" fxLayoutAlign="center center">
            <ng-template #t let-fill="fill">
              <span class="fas star-question material-icons" [class.full]="fill === 100">
                <span class="fas half-question material-icons"
                  [ngClass]="{'half-question-red': q.rate <= 2, 'half-question-yellow': q.rate > 2 && q.rate < 4, 'half-question-green': q.rate >=4}"
                  [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>
            <ngb-rating [(rate)]="q.rate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
            <span class="rate-question"
              [ngClass]="{'half-question-red': q.rate <= 2, 'half-question-yellow': q.rate > 2 && q.rate < 4, 'half-question-green': q.rate >=4}">{{q.rate}}</span>
          </div>
          <div *ngIf="q.isRecomendationQuestion" fxLayout="row" fxLayoutAlign="center center">
            <ng-template #t let-fill="fill">
              <span class="fas star-question material-icons" [class.full]="fill === 100">
                <span class="fas half-question material-icons"
                  [ngClass]="{'half-question-red': q.rate <= 4, 'half-question-yellow': q.rate > 4 && q.rate < 8, 'half-question-green': q.rate >=8}"
                  [style.width.%]="fill">&#9733;</span>&#9733;
              </span>
            </ng-template>
            <ngb-rating [(rate)]="q.rate" [starTemplate]="t" [readonly]="true" max="10"></ngb-rating>
            <span class="rate-question"
              [ngClass]="{'half-question-red': q.rate <= 4, 'half-question-yellow': q.rate > 4 && q.rate < 8, 'half-question-green': q.rate >=8}">{{q.rate}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="no-comments" *ngIf="!usersComment || !usersComment.length">
      <hr />
    </div>
  </div>




  <div class="comments_section" *ngIf="usersComment && usersComment.length">
    <div fxLayout="column" fxLayoutAlign="start">
      <p class="comment_title" ngClass.gt-sm="comment_title_gt_sm">Comentários:</p>
      <div fxLayout="row wrap" fxLayout.lt-md="column wrap" fxLayoutGap="16px grid" fxLayoutAlign="center none">
        <div fxFlex="100%" *ngFor="let uc of usersComment" class="comment_item">
          <div fxLayout="row" fxLayoutAlign="center stretch" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center center">

            <div fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign="center center" ngClass.gt-sm="userInfo"
              ngClass.lt-md="userInfo_lt-sm" fxFlexOrder.lt-md="3">
              <div fxLayout="column" fxLayoutAlign="center center" ngClass.lt-xl="user-inf0-padding-md">
                <i class="material-icons md-36 ">account_circle</i>
                <b class="username">{{uc.userName}}</b>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <div *ngIf="uc.userIsVerified" fxLayout="row" fxLayoutAlign=" center"> <span
                    class="verified_user">Usuário verificado:</span><i class="material-icons green">done</i></div>
                <div fxLayout="row" fxLayoutAlign=" center"><span class="verified_user">Avaliação verificada:</span><i
                    class="material-icons green">done</i></div>
                <div fxLayout="row" fxLayoutAlign=" center"><span
                    class="verified_user">{{clientForHowLong[uc.userClientHowLong]}}</span></div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" fxFlexOrder.lt-md="2" style="position: relative;">
              <div class="text_box mat-elevation-z5" fxLayout="row" ngClass.lt-sm="text_box_lt-sm">
                <textarea class="text-area-size font-family" ngClass.lt-sm="text-area-size_lt-sm font-family"
                  disabled>{{uc.userCommentText}}</textarea>
                <div fxLayout="column" fxLayoutAlign="end end" class="comment-date-div">
                  <i class="comment_date"> {{uc.userCommentUpdatedAt | date:'dd/MM/yyyy'}}</i>
                </div>

              </div>
            </div>

            <div fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center"
              fxLayoutGap.lt-md="10px" class="gradeInfo" fxFlexOrder.lt-md="1">
              <div fxLayout="column" fxFlexOrder.lt-md="2">
                <span class="font-family" *ngIf="uc.businessRate <= 2">Nota:<span
                    class="grade_red">&nbsp;{{uc.businessRate}}</span>/5</span>
                <span class="font-family" *ngIf="uc.businessRate > 2 && uc.businessRate < 4">Nota:<span
                    class="grade_yellow">&nbsp;{{uc.businessRate}}</span>/5</span>
                <span class="font-family" *ngIf="uc.businessRate >= 4">Nota:<span
                    class="grade_green">&nbsp;{{uc.businessRate}}</span>/5</span>
                <span class="font-family" *ngIf="uc.recomandationRate <= 2">Recomendaria:<span
                    class="grade_red">&nbsp;{{uc.recomandationRate}}</span>/10</span>
                <span class="font-family"
                  *ngIf="uc.recomandationRate > 2 && uc.recomandationRate < 4">Recomendaria:<span
                    class="grade_yellow">&nbsp;{{uc.recomandationRate}}</span>/10</span>
                <span class="font-family" *ngIf="uc.recomandationRate >= 4">Recomendaria:<span
                    class="grade_green">&nbsp;{{uc.recomandationRate}}</span>/10</span>
              </div>
              <div fxLayout="column" fxLayoutAlign="space-around end" class="sentiment" fxFlexOrder.lt-md="1">
                <i *ngIf="isPositive(uc.userCommentSentimentAnalysis)"
                  class="material-icons green md-48">sentiment_satisfied_alt</i>
                <i *ngIf="isNegative(uc.userCommentSentimentAnalysis)"
                  class="material-icons red md-48">sentiment_very_dissatisfied</i>
                <i *ngIf="isNeutral(uc.userCommentSentimentAnalysis)"
                  class="material-icons gray md-48">sentiment_dissatisfied</i>
              </div>
            </div>

          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>

</div>




<div *ngIf="businessGradeInfo && businessGradeInfo.totalEvaluations == 0" class="bussiness-rating">
  <p class="ops center">Ops...essa empresa ainda não possui avaliações!</p>
  <div class="center">
    <a mat-button class="btn-evaluation" (click)="evaluation()">Seja o primeiro a avaliar esta empresa!</a>
  </div>
  <hr />
</div>