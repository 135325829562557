<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title class="signup-title">Cadastro</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="myForm">
      <mat-card-content>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Usuário" [formControl]="usernameFormControl"
               [errorStateMatcher]="matcher"  maxlength="8">
            <mat-error class="font-family" *ngIf="usernameFormControl.hasError('required')">
                Usuário é <strong>obrigatório</strong>
            </mat-error>
            <mat-error class="font-family" *ngIf="usernameFormControl.hasError('maxlength')">
                Tamanho máximo é de 8 caracteres
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="E-mail" [formControl]="emailFormControl"
               [errorStateMatcher]="matcher" >
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                E-mail inválido
            </mat-error>
            <mat-error class="font-family" *ngIf="emailFormControl.hasError('required')">
                E-mail é <strong>obrigatório</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" outline="outlined">
            <mat-label>Data de Nascimento</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="birthdayFormControl" [max]="today" autocomplete="off" >
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                  startView="multi-year"
                  panelClass="example-month-picker">
            </mat-datepicker>
            <mat-error class="font-family" *ngIf="birthdayFormControl.hasError('required')">
                Data de Nascimento é <strong>obrigatória</strong>
            </mat-error>
            <mat-error class="font-family" *ngIf="birthdayFormControl.hasError('underage')">
                É preciso ser maior de 18 anos para utilizar a Fincatch!
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width top_margin" appearance="outline" >
          <mat-label style="padding-left: 20px">Gênero</mat-label>
          <mat-select  class="select" (selectionChange)='onOptionsSelected($event)' [formControl]="sexFormControl">
          <mat-option class="font-family" *ngFor="let sex of enumKeys" [value]="sex">
            {{sexChoices[sex]}}
           </mat-option>
          </mat-select>
          <mat-error class="font-family" class="font-family" *ngIf="sexFormControl.hasError('required')">
            Gênero é <strong>obrigatório</strong>
          </mat-error>

        </mat-form-field>

    <!--  <form [formGroup]="myForm">-->
        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Senha" formControlName="password"
               [errorStateMatcher]="crossMatcher"  type='password'>
            <mat-error class="font-family" *ngIf="myForm.get('password').hasError('required')">
                Senha é <strong>obrigatória</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <input matInput placeholder="Confirmar Senha" formControlName="confirmPassword"
               [errorStateMatcher]="crossMatcher"  type='password'>
               <mat-error class="font-family" *ngIf="myForm.hasError('mismatch') && !myForm.get('confirmPassword').hasError('required')">
                   Senha incompatível
               </mat-error>
                <mat-error class="font-family" *ngIf="myForm.get('confirmPassword').hasError('required')">
                Confirmar Senha é <strong>obrigatória</strong>
              </mat-error>

        </mat-form-field>
    <!--  </form> -->

        </mat-card-content>
        <button class="btn-block" mat-raised-button color="primary" (click)="signup()">Cadastrar</button>
        <a mat-raised-button href="{{facebookLoginLink}}"  class="btn btn-block btn-social btn-facebook" style="background-color:#3b5998;text-align:center">
          <img src="https://img.icons8.com/fluent/24/000000/facebook-new.png"/>&nbsp;Entrar com Facebook
        </a>

        <a mat-raised-button href="{{googleLoginLink}}" class="btn btn-block btn-social btn-google"
         style="background-color:#FFFFFF;text-align:center">
           <img src="https://img.icons8.com/cute-clipart/24/000000/google-logo.png"/>&nbsp;Entrar com Google
        </a>


    </form>
  </mat-card>

</div>
