
<div class="main_div">
  <div class="row">
    <div class="col-8">
      <img  class="image" src="assets/fincatch/logotipo_fincatch_fundo_escuro.png" alt="Fincatch">
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <form  [formGroup]="searchForm"  >
        <div class="center_vertical div-autocomplete">
          <mat-icon class="fas fa-search"  ></mat-icon>
          <input class="category-form" type="text"  placeholder="Busque categorias ou produtos" matInput formControlName="search" [matAutocomplete]="autoDialog" >
        </div>

          <mat-autocomplete #autoDialog="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="goToPage($event)">
            <mat-optgroup *ngIf="$filteredCategorys">-----Categorias
              <mat-option *ngFor="let search of $filteredCategorys | async" [value]="search">
                {{search.name}}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>

      </form>
    </div>
  </div>
  <div class="row text">
    <div class="col-8">
      <h1 class="title">Pesquise a categoria financeira que deseja e encontre as empresas mais bem conceituadas!</h1>
      <h3 class="sub-title">Temos diversas categorias do setor financeiro para que você possa encontrar o que deseja!</h3>
    </div>
  </div>
</div>
