import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SearchType } from '../interface';
import { map, takeUntil } from 'rxjs/operators';
import { RouteStateService } from '../route.state.service'
import { GoogleAnalyticsService } from '../google-analytics-service';
import { Subject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import Utils from '../utils';


@Component({
  selector: 'app-search-page',
  templateUrl: './search_page.html',
  styleUrls: ['./search_page.css']
})
export class SearchPage {

  public info: any;

  public selectedFintech: any;

  public isTagSearch: boolean;

  public fintechsList: any;

  private destroy = new Subject<void>();

  public hover: boolean;

  private search_field: string

  private search_field_id: string

  private search_field_type: string

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private routeStateService: RouteStateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private metaTagService: Meta) {

    this.search_field = this.activatedRoute.snapshot.paramMap.get('search_field');
    this.search_field_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.search_field_type = this.activatedRoute.snapshot.paramMap.get('type');

    let state = this.router.getCurrentNavigation().extras.state
    if (state) {
      this.info = state.info;
      if (this.info.type) {
        this.isTagSearch = true;
      } else {
        this.isTagSearch = false;
      }
    } else {
      if (this.search_field && this.search_field_id) {
        this.info = {
          id: this.search_field_id,
          name: this.search_field
        }
        this.isTagSearch = this.search_field_type === 'tag' ? true : false
      }
    }

  }

  async ngOnInit() {
    Utils.isStagingEnviroment() ? this.titleService.setTitle('Staging Fincatch') : this.titleService.setTitle('Lista de fintechs por tag(palavra-chave) ou nome - Fincatch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Lista de todas as fintechs relacionados com a tag(palavra-chave) escolhida ou pelo nome' }
    );
    if (this.info) {
      if (this.isTagSearch) {
        await this.getTagById()
        this.getFintechsByTag();
      } else {
        this.getFintechsByName();
      }
    } else {
      this.router.navigate(['home']);
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    //TODO have to null it at some point this.routeStateService.updatePathParamState(null);
  }


  getFintechTagsNames(fintechTags): string {
    let text = 'Tags: '
    for (var i = 0; i < fintechTags.length; i++) {
      text += fintechTags[i].name
      if (i < (fintechTags.length - 1)) {
        text += ", "
      }
    }
    return text
  }

  async getTagById() {
    await this.apiService.get_tag_by_id(this.info.id).toPromise().then(result => {
      this.info.name = result[0]['name']
    });
  }

  async getFintechsByTag() {
    await this.apiService.businessGradeList(this.info.id).toPromise().then(result => {
      this.fintechsList = result
      this.fintechsList = this.fintechsList.filter(obj => obj.business.isActive);
      this.fintechsList.forEach(element => {
        if (!element.business.logo_small.includes('http')) {
          element.business.logo_small = 'assets/' + element.business.logo_small
        }
      });
    });
  }

  async getFintechsByName() {
    await this.apiService.businessGradeAndRelatedBusinessListGradeSet(this.info.id).toPromise().then(result => {
      this.fintechsList = result
      this.fintechsList = this.fintechsList.filter(obj => obj.business.isActive);
      let indexToRemove;
      this.fintechsList.forEach((item, index) => {
        if (item.business.id == this.info.id) {
          this.selectedFintech = item
          indexToRemove = index
        };
        if (!item.business.logo_small.includes('http')) {
          item.business.logo_small = 'assets/' + item.business.logo_small
        }
      });
      this.fintechsList.splice(indexToRemove, 1)
    });
  }

  evaluationResult(business) {
    let searchType = null;
    let searchName = null;
    if (this.isTagSearch) {
      searchType = SearchType.Tag
      searchName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(this.info.name)
    } else {
      searchType = SearchType.Fintech
      searchName = business.name
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['business_evaluation_result', searchType, searchName, business.id])
    );
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: business.category_id, searchType: searchType, searchName: searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechPageResultsEvent(business.name);
    //TODO improve this, user maybe able to click in the button and open it directly on current tab or choose in context menu: Open New Tab
    window.open(url, "_blank");
    //this.router.navigate(['business_evaluation_result',SearchType.Tag, this.chosenTag.name, business.id])

  }

  evaluation(business) {
    let searchType = null;
    let searchName = null;
    if (this.isTagSearch) {
      searchType = SearchType.Tag
      searchName = Utils.removeSpecialCaractheresAndSpaceFromURLPart(this.info.name)
    } else {
      searchType = SearchType.Fintech
      searchName = business.name
    }
    //add params so it can be read in outside component
    this.activatedRoute.paramMap.pipe(
      map(paramMap => ({ business_id: business.id, category_id: business.category_id, searchType: searchType, searchName: searchName })),
      takeUntil(this.destroy)
    ).subscribe(routePathParam => {
      this.routeStateService.updatePathParamState(routePathParam)
    })
    this.googleAnalyticsService.openFintechEvaluationEvent(business.name);
    this.router.navigate(['evaluation', searchType, searchName, business.id])
  }

  decideBtnColor(i) {
    if ((i + 1) % 4 === 0) {
      return 'orange'
    }
    if ((i + 1) % 3 === 0) {
      return 'green'
    }
    if ((i + 1) % 2 === 0) {
      return 'blue'
    }
    return 'pink'
  }

}