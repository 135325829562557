<h2 mat-dialog-title fxLayoutAlign="center center">ACEITAR TERMOS DE USO e POLÍTICA DE PRIVACIDADE</h2>
<div class="div" mat-dialog-content>
  <span class="font-family" *ngIf="data.tcAndPPText" [innerHtml]="data.tcAndPPText"></span>
</div>
<div mat-dialog-actions align="end">
  <div>
   <section *ngFor="let userCheck of data.userChecks">
       <mat-checkbox class="checkBox" ngDefaultControl [(ngModel)]="userCheck.userCheckChoice">
         <span class="font-family" class="wrap-mat-checkbox-label">{{userCheck.acceptanceText}}</span>
       </mat-checkbox>
       <mat-error class="font-family" *ngIf="userClickedInBtn && !userCheck.userCheckChoice">
           Você deve concordar com todos os elementos
       </mat-error>
       <hr />
   </section>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end" >
    <button class="agree_btn" mat-button  (click)="onOKClick()">CONCORDO</button>
    <button class="disagree_btn" mat-button (click)="onCancelClick()">Discordo</button>
  </div>

</div>
