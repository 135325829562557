import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { retry, catchError, switchMap, filter, take } from 'rxjs/operators';

import { Router } from '@angular/router';

import { AuthService } from './auth.service';

import { Injector } from '@angular/core';

export class HttpErrorInterceptor implements HttpInterceptor {

  private authService: AuthService
  private router: Router
  private hasAlreadyTried = false

  constructor(private injector: Injector) {
    this.authService = injector.get(AuthService);
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)

      .pipe(

        retry(1),

        catchError((error: HttpErrorResponse) => {

          let errorMessage = '';
          let isToThrowError = false;

          //console.log("################# GOT ERROR ON HttpErrorInterceptor ######################")

          if (error.error instanceof ErrorEvent) {

            // client-side error
            this.hasAlreadyTried = false;
            errorMessage = `Error: ${error.error.message}`;
            return throwError(errorMessage);

          } else {
            //console.log("WILL TREAT SERVER ERROR error.status ", error.status)
            // server-side error
            switch (error.status) {
              case 401:      //login
                //console.log("handleServerErrors 401 authService " + error.message)
                //this.authService.clean()
                //TODO on handle401Error2, the pipe on the refreshToken call to catch the error is not working anymore, I dont know
                //why, so if the refresh token url fails, we logout user
                if (error && error.url && error.url && error.url.includes('token/refresh')) {
                  //console.log("INCLUDES")
                  this.logout()
                }
                this.hasAlreadyTried = false;
                return this.handle401Error2(request, next)
                /*if (error.error && error.error.code && error.error.detail.includes("Given token not valid for any token type")){
                  this.authService.clean()
                  this.authService.logout().subscribe(
                   success => {
                     //console.log("LOGGEDOUT FROM handle401Error2")
                      this.router.navigate(['login'])
                   },
                   error => {
                      console.log(">>>>>>> HttpInterceptor logout 2 ", error)
                     //console.log("ERROR LOGGEDOUT FROM handle401Error2")
                      this.router.navigate(['login'])
                   })
                   return null
                } else {
                  return this.handle401Error2(request, next)
                }*/


                break;
              case 403:     //forbidden
                //console.log("handleServerErrors 403 ")
                //console.log(">>>>>>>>>>> LOGOUT BY HTTP ERROR INTERCEPTOR 403 <<<<<<<<<<<<<<<<")

                /*  if (!this.hasAlreadyTried) {
                    this.authService.logout().subscribe(
                      success => {
                        console.log(">>>>>>>>>>> handleServerErrors 403 logout success ", success)
                        this.router.navigate(['login'])
                        this.hasAlreadyTried = true;
                      },
                      error => {
                        console.log(">>>>>>> handleServerErrors 403 logout  error", error)
                      this.authService.clean()
                      this.hasAlreadyTried = true;
                    })
                  }*/

                this.router.navigate(['login'])
                break;
              /*case 400: //verifying email error
                  console.log("error.error.global " , error.error.global)
                  console.log("------------- HttpErrorInterceptor server-side error " + JSON.stringify(error))
                  if ( error.error.global &&  error.error.global[0] === 'E-mail is not verified.')
                    return throwError(errorMessage);
                  return throwError(errorMessage);
                  break;*/
              default:
                this.hasAlreadyTried = false;
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                isToThrowError = true

              //return Observable.throw( error );
            }


          }
          //window.alert(errorMessage);
          if (isToThrowError) {
            return throwError(error);
          }
        })
      )
  }

  private logout() {
    this.authService.clean()
    this.authService.logout().subscribe(
      success => {
        //console.log(">>>>>>>>>>> HttpErrorInterceptor logout success 2 ", success)
        window.location.reload()
        //this.router.navigate(['login'])
      },
      error => {
        //console.log(">>>>>>> HttpErrorInterceptor error 2 ", error)
        window.location.reload()
        //this.router.navigate(['login'])
        //this.error = error
      }
    );
    /* console.log(">>>>>>>>>>>>>> this.authService.isSocialLogin() ", this.authService.isSocialLogin())
     if (this.authService.isSocialLogin()){
       this.authService.clean()
       //document.createElement('a').setAttribute('href', environment.apiUrl.concat('accounts/').concat('logout/')).click();
     } else {
  
     }*/
  }

  /*private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
      console.log("*****************************************")
        console.log("*****************************************")
    console.log("********************* handle401Error ********************")
     console.log("*****************************************")
       console.log("*****************************************")
   // await this.authService.refreshToken().toPromise().then(success => {
     // console.log("******************************** GOT NEW TOKEN")
   //});
      this.authService.refreshToken().subscribe(
        success => {
          console.log("******************************** GOT NEW TOKEN")
          console.log("******************************** ADDING TOKEN BY REFRESH")
           const token =  this.authService.tokenAccess
           console.log("************ token ", token)
           if (token) {
             const cloned = req.clone({
               headers: req.headers.set('Authorization', 'Bearer '.concat(token))
             });
             console.log("******************************** TOKEN ADDED BY REFRESH")
             return next.handle(cloned);
           } else {
             return next.handle(req);
           }
        },
        error => {
          //console.log("TODO DEAL WITH THIS ERROR")
        });
  }*/

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private handle401Error2(request: HttpRequest<any>, next: HttpHandler) {
    //console.log("*****************************************")
    //console.log("*****************************************")
    //console.log("********************* handle401Error ********************")
    //console.log("*****************************************")
    //console.log("*****************************************")
    //console.log("*************** WILL HANDLE 401 ERROR this.isRefreshing ", this.isRefreshing)
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((result: any) => {
          //console.log("******************************** GOT NEW TOKEN result ", result)
          //console.log("SWITCHMAP FROM FIRST REFRESH TOKEN result ", result)
          this.isRefreshing = false;
          const token = this.authService.tokenAccess
          this.refreshTokenSubject.next(token);
          const cloned = request.clone({
            headers: request.headers.set('Authorization', 'Bearer '.concat(token))
          });
          //console.log("******************************** GOT NEW TOKEN cloned ", cloned)
          return next.handle(cloned);
        }),
        catchError(error => {
          //console.log(">>>>>>> HttpInterceptor refreshToken error ", error)
          //console.log("***** typeof error ", typeof error)
          //console.log("***** error instanceof Error ", error instanceof Error)
          //console.log("***** error error.message ", error.message)
          //  console.log("***** error error.message ", error.error)
          //console.log("***** error error.code ", error.prototype.toString())
          //console.log("ERRO FROM FIRST REFRESH TOKEN ")

          //if ((error.error && error.error.code && error.error.code == "token_not_valid")
          //      || error.message.includes("Unknown Error")){
          //TODO deal with user end session better
          //console.log("+++++++++++ GAYGAYUGAYGAYGAUYGAYg ", error)
          this.authService.logout().subscribe(
            success => {
              //console.log(">>>>>>> HttpInterceptor logout 2 handle401Error2")
              this.authService.clean()
              this.router.navigate(['login'])
            },
            error => {
              //console.log(">>>>>>> HttpInterceptor logout 2 ", error)
              this.authService.clean()
              //console.log("ERROR LOGGEDOUT FROM handle401Error2")
              this.router.navigate(['login'])
            })

          //   return throwError(error);
          //}
          return throwError(error);

        }));

    } else {
      //  console.log("###################### REFRESH IS HAPPENING")
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(result => {
          //console.log("******************************** refreshTokenSubject result ", result)
          //console.log("SWITCHMAP FROM SECOND REFRESH TOKEN ")
          const token = this.authService.tokenAccess
          const cloned = request.clone({
            headers: request.headers.set('Authorization', 'Bearer '.concat(token))
          });
          return next.handle(cloned);
        }));
    }
  }



}
