import { NotificationService } from './notification-service';
import { ErrorService } from './error-service';

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

     constructor(private injector: Injector) { }

     handleError(error: Error | HttpErrorResponse | any) {
          const errorService = this.injector.get(ErrorService);
          const notifier = this.injector.get(NotificationService);

          let message;
          let stackTrace;

          //console.log("------------------- GlobalErrorHandler ERROR  error.error " + error)
          //console.log("------------------- GlobalErrorHandler ERROR  error.stack " + error.stack)
          //console.log("------------------- GlobalErrorHandler ERROR  error.error.code " + error.error.code)

          //TODO deal with user end session better
          if (error.error && error.error.code && error.error.code != "token_not_valid") {

               message = errorService.getClientMessage(error);
               stackTrace = errorService.getClientStack(error);
               notifier.showError(message);

          } else if (error instanceof HttpErrorResponse) {
               // Server Error
               message = errorService.getServerMessage(error);
               stackTrace = errorService.getServerStack(error);
               notifier.showError(message);
          } else {
               //Client Error
               notifier.showError(errorService.getClientMessage(error));

          }

     }
}
