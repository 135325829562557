

<div fxLayout="row" fxLayoutAlign="start none">
  <div fxHide.lt-sm fxHide.sm>
    <a class="twitter-timeline" data-width="300" data-height="700" data-theme="dark" href="https://twitter.com/FincatchOficial?ref_src=twsrc%5Etfw">Tweets by FincatchOficial</a>
  </div>
<div>
  <div class="carousel" fxLayout="column" fxLayoutAlign="center center" fxHide.lt-sm fxHide.sm >
    <ngb-carousel >
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper" >
        <img  src="assets/fincatch/consultoria_banner.jpeg" >
      </div>
      <div class="carousel-caption carousel-pointer" >
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper" >
        <img  src="assets/fincatch/ebook_banner.jpeg" >
      </div>
      <div class="carousel-caption carousel-pointer">
      </div>
    </ng-template>
  </ngb-carousel>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" ngClass.lt-sm="margin_top_lt-sm_and_sm" ngClass.sm="margin_top_lt-sm_and_sm">
      <h1 class="title" fxLayout="column" fxLayoutAlign="center center"  ngClass.lt-sm="title_lt-sm" ngClass.sm="title_sm">Somos a plataforma que ajuda pessoas e empresas a encontrarem as melhores soluções financeiras no mundo das fintechs.</h1>
      <h3 class="sub-title" ngClass.lt-sm="sub-title_lt-sm" >Confira os rankings por categorias e subcategorias para conhecer as melhores fintechs!</h3>
      <h3 class="sub-title" ngClass.lt-sm="sub-title_lt-sm" >Pesquise pelo nome da fintech ou palavras-chave para encontrar as melhores opções para você.</h3>
  </div>

  <div class="main_div" fxLayout="column" fxLayoutAlign="space-evenly center"  >

    <div fxLayout="row" fxLayoutAlign="center center" >
      <form  [formGroup]="searchForm"  >
        <div class="div-autocomplete">
          <mat-icon class="fas fa-search"  ></mat-icon>
          <input class="tag-form" ngClass.lt-sm="tag-form_lt-sm" type="text"  placeholder="Busque por fintechs ou tags (palavras-chave)" matInput formControlName="search" [matAutocomplete]="autoDialog" [(ngModel)]="search_Tag">
        </div>

          <mat-autocomplete #autoDialog="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="populateListByAutoComplete($event.option.value)">
            <mat-optgroup *ngIf="filteredTagsForUser">----- Para você
              <mat-option *ngFor="let search of filteredTagsForUser | async" [value]="search">
                {{search.name}}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup *ngIf="filteredTagsForBusiness">----- Para seu negócio
              <mat-option *ngFor="let search of filteredTagsForBusiness | async" [value]="search">
                {{search.name}}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup *ngIf="filteredBusinessByName">----- Fintechs
              <mat-option *ngFor="let search of filteredBusinessByName | async" [value]="search">
                {{search.name}}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>

      </form>
    </div>

  <hr />

    <div  fxLayout="row " fxLayoutAlign="center center" >
        <mat-accordion class="accordion" fxFlex="900px" fxFlex.lt-sm="100%" fxFlex.sm="700px">
          <mat-expansion-panel  class="expansion-panel" [expanded]="isOpen">
              <mat-expansion-panel-header >
                <mat-panel-title >
                  <div fxLayout="column" fxLayoutAlign="center center"  >
                    <span ngClass.lt-sm="panel-title-lt-sm" class="panel-title">Faça sua escolha:</span>
                  </div>
                  <a  ngClass.lt-sm="btn-lt-sm btn-for-user" mat-button class="btn btn-for-user" (click)="$event.stopPropagation();showTagsForUser()">Para você</a>
                  <a  ngClass.lt-sm="btn-lt-sm btn-for-business-lt-sm" mat-button class="btn btn-for-business" (click)="$event.stopPropagation();showTagsForBusiness()">Para seu negócio</a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div  fxLayout="row" fxLayoutAlign="start start"    >
                <div class="tag-for">
                  <div  *ngFor="let tag of tagsToShow | slice:0:tagsSliceSize">
                    <a mat-button class="tag-items" [ngClass]="tagItemColor()" (click)="populateListByTagList(tag)">{{tag.name}}</a>
                  </div>
                  <div *ngIf="isToShowAddTagBtn()">
                      <a mat-button class="add_btn" (click)="addItems()">
                        <mat-icon width="20" height="20">add</mat-icon></a>
                  </div>
                </div>

              </div>
            </mat-expansion-panel>
        </mat-accordion>
      </div>


      <div *ngIf="fintechsByTags" class="div-grid" ngClass.lt-md="div-grid-lt-md" >
        <div>
          <span  class="selected-tag-label">Tag selecionada:&nbsp;</span>
          <span class="selected-tag" ngClass.lt-sm="selected-tag-lt-sm" *ngIf="this.chosenTag">{{this.chosenTag.name}}</span>
        </div>
        <div  fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="center none">
          <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let b of fintechsByTags | slice:0:fintechSliceSize">
            <mat-card appearance="outlined" class="card mat-elevation-z4"  matTooltip="{{getFintechTagsNames(b.business.fintechTags)}}" matTooltipPosition="above">
                <mat-card-header>
                  <div mat-card-avatar class="header-image"  [style.background-image]="'url(assets/' + b.business.logo_small + ')'"></div>
                  <mat-card-title   (click)="evaluationResult(b.business)" >{{b.business.name}}</mat-card-title>
                    <span class="fa-stack fa-lg position" *ngIf="b.businessRate > 0">
                      <i *ngIf="b.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
                      <i *ngIf="b.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
                      <i *ngIf="b.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
                      <i *ngIf="b.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
                      <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{b.position}}</i>
                    </span>
                </mat-card-header>

                <mat-card-content class="card_content">
                  <div class="rating" fxLayout="row" fxLayoutAlign="center center" >
                    <ng-template #t let-fill="fill" >
                      <span class="fas star material-icons " [class.full]="fill === 100">
                        <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
                      </span>
                    </ng-template>

                    <ngb-rating   [(rate)]="b.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

                    <span class="rate">{{b.businessRate}}</span>
                  </div>


                  <div fxLayout="row" fxLayoutAlign="center center">
                      <a mat-button class="btn-evaluation" (click)="evaluation(b.business)">Avaliar esta empresa!</a>
                  </div>
                  <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
                      <a class="visualize" (click)="evaluationResult(b.business)" [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
                  </div>

                </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <div *ngIf="fintechByName" class="div-grid" ngClass.lt-md="div-grid-lt-md" >
        <div fxLayout="column" fxLayoutAlign="space-evenly" >
          <div>
            <span class="selected-tag-label">Empresa Selecionada:&nbsp;</span>
            <span class="selected-tag" ngClass.lt-sm="selected-tag-lt-sm" *ngIf="this.fintechByName">{{this.fintechByName.business.name}}</span>
          </div>
          <div>
            <mat-card appearance="outlined" class="card-related_fintechs mat-elevation-z4"  matTooltip="{{getFintechTagsNames(fintechByName.business.fintechTags)}}" matTooltipPosition="above">
                <mat-card-header>
                  <div mat-card-avatar class="header-image"  [style.background-image]="'url(assets/' + fintechByName.business.logo_small + ')'"></div>
                  <mat-card-title   (click)="evaluationResult(fintechByName.business)" >{{fintechByName.business.name}}</mat-card-title>
                    <span class="fa-stack fa-lg position" *ngIf="fintechByName.businessRate > 0">
                      <i *ngIf="fintechByName.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
                      <i *ngIf="fintechByName.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
                      <i *ngIf="fintechByName.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
                      <i *ngIf="fintechByName.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
                      <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{fintechByName.position}}</i>
                    </span>
                </mat-card-header>

                <mat-card-content class="card_content">
                  <div class="rating" fxLayout="row" fxLayoutAlign="center center" >
                    <ng-template #t let-fill="fill" >
                      <span class="fas star material-icons " [class.full]="fill === 100">
                        <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
                      </span>
                    </ng-template>

                    <ngb-rating   [(rate)]="fintechByName.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

                    <span class="rate">{{fintechByName.businessRate}}</span>
                  </div>


                  <div fxLayout="row" fxLayoutAlign="center center">
                      <a mat-button class="btn-evaluation" (click)="evaluation(fintechByName.business)">Avaliar esta empresa!</a>
                  </div>
                  <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
                      <a class="visualize" (click)="evaluationResult(fintechByName.business)" [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
                  </div>

                </mat-card-content>
            </mat-card>
          </div>
        </div>
        <hr />
        <div *ngIf="relatedFintechsToFintechByName && relatedFintechsToFintechByName.length > 0" class="div-related_fintechs">
          <span class="related-fintech-label">Empresas Relacionadas:&nbsp;</span>
          <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="center none">
            <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let b of relatedFintechsToFintechByName | slice:0:fintechSliceSize">
              <mat-card appearance="outlined" class="card mat-elevation-z4"  matTooltip="{{getFintechTagsNames(b.business.fintechTags)}}" matTooltipPosition="above">
                  <mat-card-header>
                    <div mat-card-avatar class="header-image"  [style.background-image]="'url(assets/' + b.business.logo_small + ')'"></div>
                    <mat-card-title   (click)="evaluationResult(b.business)" >{{b.business.name}}</mat-card-title>
                      <span class="fa-stack fa-lg position" *ngIf="b.businessRate > 0">
                        <i *ngIf="b.position == 1" class="fa fa-award fa-stack-2x" style="color: #FFD700"></i>
                        <i *ngIf="b.position == 2" class="fa fa-award fa-stack-2x" style="color: #C0C0C0"></i>
                        <i *ngIf="b.position == 3" class="fa fa-award fa-stack-2x" style="color: #E57B19"></i>
                        <i *ngIf="b.position > 3" class="fa fa-award fa-stack-2x" style="color: #D8D8D8"></i>
                        <i class="fa fa-stack-1x" style="color: #291749; font-size:1rem">{{b.position}}</i>
                      </span>
                  </mat-card-header>

                  <mat-card-content class="card_content">
                    <div class="rating" fxLayout="row" fxLayoutAlign="center center" >
                      <ng-template #t let-fill="fill" >
                        <span class="fas star material-icons " [class.full]="fill === 100">
                          <span class="fas half material-icons" [style.width.%]="fill">&#9733;</span>&#9733;
                        </span>
                      </ng-template>

                      <ngb-rating   [(rate)]="b.businessRate" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>

                      <span class="rate">{{b.businessRate}}</span>
                    </div>


                    <div fxLayout="row" fxLayoutAlign="center center">
                        <a mat-button class="btn-evaluation" (click)="evaluation(b.business)">Avaliar esta empresa!</a>
                    </div>
                    <div (mouseover)="hover=true" (mouseleave)="hover=false" fxLayout="row" fxLayoutAlign="center center">
                        <a class="visualize" (click)="evaluationResult(b.business)" [ngStyle]="{color: hover==true  ? 'var(--primary-color-lighter)' : '#0075b6' }">Visualizar</a>
                    </div>

                  </mat-card-content>
              </mat-card>
            </div>
          </div>

        </div>

      </div>

  </div>

</div>
</div>

<div class='fixedButton'>
  <a mat-button class='roundedFixedBtn' (click)="openFormsPopUp()">Não sei por onde começar</a>
</div>
