<div fxLayout="column" fxLayoutAlign="center center"> 
    <h1 class="title">Especialistas:</h1>
    <div  *ngFor="let expert of experts">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center"  class="expert-div" >
            <div  fxLayout="column" fxLayoutAlign="center start" >
                <img class="photo"  src='{{ "assets/" +  expert.photo }}'/>
            </div>
            <div  fxLayout="column" fxLayoutAlign="center start" class="expert-data-div" ngClass.lt-md="expert-data-div-lt-md">
                <span class="font-for-all name">{{expert.name}}</span>
                <span class="font-for-all">{{expert.about}}</span>
                <a href="{{expert.link}}" target="_blank">{{expert.link}}</a>
            </div>
        </div>
        <hr/>
    </div>

</div>